export const ADD_DEVICE = "ADD_DEVICE"
export const ADD_DEVICE_SUCCESS = "ADD_DEVICE_SUCCESS"
export const ADD_DEVICE_ERROR = "ADD_DEVICE_ERROR"

export const SEARCH_DEVICE_DEFAULT = "SEARCH_DEVICE_DEFAULT"
export const SEARCH_DEVICE_SUCCESS = "SEARCH_DEVICE_SUCCESS"
export const SEARCH_DEVICE_ERROR = "SEARCH_DEVICE_ERROR"

export const BULKSALE_DEFAULT = "BULKSALE_DEFAULT"
export const BULKSALE_SUCCESS = "BULKSALE_SUCCESS"
export const BULKSALE_ERROR = "BULKSALE_ERROR"

export const CHECK_IMEI_DEFAULT = "CHECK_IMEI_DEFAULT"
export const CHECK_IMEI_SUCCESS = "CHECK_IMEI_SUCCESS"
export const CHECK_IMEI_ERROR = "CHECK_IMEI_ERROR"

export const RECHARGE_DEFAULT = "RECHARGE_DEFAULT"
export const RECHARGE_SUCCESS = "RECHARGE_SUCCESS"
export const RECHARGE_ERROR = "RECHARGE_ERROR"

export const MODIFY_EXPIRY_DATE = "MODIFY_EXPIRY_DATE"
export const MODIFY_EXPIRY_DATE_SUCCESS = "MODIFY_EXPIRY_DATE_SUCCESS"
export const MODIFY_EXPIRY_DATE_ERROR = "MODIFY_EXPIRY_DATE_ERROR"

export const PLATEFORM_MODIFY_EXPIRY_DATE = "PLATEFORM_MODIFY_EXPIRY_DATE"
export const PLATEFORM_MODIFY_EXPIRY_DATE_SUCCESS =
  "PLATEFORM_MODIFY_EXPIRY_DATE_SUCCESS"
export const PLATEFORM_MODIFY_EXPIRY_DATE_ERROR =
  "PLATEFORM_MODIFY_EXPIRY_DATE_ERROR"

export const ADD_TO_VIRTUAL_DEVICE = "ADD_TO_VIRTUAL_DEVICE"
export const ADD_TO_VIRTUAL_DEVICE_SUCCESS = "ADD_TO_VIRTUAL_DEVICE_SUCCESS"
export const ADD_TO_VIRTUAL_DEVICE_ERROR = "ADD_TO_VIRTUAL_DEVICE_ERROR"
