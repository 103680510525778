/* eslint-disable */

import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Input,
  Table,
  InputGroup,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./SearchStyle.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PopupDeviceList from "../../components/Business/PopupDeviceList"

import classnames from "classnames";
import SearchDeviceTable from "./SearchDeviceTable";
import SearchDeviceInfo from "../../components/Business/SearchDeviceInfo";
function UiTabsAccordions(props) {
  //let GetPropId = '"'+props.GetTabId+'"';  
  const [activeTab, setactiveTab] = useState("1");
  const toggle = tab => {

    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  
  useEffect(() => {
    props.GetTabId == '1'?setactiveTab('1'):setactiveTab('2');
  }, [])


  

  return (
    <React.Fragment>
      <Nav tabs className="nav-tabs-custom custompoptabs">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
            active: props.GetTabId == '1',
          })}
            onClick={() => {
              toggle("1");
            }}
          >
            Device
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: props.GetTabId == '2',
            })}
            // className={` ${activeTab === '2' ? 'active' : ''}  `}
             onClick={() => {
              toggle("2");
            }}
          >
            User
          </NavLink>
        </NavItem>
        </Nav>
        <div className="row my-3 mx-4 mb-2 align-items-center justify-content-center">
          <div className="col-md-5">
            <div className="position-relative formsearch me-2">
              <span className="searchi"><i className="bx bx-search-alt"></i></span>
                <InputGroup>
                  <input type="text" className="form-control ps-5" id="inlineFormInputGroupUsername" placeholder={props?.searchData} />
                    <div className="input-group-text"><i className=" bx bx-search"></i></div>
                </InputGroup>
             </div>
           </div>
        </div>
        <TabContent activeTab={activeTab} className="py-3 text-muted">
          <TabPane tabId="1">
            <div className="px-4 minheightis">
              <PopupDeviceList data={props?.getSearchDeviceData} />
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="px-4">
              <Table className="table mb-0">
                <thead>
                  <tr>
                        <th>#</th>
                          <th>Username</th>
                          <th>Account</th>
                          <th>Contact</th>
                          <th>Operation</th>
                        </tr>
                      </thead>
                      <tbody>
                        { 
                           props?.getSearchUserData  && props?.getSearchUserData.length > 0 && props?.getSearchUserData.map((data, index) => {
                             return(
                               <>
                                  <tr>
                                      <td>{index + 1}</td>
                                      <td><span className="text-info">{data.name}</span></td>
                                      <td>{data.username}</td>
                                      <td>{data.contactNumber || data.contactNumber === null && '-'}</td>
                                      <td><span className="text-info">
                                      <Link
                                        to="/monitor" target={"_blank"}
                                        className="txt_colr_1"
                                      >
                                        <span>Details</span>
                                      </Link> | <Link
                                          to="/monitor" target={"_blank"}
                                          className="txt_colr_1"
                                      >
                                        <span>Monitor</span>
                                      </Link>
                                        </span></td>
                                  </tr>
                               </>
                             )
                           })
                        }
                      </tbody>
                    </Table>
                      </div>
                    </TabPane>
                  </TabContent>
               
    </React.Fragment>
  );
}

export default UiTabsAccordions;
