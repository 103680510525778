/* eslint-disable */
import { takeEvery, fork, put, all, call, delay } from "redux-saga/effects"

import {
  GET_MOVINGOVERVIEW_DETAILS,
  GET_MILEAGEREPORT,
  GET_SPEEDING_DETAILS,
  GET_PARKING_DETAILS,
  GET_TRIP_REPORT,
  GET_SPEEDING_TRIP_REPORT,
  GET_ENGINE_OVERVIEW,
  GET_ENGINE_REPORT,
  GET_ENGINE_IDLE_REPORT,
  GET_ALERT_OVERVIEW,
  GET_ALERT_REPORT,
  GET_ALERT_DETAILS,
  GET_GEO_FENCE_ALERT_REPORT,
} from "./actionTypes"
import {
  getMovingOverviewDetailsSuccess,
  getMovingOverviewDetailsError,
  getMileageReportSuccess,
  getMileageReportError,
  getSpeedingDetailsSuccess,
  getSpeedingDetailsError,
  getParkingDetailsSuccess,
  getParkingDetailsError,
  getTripReportSuccess,
  getTripReportError,
  getSpeedingTripReportSuccess,
  getSpeedingTripReportError,
  getEngineOverviewSuccess,
  getEngineOverviewError,
  getEngineReportSuccess,
  getEngineReportError,
  getEngineIdleReportSuccess,
  getEngineIdleReportError,
  getAlertOverviewSuccess,
  getAlertOverviewError,
  getAlertReportSuccess,
  getAlertReportError,
  getAlertDetailsSuccess,
  getAlertDetailsError,
  getGeoFenceAlertReportSuccess,
  getGeoFenceAlertReportError,
} from "./actions"

import { getReports } from "../../helpers/fakebackend_helper"

import { showToast } from "../../utils/Common"

function* getMovingOverviewSaga({ payload }) {
  try {
    const response = yield call(getReports, payload)
    yield put(getMovingOverviewDetailsSuccess(response))
  } catch (error) {
    yield put(getMovingOverviewDetailsError(error))
  }
}

function* getMileageReportSaga({ payload }) {
  try {
    const response = yield call(getReports, payload)
    yield put(getMileageReportSuccess(response))
  } catch (error) {
    yield put(getMileageReportError(error))
  }
}

function* getSpeedingDetailsSaga({ payload }) {
  try {
    const response = yield call(getReports, payload)
    yield put(getSpeedingDetailsSuccess(response))
  } catch (error) {
    yield put(getSpeedingDetailsError(error))
  }
}

function* getParkingDetailsSaga({ payload }) {
  try {
    const response = yield call(getReports, payload)
    yield put(getParkingDetailsSuccess(response))
  } catch (error) {
    yield put(getParkingDetailsError(error))
  }
}

function* getTripReportSaga({ payload }) {
  try {
    const response = yield call(getReports, payload)
    yield put(getTripReportSuccess(response))
  } catch (error) {
    yield put(getTripReportError(error))
  }
}

function* getSpeedingTripReportSaga({ payload }) {
  try {
    const response = yield call(getReports, payload)
    yield put(getSpeedingTripReportSuccess(response))
  } catch (error) {
    yield put(getSpeedingTripReportError(error))
  }
}

function* getEngineOverviewSaga({ payload }) {
  try {
    const response = yield call(getReports, payload)
    yield put(getEngineOverviewSuccess(response))
  } catch (error) {
    yield put(getEngineOverviewError(error))
  }
}

function* getEngineReportSaga({ payload }) {
  try {
    const response = yield call(getReports, payload)
    yield put(getEngineReportSuccess(response))
  } catch (error) {
    yield put(getEngineReportError(error))
  }
}

function* getEngineIdleReportSaga({ payload }) {
  try {
    const response = yield call(getReports, payload)
    yield put(getEngineIdleReportSuccess(response))
  } catch (error) {
    yield put(getEngineIdleReportError(error))
  }
}

function* getAlertOverviewSaga({ payload }) {
  try {
    const response = yield call(getReports, payload)
    yield put(getAlertOverviewSuccess(response))
  } catch (error) {
    yield put(getAlertOverviewError(error))
  }
}

function* getAlertReportSaga({ payload }) {
  try {
    const response = yield call(getReports, payload)
    yield put(getAlertReportSuccess(response))
  } catch (error) {
    yield put(getAlertReportError(error))
  }
}

function* getAlertDetailsSaga({ payload }) {
  try {
    const response = yield call(getReports, payload)
    yield put(getAlertDetailsSuccess(response))
  } catch (error) {
    yield put(getAlertDetailsError(error))
  }
}

function* getGeoFenceAlertReportSaga({ payload }) {
  try {
    const response = yield call(getReports, payload)
    yield put(getGeoFenceAlertReportSuccess(response))
  } catch (error) {
    yield put(getGeoFenceAlertReportError(error))
  }
}

export function* watchMessage() {
  yield takeEvery(GET_MOVINGOVERVIEW_DETAILS, getMovingOverviewSaga)
  yield takeEvery(GET_MILEAGEREPORT, getMileageReportSaga)
  yield takeEvery(GET_SPEEDING_DETAILS, getSpeedingDetailsSaga)
  yield takeEvery(GET_PARKING_DETAILS, getParkingDetailsSaga)

  yield takeEvery(GET_TRIP_REPORT, getTripReportSaga)
  yield takeEvery(GET_SPEEDING_TRIP_REPORT, getSpeedingTripReportSaga)

  yield takeEvery(GET_ENGINE_OVERVIEW, getEngineOverviewSaga)
  yield takeEvery(GET_ENGINE_REPORT, getEngineReportSaga)
  yield takeEvery(GET_ENGINE_IDLE_REPORT, getEngineIdleReportSaga)

  yield takeEvery(GET_ALERT_OVERVIEW, getAlertOverviewSaga)
  yield takeEvery(GET_ALERT_REPORT, getAlertReportSaga)
  yield takeEvery(GET_ALERT_DETAILS, getAlertDetailsSaga)
  yield takeEvery(GET_GEO_FENCE_ALERT_REPORT, getGeoFenceAlertReportSaga)
}

function* reportsSaga() {
  yield all([fork(watchMessage)])
}

export default reportsSaga
