import {
  GET_GEO_FENCE_DETAILS,
  GET_GEO_FENCE_DETAILS_SUCCESS,
  GET_GEO_FENCE_DETAILS_ERROR
} from "./actionTypes"

export const getFenceDetails = memberId => {
  return {
    type: GET_GEO_FENCE_DETAILS,
    memberId,
  }
}

export const getFenceDetailsSuccess = user => {
  return {
    type: GET_GEO_FENCE_DETAILS_SUCCESS,
    payload: user,
  }
}

export const getFenceDetailsFail = error => {
  return {
    type: GET_GEO_FENCE_DETAILS_ERROR,
    payload: error,
  }
}
