/* eslint-disable */

import React, { useState, useEffect } from "react"

import { Formik, Field, Form, ErrorMessage } from "formik"
import * as yup from "yup"
import moment from "moment"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Tooltip,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Spinner,
} from "reactstrap"

import classnames from "classnames"

import Select from "react-select"

import { fileToBase64, TimeZoneOptionList } from "../../utils/Common"

import LoadingButton from "../Common/Button"

import { updateUserdefault } from "../../store/actions"

import { useSelector, useDispatch } from "react-redux"

const userFormScheme = yup.object().shape({
  // email: yup.string().email().required("Email is required"),
  timezone: yup.string().required("Time zone is required"),
  // contactNumber: yup.string().matches(/^(\+91)/, "Please add +91 code"),
  // telephone: yup.string().matches(/^(\+91)/, "Please add +91 code"),
})

function Information(props) {
  const { userDetail, isLoading: loadingData = false } = props
  const dispatch = useDispatch()

  const [ttop, setttop] = useState(false)
  const [emaitooltip, setEmaitooltip] = useState(false)

  const [userImage, setUserImage] = useState(userDetail?.profileImage)
  const [file, setFileData] = useState()

  const { isLoading } = useSelector(state => {
    return {
      isLoading: state.AddUser.isLoading,
    }
  })

  useEffect(() => {
    setUserImage(userDetail?.profileImage)
  }, [userDetail])

  const onImageChange = e => {
    fileToBase64(e).then(res => {
      setUserImage(res.file)
      setFileData(e.target.files[0])
    })
  }

  const optionsTimeZone = TimeZoneOptionList()

  if (loadingData) {
    return (
      <Spinner
        className={classnames({
          "position-relative": true,
          "button-style": false,
          visible: true,
          "left-50": true,
        })}
        size="lg"
        // type="grow"
      />
    )
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: userDetail?.name || "",
          contactNumber: userDetail?.contactNumber || "",
          mobileNumber: userDetail?.mobileNumber || "",
          telephone: userDetail?.telephone || "",
          email: userDetail?.email || "",
          address: userDetail?.address || "",
          webpage: userDetail?.webpage || "",
          timezone: userDetail?.timezone || "",
          role:
            (userDetail?.roles && userDetail?.roles[0].toLowerCase()) ||
            "distributor",
        }}
        validationSchema={userFormScheme}
        onSubmit={values => {
          if (values) {
            const {
              name,
              contactNumber,
              mobileNumber,
              telephone,
              email,
              address,
              webpage,
              timezone,
              role,
            } = values

            dispatch(
              updateUserdefault(
                {
                  name,
                  contactNumber: contactNumber ? contactNumber : undefined,
                  // mobileNumber,
                  telephone: telephone ? telephone : undefined,
                  email: email ? email : undefined,
                  address,
                  webpage,
                  timezone,
                  role,
                },
                userDetail?.id,
                props.history,
                userImage !== userDetail?.profileImage,
                [
                  {
                    file,
                  },
                ]
              )
            )
          }
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          errors,
          setFieldValue,
        }) => (
          <>
            <Form>
              <div className="row">
                <div className="col-lg-6">
                  <Row className="mb-3 align-items-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Account
                    </label>
                    <div className="col-md-7">{userDetail?.username}</div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      User name
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-username"
                        name="name"
                      />
                      <ErrorMessage
                        name="name"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                </div>
                <div className="col-lg-6">
                  <Row className="">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 text-end"
                    >
                      Profile photo:
                    </label>
                    <div className="col-md-7 d-flex">
                      <div className="profi_img_row position-relative me-1">
                        <img className="profile_img" src={userImage} alt="" />
                        <div className="upload_cus">
                          <i className="bx bxs-pencil upload_edit d-flex align-items-center justify-content-center"></i>
                          <input
                            type="file"
                            name="file"
                            onChange={onImageChange}
                          />
                        </div>
                      </div>
                      <div>
                        <i
                          className="bx bxs-info-circle info_icon"
                          id="ProfileTooltip"
                        ></i>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
              <Row className="mb-3 align-items-center">
                <label
                  htmlFor="example-text-input"
                  className="col-md-3 col-form-label text-end"
                >
                  Role <small className="text-danger"> *</small>
                </label>
                <div className="col-md-9 d-flex pt-2">
                  <div className="form-check pe-2">
                    <Input
                      type="radio"
                      value="distributor"
                      id="role1"
                      name="role"
                      className="form-check-input"
                      onClick={e => setFieldValue("role", e.target.value)}
                      defaultChecked={values.role === "distributor"}
                    />
                    <Label
                      className="form-check-label font-size-13 w-99"
                      htmlFor="role1"
                    >
                      <i className="bx bx-user color_1"></i> Distributor
                    </Label>
                  </div>
                  <div className="form-check pe-2">
                    <Input
                      type="radio"
                      value="virtual"
                      id="role2"
                      name="role"
                      className="form-check-input"
                      onClick={e => setFieldValue("role", e.target.value)}
                      defaultChecked={values.role === "virtual"}
                    />
                    <Label
                      className="form-check-label font-size-13 w-99"
                      htmlFor="role2"
                    >
                      <i className="bx bx-user color_2"></i> Virtual account
                    </Label>
                  </div>
                  <div className="form-check pe-2">
                    <Input
                      type="radio"
                      value="end_user"
                      id="role3"
                      name="role"
                      className="form-check-input"
                      onClick={e => setFieldValue("role", e.target.value)}
                      defaultChecked={values.role === "end_user"}
                    />
                    <Label
                      className="form-check-label font-size-13 w-99"
                      htmlFor="role3"
                    >
                      <i className="bx bx-user color_2"></i> End User
                    </Label>
                  </div>
                  {values.role === "end_user" ? (
                    <>
                      <div className="form-check pe-2">
                        <Input
                          type="checkbox"
                          className="form-check-Input"
                          id="horizontal-customCheck-auto2"
                          // onClick={e => setAutoCalculate2(e.target.checked)}
                          checked={false}
                        />
                        <label className="form-check-label">Monitor Only</label>
                        <span
                          className="ms-1 text-danger"
                          data-tip="User can not modify any device or account information; Only Monitoring,Tracking,Playback and Statistic reports are supported."
                        >
                          <i className="bx bxs-info-circle"></i>
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </Row>
              <div className="row">
                <div className="col-lg-6">
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Time zone <small className="text-danger">*</small>
                    </label>
                    <div className="col-md-7">
                      <Select
                        classNamePrefix="select2-selection"
                        placeholder="Please Select"
                        title="timezone"
                        name="timezone"
                        isClearable={false}
                        options={optionsTimeZone}
                        isSearchable={false}
                        onChange={selectedOption =>
                          setFieldValue("timezone", selectedOption.value)
                        }
                        value={optionsTimeZone.filter(option => {
                          if (option.value == values.timezone) return option
                        })}
                        onBlur={() => {
                          handleBlur({ target: { name: "timezone" } })
                        }}
                      />
                      <ErrorMessage
                        name="timezone"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Contact
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-contact-Input"
                        name="contactNumber"
                      />
                      <ErrorMessage
                        name="contactNumber"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Telephone
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-tephone-Input"
                        name="telephone"
                      />
                      <ErrorMessage
                        name="telephone"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Address
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-address-Input"
                        name="address"
                      />
                      <ErrorMessage
                        name="address"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 text-end"
                    >
                      Create time
                    </label>
                    <div className="col-md-7">
                      {moment(userDetail?.createdAt).format(
                        "YYYY-MM-DD hh:mm:ss"
                      )}
                    </div>
                  </Row>
                </div>
                <div className="col-lg-6">
                  <Row className="mb-3 pt-4">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Mobile
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-mobile-Input"
                        name="mobileNumber"
                      />
                      <ErrorMessage
                        name="mobileNumber"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      Email{" "}
                      <i
                        className="bx bxs-info-circle info_icon"
                        id="EmailTootlip"
                      ></i>
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-email-Input"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-5 col-form-label text-end"
                    >
                      WebPage
                    </label>
                    <div className="col-md-7">
                      <Field
                        type="text"
                        className="form-control"
                        id="formrow-webpage-Input"
                        name="webpage"
                      />
                      <ErrorMessage
                        name="webpage"
                        component="span"
                        className="error"
                      />
                    </div>
                  </Row>
                </div>
                <Row className="mb-3">
                  <div className="col-md-6">
                    <Row className="mb-3">
                      <div className="col-md-5"></div>
                      <div className="col-md-7">
                        {" "}
                        <LoadingButton
                          color="primary"
                          className="btn btn-primary submitbtn "
                          loading={isLoading}
                          disabled={isLoading}
                          block={false}
                          type="submit"
                        >
                          Save changes
                        </LoadingButton>
                      </div>
                    </Row>
                  </div>
                </Row>
              </div>
              <Tooltip
                placement="top"
                isOpen={ttop}
                target="ProfileTooltip"
                toggle={() => {
                  setttop(!ttop)
                }}
              >
                JPG/PNG/GIF, 1 MB
              </Tooltip>
              <Tooltip
                placement="top"
                isOpen={emaitooltip}
                target="EmailTootlip"
                toggle={() => {
                  setEmaitooltip(!emaitooltip)
                }}
              >
                You can retrieve your password through this email address,
                please be sure to fill in the valid one.
              </Tooltip>
            </Form>
          </>
        )}
      </Formik>
    </div>
  )
}

export default Information
