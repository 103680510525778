import {
  GET_GEO_FENCE_DETAILS,
  GET_GEO_FENCE_DETAILS_SUCCESS,
  GET_GEO_FENCE_DETAILS_ERROR,
} from "./actionTypes"

const initialState = {
  geoFenceData:{},
  isLoading: false,
  isFailed: false,
  GeoFenceCurrentMemberId: '',
}

const GeoFenceDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_GEO_FENCE_DETAILS:
      return {
        ...state,
        isLoading: true,
      }
    case GET_GEO_FENCE_DETAILS_SUCCESS:
      return {
        ...state,
        geoFenceData: action.payload.response,
        isLoading: false,
        isFailed: false,
        GeoFenceCurrentMemberId: action.payload.memberId,
      }

    case GET_GEO_FENCE_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
      }
  }
  return state
}

export default GeoFenceDetails
