/* eslint-disable */

import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import CarStatus from "../../assets/images/car-staus.svg"
import trace from "../../assets/images/trace.svg"
import list from "../../assets/images/list.svg"
import t from "../../assets/images/t.svg"
import target from "../../assets/images/target.svg"
import UserLists from "../../components/user/monitorTreeMenu"
import { useSelector, useDispatch } from "react-redux"
import infoicons1 from "../../assets/images/icons/static.png"
import infoicons2 from "../../assets/images/icons/static-idle.png"
import infoicons3 from "../../assets/images/icons/moving.png"
import infoicons4 from "../../assets/images/icons/speeding.png"
import infoicons5 from "../../assets/images/icons/offline.png"
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
} from "@mui/material"

import {
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { getMonitorDetails } from "../../store/actions"
import classnames from "classnames"
import MyDeviceAll from "./MyDeviceAll"
import "./Monitor.css"
import _ from "lodash"
import minimize from "../../assets/images/minimize.svg"
function MonitorPage({ data }) {
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [col5, setcol5] = useState(true)
  const t_col5 = () => {
    setcol5(!col5)
  }

  const [showSideMenu, setSideMenu] = useState(true)

  const [isActive, setIsActive] = useState(false)
  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current)
  }

  return (
    <>
      <span
        className={isActive ? "mininmax hiddiv" : "mininmax"}
        onClick={handleClick}
      ></span>

      <div
        className={
          isActive ? "my_device_main d-none" : " my_device_main d-block"
        }
      >
        <div className="my_deviceinner">
          <header className="flx_0">
            <h3 className="h3title mb-0">My device</h3>
          </header>
          <div className="tab_wrapper flx_1 d-flex flex-column over_auto">
            <div className="flx_0">
              <Nav tabs className="nav-tabs-custom">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1")
                    }}
                  >
                    <span className="d-none d-sm-block">Default</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2")
                    }}
                  >
                    {/* <span className="d-none d-sm-block">Followed</span> */}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div className="flx_1 over_auto">
              <TabContent
                activeTab={customActiveTab}
                className="text-muted h-100"
              >
                <TabPane tabId="1" className="h-100">
                  <div className="d-flex flex-column h-100">
                    <div className="flx_1 d-flex w-100 flex-column h-50 over_auto border-bottom">
                      {/* <div className="flx_0">
                        <div className="bg_cus_1 d-flex p-2 over_auto">
                          <div className="flx_0">
                            <select name="" id="" className="form-select">
                              <option value="">Device</option>
                              <option value="">User</option>
                            </select>
                          </div>
                          <div className="flx_1">
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                      </div> */}
                      <div className="flx_1 over_auto">
                        <div className="accor_row h-100 d-flex flex-column">
                          <div className="selectisopen">
                            <UserLists/>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                    <MyDeviceAll />
                  </div>
                </TabPane>
                {/* <TabPane tabId="2" className="h-100">
                  fgsgfsgs
                </TabPane> */}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MonitorPage
