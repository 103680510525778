/* eslint-disable */

import React from "react"
import {
  SET_SELECTED_DEVICE_INITIAL,
  SET_SELECTED_DEVICE_SUCCESS,
  SET_SELECTED_DEVICE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  selectedDevicePopupDetails:null,
  error: {},
  isLoading: false,
}

const SelectedDevicePopup = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_DEVICE_INITIAL:
      return {
        ...state,
        isLoading: true,
      }
    case SET_SELECTED_DEVICE_SUCCESS:
      return {
        ...state,
        selectedDevicePopupDetails: action.payload,
        isLoading: false,
      }

    case SET_SELECTED_DEVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    default:
      return state
  }
}

export default SelectedDevicePopup
