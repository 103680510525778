/* eslint-disable */

import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"

import {
  CardText,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Spinner,
  Input,
  FormGroup,
  Label,
  InputGroup,
} from "reactstrap"

import Select from "react-select"

import { connect } from "react-redux"

import LoadingButton from "../../components/Common/Button"

import { Link } from "react-router-dom"

import { putDeviceFuel } from "../../store/actions"

import { bindActionToPromise } from "../../utils/Common"

import { useSelector } from "react-redux"

import classnames from "classnames"

import Calibration from "./Calibration"

function FuelDetails(props) {
  const {
    fuelInformationInputs,
    updateFuelInformationInputs,
    selectedDevice,
    tog_model,
  } = props

  const { isUpdateDeviceLoading } = useSelector(state => {
    return {
      isUpdateDeviceLoading: state.Device.isUpdateDeviceLoading,
    }
  })

  const [activeTab, setactiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const updateFuelCalibration = (key, value) => {
    let newCalibration = { ...fuelInformationInputs.calibration, [key]: value }

    updateFuelInformationInputs("calibration", { ...newCalibration })
  }

  const updateFuelSensor = (key, value) => {
    let newSensor = { ...fuelInformationInputs.sensor, [key]: value }

    updateFuelInformationInputs("sensor", { ...newSensor })
  }

  const updateFuelTank = (key, value) => {
    let newTank = { ...fuelInformationInputs.tank, [key]: value }

    updateFuelInformationInputs("tank", { ...newTank })
  }

  const updateFuelTankCube = (key, value) => {
    let newCube = {
      ...fuelInformationInputs?.tank?.cube,
      [key]: value,
    }
    updateFuelTank("cube", { ...newCube })
  }

  const updateFuelTankHorizontal = (key, value) => {
    let newHorizontal = {
      ...fuelInformationInputs?.tank?.horizontal,
      [key]: value,
    }
    updateFuelTank("horizontal", { ...newHorizontal })
  }

  const sensorInputs = [
    { value: "Analogue input 1", label: "Analogue input 1" },
    { value: "Analogue input 2", label: "Analogue input 2" },
  ]

  const sensorTypes = [
    { value: "Default", label: "Default" },
    { value: "Capacitive sensor", label: "Capacitive sensor" },
    { value: "Ultrasonic sensor", label: "Ultrasonic sensor" },
  ]

  const generatePayloadForUpdate = () => {
    const updatedTank = {
      volume: fuelInformationInputs?.tank?.volume,
      ...(fuelInformationInputs?.tank?.shape === "Cube"
        ? { cube: fuelInformationInputs?.tank?.cube }
        : { horizontal: fuelInformationInputs?.tank?.horizontal }),
    }

    const filterdCalibrationValues =
      fuelInformationInputs?.calibration?.values.filter(
        ele => ele.value_l !== "" && ele.sensorValue !== ""
      )

    const updatedCalibration = {
      ...fuelInformationInputs?.calibration,
      values: filterdCalibrationValues.map(ele => ({
        value_l: ele.value_l,
        sensorValue: ele.sensorValue,
      })),
    }
    return {
      data: {
        deviceId: Number(selectedDevice?.id),
        consumption: Number(fuelInformationInputs?.consumption),
        tank: updatedTank,
        sensor: fuelInformationInputs?.sensor,
        calibration: updatedCalibration,
      },
    }
  }

  const onSubmitFuelDetail = () => {
    props.putDeviceFuelAction(generatePayloadForUpdate()).then(() => {
      tog_model()
    })
  }

  return (
    <React.Fragment>
      <Nav tabs className="nav-tabs-custom mb-2" role="tablist">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "1",
            })}
            onClick={() => {
              toggle("1")
            }}
          >
            Fuel Consumption
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "2",
            })}
            onClick={() => {
              toggle("2")
            }}
          >
            Fuel Tank
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "3",
            })}
            onClick={() => {
              toggle("3")
            }}
          >
            Fuel Sensor
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "4",
            })}
            onClick={() => {
              toggle("4")
            }}
          >
            Calibration
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="text-muted">
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <Row className="mb-1">
                <div className="col-md-6">
                  <label className="col-md-12 col-form-label text-start">
                    Fule Consumption
                  </label>
                  <div className="col-md-12 d-flex align-items-center">
                    <Input
                      name="consumption"
                      type="number"
                      className="form-control"
                      onChange={e =>
                        updateFuelInformationInputs(
                          "consumption",
                          e.target.value
                        )
                      }
                      value={fuelInformationInputs.consumption}
                    />
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mb-1 mt-3">
            <div className="col-md-12 d-flex justify-content-end">
              <LoadingButton
                color="primary"
                className="btn btn-primary btn-right"
                loading={isUpdateDeviceLoading}
                disabled={isUpdateDeviceLoading}
                block={false}
                onClick={() => onSubmitFuelDetail()}
              >
                Submit
              </LoadingButton>
            </div>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <div className="mb-3">
                <Label htmlFor="metatitle" style={{ paddingRight: "15px" }}>
                  Fuel Tank Shape
                </Label>
                <div style={{ display: "inline-flex" }}>
                  <div className="form-check form-check-inline font-size-16 d-flex align-items-flex-start pr-1">
                    <Input
                      type="radio"
                      value="Cube"
                      id="fuelUnit1"
                      name="shape"
                      className="form-check-input radio-inline"
                      onClick={e => updateFuelTank("shape", e.target.value)}
                      checked={fuelInformationInputs?.tank?.shape === "Cube"}
                    />
                    <Label
                      className="form-check-label font-size-13"
                      htmlFor="fuelUnit1"
                      style={{ paddingLeft: "10px" }}
                    >
                      <div className="d-flex justify-content-between">Cube</div>
                    </Label>
                  </div>
                  <div className="form-check form-check-inline font-size-16 d-flex align-items-flex-start ">
                    <Input
                      type="radio"
                      value="Horizontal"
                      id="fuelUnit2"
                      name="shape"
                      className="form-check-input radio-inline"
                      onClick={e => updateFuelTank("shape", e.target.value)}
                      checked={
                        fuelInformationInputs?.tank?.shape === "Horizontal"
                      }
                    />
                    <Label
                      className="form-check-label font-size-13"
                      htmlFor="fuelUnit2"
                      style={{ paddingLeft: "10px" }}
                    >
                      <div>Horizontal</div>
                    </Label>
                  </div>
                </div>
              </div>
            </Col>
            {fuelInformationInputs?.tank?.shape === "Cube" && (
              <Col sm="12">
                <Row className="mb-1">
                  <div className="col-md-6">
                    <InputGroup>
                      <span className="input-group-text" id="basic-addon2">
                        Length
                      </span>
                      <Input
                        name="length"
                        type="number"
                        className="form-control"
                        onChange={e =>
                          updateFuelTankCube("length", e.target.value)
                        }
                        value={fuelInformationInputs?.tank?.cube?.length}
                      />
                      <span className="input-group-text" id="basic-addon2">
                        cm
                      </span>
                    </InputGroup>
                  </div>
                </Row>
                <Row className="mb-1">
                  <div className="col-md-6">
                    <InputGroup>
                      <span className="input-group-text" id="basic-addon2">
                        Width
                      </span>
                      <Input
                        name="width"
                        type="number"
                        className="form-control"
                        onChange={e =>
                          updateFuelTankCube("width", e.target.value)
                        }
                        value={fuelInformationInputs?.tank?.cube?.width}
                      />
                      <span className="input-group-text" id="basic-addon2">
                        cm
                      </span>
                    </InputGroup>
                  </div>
                </Row>
                <Row className="mb-1">
                  <div className="col-md-6">
                    <InputGroup>
                      <span className="input-group-text" id="basic-addon2">
                        Height
                      </span>
                      <Input
                        name="height"
                        type="number"
                        className="form-control"
                        onChange={e =>
                          updateFuelTankCube("height", e.target.value)
                        }
                        value={fuelInformationInputs?.tank?.cube?.height}
                      />
                      <span className="input-group-text" id="basic-addon2">
                        cm
                      </span>
                    </InputGroup>
                  </div>
                </Row>
              </Col>
            )}
            {fuelInformationInputs?.tank?.shape === "Horizontal" && (
              <Col sm="12">
                <Row className="mb-1">
                  <div className="col-md-6">
                    <InputGroup>
                      <span className="input-group-text" id="basic-addon2">
                        Length
                      </span>
                      <Input
                        name="length"
                        type="number"
                        className="form-control"
                        onChange={e =>
                          updateFuelTankHorizontal("length", e.target.value)
                        }
                        value={fuelInformationInputs?.tank?.horizontal?.length}
                      />
                      <span className="input-group-text" id="basic-addon2">
                        cm
                      </span>
                    </InputGroup>
                  </div>
                </Row>
                <Row className="mb-1">
                  <div className="col-md-6">
                    <InputGroup>
                      <span className="input-group-text" id="basic-addon2">
                        Diameter
                      </span>
                      <Input
                        name="diameter"
                        type="number"
                        className="form-control"
                        onChange={e =>
                          updateFuelTankHorizontal("diameter", e.target.value)
                        }
                        value={
                          fuelInformationInputs?.tank?.horizontal?.diameter
                        }
                      />
                      <span className="input-group-text" id="basic-addon2">
                        cm
                      </span>
                    </InputGroup>
                  </div>
                </Row>
              </Col>
            )}
            <Row>
              <Col sm="12">
                <Row className="mb-1">
                  <div className="col-md-6">
                    <label className="col-md-12 col-form-label text-start">
                      Fule tank volume
                    </label>
                    <div className="col-md-12 d-flex align-items-center">
                      <InputGroup>
                        <Input
                          name="volume"
                          type="number"
                          className="form-control"
                          onChange={e =>
                            updateFuelTank("volume", e.target.value)
                          }
                          value={fuelInformationInputs?.tank?.volume}
                        />
                        <span className="input-group-text" id="basic-addon2">
                          L
                        </span>
                      </InputGroup>
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>

            <Row className="mb-1 mt-3">
              <div className="col-md-12 d-flex justify-content-end">
                <LoadingButton
                  color="primary"
                  className="btn btn-primary btn-right"
                  loading={isUpdateDeviceLoading}
                  disabled={isUpdateDeviceLoading}
                  block={false}
                  onClick={() => onSubmitFuelDetail()}
                >
                  Submit
                </LoadingButton>
              </div>
            </Row>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row className="mb-3">
            <div className="col-md-6">
              <label className="col-md-12 col-form-label text-start">
                Input
              </label>
              <Select
                classNamePrefix="select2-selection"
                placeholder="Please Select"
                title="Type"
                name="input"
                options={sensorInputs}
                isSearchable={false}
                isClearable={false}
                onChange={selectedOption =>
                  updateFuelSensor("input", selectedOption?.value || "")
                }
                value={sensorInputs.filter(option => {
                  if (option.value == fuelInformationInputs?.sensor?.input)
                    return option
                })}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-md-6">
              <label className="col-md-12 col-form-label text-start">
                Type
              </label>
              <Select
                classNamePrefix="select2-selection"
                placeholder="Please Select"
                title="Type"
                name="type"
                options={sensorTypes}
                isSearchable={false}
                isClearable={false}
                onChange={selectedOption =>
                  updateFuelSensor("type", selectedOption?.value || "")
                }
                value={sensorTypes.filter(option => {
                  if (option.value == fuelInformationInputs?.sensor?.type)
                    return option
                })}
              />
            </div>
          </Row>
          <Row className="mb-1 mt-3">
            <div className="col-md-12 d-flex justify-content-end">
              <LoadingButton
                color="primary"
                className="btn btn-primary btn-right"
                loading={isUpdateDeviceLoading}
                disabled={isUpdateDeviceLoading}
                block={false}
                onClick={() => onSubmitFuelDetail()}
              >
                Submit
              </LoadingButton>
            </div>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col sm="12">
              <Calibration
                fuelInformationInputs={fuelInformationInputs}
                updateFuelCalibration={updateFuelCalibration}
                onSubmitFuelDetail={onSubmitFuelDetail}
              />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </React.Fragment>
  )
}

FuelDetails.propTypes = {
  fuelInformationInputs: PropTypes.obj,
  selectedDevice: PropTypes.obj,
  tog_model: PropTypes.func,
  updateFuelInformationInputs: PropTypes.func,
  putDeviceFuelAction: PropTypes.func,
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  putDeviceFuelAction: bindActionToPromise(dispatch, putDeviceFuel),
})

export default connect(mapStateToProps, mapDispatchToProps)(FuelDetails)
