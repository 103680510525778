import { TreeSelect } from "antd"
import React, { useState } from "react"
import usicons1 from "../../assets/images/user.svg"
import usicons2 from "../../assets/images/user2.svg"
import PropTypes from "prop-types"
import TreeRightMoniotor from "./TreeRightMoniotor"

import axios from "axios"
const ReportUserMenu = props => {
  const { handleCallBack, selectedValue } = props
  const userDetails = JSON.parse(localStorage.getItem("authUser"))
  const [treeLine, setTreeLine] = useState(true)
  const [showLeafIcon, setShowLeafIcon] = useState(false)
  const [treeData, setTreeData] = useState([
    {
      id: userDetails.memberId,
      pId: 0,
      value: userDetails.memberId,
      title: (
        <>
          <div>{userDetails.member.name}</div>
          <div className="ripos">
            <TreeRightMoniotor Id={userDetails.memberId} />
          </div>
        </>
      ),
      icon: <img src={usicons1} />,
    },
  ])

  const onChange = (value, label, extra) => {}

  const onSelect = (value, node, extra) => {
    handleCallBack(value, node)
  }

  const genTreeNode = async parentId => {
    const random = Math.random().toString(36).substring(2, 6)
    const axconfig = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/Member/MenuTree/SubUser/${parentId}`,
      headers: {
        accept: "/",
        Authorization: `Bearer ${userDetails.accessToken}`,
      },
    }

    const response = await axios(axconfig)

    response.data.rows.forEach(userDetails => {
      let leafstatus
      delete userDetails.key
      if (userDetails.childMemberCount === "0") {
        leafstatus = true
      } else {
        leafstatus = false
      }

      if (userDetails.role === "end_user") {
        userDetails.icon = <img src={usicons1} />
      } else if (userDetails.role === "distributor") {
        userDetails.icon = <img src={usicons1} />
      } else if (userDetails.role === "virtual") {
        userDetails.icon = <img src={usicons2} />
      }
      userDetails.id = userDetails.id
      userDetails.pId = parentId
      userDetails.value = `${userDetails.id}`
      userDetails.title = (
        <>
          <div>{userDetails.name}</div>
          <div className="ripos">
            <TreeRightMoniotor Id={userDetails.memberId} />
          </div>
        </>
      )
      userDetails.isLeaf = leafstatus
    })
    return response.data.rows
  }

  const onLoadData = ({ id }) =>
    new Promise(resolve => {
      setTimeout(async () => {
        setTreeData(treeData.concat(await genTreeNode(id)))
        resolve(undefined)
      }, 300)
    })

  return (
    <TreeSelect
      style={{ width: "100%" }}
      treeDataSimpleMode
      placeholder="Search"
      value={selectedValue}
      dropdownStyle={{ maxHeight: 100, overflow: "auto", position: "relative" }}
      onSelect={onSelect}
      onChange={onChange}
      loadData={onLoadData}
      treeData={treeData}
      // status="warning"
      allowClear
      defaultOpen={true}
      bordered={false}
      treeIcon={true}
      defaultValue="Select"
      getPopupContainer={trigger => trigger.parentNode}
      open={true}
      virtual={false}
      showSearch={true}
      //showIcon={true}
      treeLine={
        treeLine && {
          showLeafIcon,
        }
      }
      treeNodeFilterProp="name"
      // showLeafIcon={false}
      treeDefaultExpandAll={true}
      dropdownClassName="gloabtree"
      filterTreeNode={(search, item) => {
        return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
      }}
    />
  )
}

ReportUserMenu.propTypes = {
  handleCallBack: PropTypes.func,
  selectedValue: PropTypes.string,
}

export default ReportUserMenu
