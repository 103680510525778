/* eslint-disable */
import PropTypes from "prop-types"
import React, { useState } from "react"

import { connect } from "react-redux"
import { Row, Col,Modal, } from "reactstrap"
import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Button } from "reactstrap"

import { searchDeviceDefault, searchUserDefault } from '../../store/actions'

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import megamenuImg from "../../assets/images/megamenu-img.png"
import { useDispatch, useSelector } from "react-redux"

// import images
import github from "../../assets/images/brands/github.png"
import bitbucket from "../../assets/images/brands/bitbucket.png"
import dribbble from "../../assets/images/brands/dribbble.png"
import dropbox from "../../assets/images/brands/dropbox.png"
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"

import logo from "../../assets/images/logo.svg"
import monitoricons from "../../assets/images/icons/monitor.svg"

import logoLightSvg from "../../assets/images/logo-light.svg"
import TopBarMessage from "components/Message/TopBarMessage"
import SearchPop from "../../pages/Search/Search"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const Header = props => {
  const dispatch = useDispatch()
  const [searchData, setSearchData] = useState('')
  const [search, setsearch] = useState('')
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)

  const { getSearchDeviceData } = useSelector(state => {
   
    return {
      getSearchDeviceData: state.DeviceReducer.searchDeviceData,
    }
  })

  const { getSearchUserData } = useSelector(state => {
  
    return {
      getSearchUserData: state.AddUser.userSearchData,
    }
  })



  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [modal_large, setmodal_large] = useState(false);
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }
  const [SearchTab, setSearchTab] = useState(1);
  function tog_large(tabid) {
    setmodal_large(!modal_large);
    setSearchTab(tabid)
    if(tabid === 1){
  
        dispatch(searchDeviceDefault(searchData))
    } else {
      if(tabid === 2){
        dispatch(searchUserDefault(searchData))
      }
    }

    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const handleData = (e) => {
    if(e.target.value){
      setSearchData(e.target.value)
    }
  }


  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex justify-content-center align-items-center">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <div className=" me-4 ">
              <Link
                to="/monitor" target={"_blank"}
                className="monitorlinks d-flex justify-content-center align-items-center orangecol"
              >
                <img src={monitoricons} width="20" className="me-1" />{" "}
                <span>Monitor</span>
              </Link>
            </div>

            <form className="app-search d-flex">
              <div className="position-relative formsea me-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("IMEI/Account/Name") + "..."}
                  onChange={(e) => handleData(e)}
                />
                <span className="bx bx-search-alt" />
              </div>
              <Button 
                onClick={() => {
                tog_large(1)
              }}
              type="reset" color="secondary" className="btnwid" outline>
                Device
              </Button>

              <Button
                type="reset"
                color="secondary"
                className="ms-2 btnwid"
                outline
                onClick={() => {
                  tog_large(2)
                }}
              >
                User
              </Button>
            </form>
          </div>



          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* <TopBarMessage/> */}
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
      



      <Modal
        size="lg"
        isOpen={modal_large}
        toggle={() => {
          tog_large()
         
        }}
      >
        <div className="modal-header border-0">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Search
          </h5>
          <button
            onClick={() => {
              setmodal_large(false)
            }}

            

            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body minheightpop pt-0 ps-0 pe-0">
          <SearchPop GetTabId = {SearchTab} searchData={searchData} getSearchDeviceData={getSearchDeviceData} getSearchUserData={getSearchUserData} />
        </div>
      </Modal>



    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
