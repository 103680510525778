/* eslint-disable */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  UPDATE_LOGIN_USER,
  UPLOAD_IMAGE,
  LOGIN_USER_DETAIL,
} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  updateLoginUserSuccess,
  updateLoginUserError,
  uploadImageSuccess,
  uploadImageError,
  loginUserDetailSuccess,
  loginUserDetailError,
} from "./actions"

import {
  postAuthLogin,
  postJwtLogin,
  updateLoginUser,
  getLoggedInUser,
  imageUpload,
  getLoginUserDetail,
} from "../../../helpers/fakebackend_helper"

import { showToast } from "../../../utils/Common"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postAuthLogin, {
      ...user,
      deviceType: "web",
    })

    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response))

    if (
      response?.roles?.includes("admin") ||
      response?.roles?.includes("Distributor") ||
      response?.roles?.includes("distributor")
    ) {
      window.location = "/dashboard"
    } else {
      window.location = "/monitor"
      //history.push({ pathname: "/monitor" })
    }
    showToast("Login success", true)
  } catch (error) {
    yield put(
      apiError(error?.response?.data?.message || "Something Want Wrong!")
    )
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    yield put(logoutUserSuccess())
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* onUpdateLoginUser({
  payload: { userData, id, history, isFileUpload, files },
}) {
  try {
    if (isFileUpload) {
      const responseFiles = yield call(imageUpload, { images: files })
      const response = yield call(updateLoginUser, {
        ...userData,
        profileImage: responseFiles[0].key,
      })

      const oldData = getLoggedInUser()
      const newData = { ...oldData, member: { ...oldData.member, ...response } }
      localStorage.setItem("authUser", JSON.stringify(newData))
      yield put(updateLoginUserSuccess(newData))
      showToast("User updated succesfully", true)
    } else {
      const response = yield call(updateLoginUser, { ...userData })

      const oldData = getLoggedInUser()
      const newData = { ...oldData, member: { ...oldData.member, ...response } }
      localStorage.setItem("authUser", JSON.stringify(newData))
      yield put(updateLoginUserSuccess(newData))
      showToast("User updated succesfully", true)
    }
  } catch (error) {
    yield put(updateLoginUserError(error?.response?.data.message))
    showToast(`${error?.response?.data.message}`, false)
  }
}

function* onUploadImage({ payload: images }) {
  try {
    const response = yield call(imageUpload, images)

    yield put(uploadImageSuccess(response))
    // showToast("User added succesfully", true)
  } catch (error) {
    yield put(uploadImageError())
    showToast(`${error?.response?.data.message}`, false)
  }
}

function* loginUserDetailSaga() {
  try {
    const response = yield call(getLoginUserDetail)
    yield put(loginUserDetailSuccess(response))
  } catch (error) {
    yield put(loginUserDetailError())
    showToast(`${error?.response?.data.message}`, false)
  }
}

function* authSaga() {
  yield takeLatest(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(UPDATE_LOGIN_USER, onUpdateLoginUser)
  yield takeLatest(UPLOAD_IMAGE, onUploadImage)
  yield takeLatest(LOGIN_USER_DETAIL, loginUserDetailSaga)
}

export default authSaga
