/* eslint-disable */

import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import LoadingButton from "../../components/Common/Button"

import CheckPopup from "./CheckPopup"

import { checkImeiDefault, modifyExpiryDate } from "../../store/actions"
import { connect, useDispatch, useSelector } from "react-redux"
import { bindActionToPromise } from "utils/Common"
import moment from "moment"

function ModifyExpiryDate(props) {
  const { setToggelModel, selectedRows } = props
  const today = new Date()
  const minDateDisable = new Date(today)
  minDateDisable.setDate(minDateDisable.getDate() + 1)
  const dispatch = useDispatch()
  const [popoverright, setpopoverright] = useState(false)

  const [userDue, setUserDue] = useState(minDateDisable)
  const [imeiList, setImeiList] = useState([])
  const [errorUserDue, setErrorUserDue] = useState("")
  const [errorImei, setErrorImei] = useState("")

  useEffect(() => {
    if (selectedRows && selectedRows.length > 0) {
      setImeiList(selectedRows)
    }
  }, [])

  const { checkImeiData, isLoadingCheckImei, isLoadingModifyExpDate } =
    useSelector(state => {
      return {
        checkImeiData: state.AddDevice.imeiResData,
        isLoadingCheckImei: state.AddDevice.isLoadingCheckImei,
        isLoadingModifyExpDate: state.AddDevice.isLoadingModifyExpDate,
      }
    })

  const updateImei = value => {
    setErrorImei("")
    const updateArray = value ? value.split("\n") : []
    setImeiList(updateArray)
  }

  const updateUserDue = value => {
    setErrorUserDue("")
    setUserDue(value)
  }
  const handleCheckImei = () => {
    const imeiData = {
      imei: imeiList,
    }
    props.checkImeiDefaultAction(imeiData).then(() => {
      setpopoverright(true)
    })
  }

  const handleSubmit = () => {
    if (userDue === "" || userDue === null) {
      setErrorUserDue("Please Select User due")
    }

    if (imeiList.length <= 0) setErrorImei("Please Enter IMEI")

    if (userDue !== "" && userDue !== null && imeiList.length > 0) {
      props
        .modifyExpiryDateAction({
          imei: imeiList,
          userDue: userDue,
        })
        .then(() => {
          setToggelModel(false)
        })
        .catch(e => {
          setToggelModel(false)
        })
    }
  }

  return (
    <div>
      <div className="modal-body">
        <Row className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
            User due<small className="text-danger">*</small>
          </label>
          <div className="col-md-8">
            <DatePicker
              name="platformExpirationDate"
              className="form-control"
              selected={userDue || null}
              onChange={date => updateUserDue(date)}
              placeholderText=""
              minDate={minDateDisable}
            />
            {errorUserDue ? (
              <FormFeedback type="invalid" style={{ display: "block" }}>
                {errorUserDue}
              </FormFeedback>
            ) : null}
          </div>
        </Row>
        <Row className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-4 col-form-label text-start"
          >
            IMEI <small className="text-danger">*</small>
          </label>
          <div className="col-md-8">
            <div className="row gx-2">
              <div className="col">
                <Input
                  type="textarea"
                  placeholder="Enter Imei"
                  value={imeiList ? imeiList.join("\n") : ""}
                  onChange={e => updateImei(e.target.value)}
                  rows={5}
                />
                <span className="font-size-12">
                  One batch for 200 IMEI at most.
                </span>
                {errorImei ? (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    {errorImei}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="col-auto">
                <LoadingButton
                  id="Popoverright"
                  color="primary"
                  className="btn btn-primary btn-sm"
                  loading={isLoadingCheckImei}
                  disabled={isLoadingCheckImei || imeiList.length <= 0}
                  block={false}
                  onClick={() => handleCheckImei()}
                >
                  Check
                </LoadingButton>

                <Popover
                  placement="right"
                  isOpen={popoverright}
                  target="Popoverright"
                  toggle={() => {
                    setpopoverright(!popoverright)
                  }}
                  className="width350"
                >
                  {checkImeiData && !isLoadingCheckImei && (
                    <PopoverBody>
                      <span
                        id="Popoverright"
                        onClick={() => {
                          setpopoverright(!popoverright)
                        }}
                        className="popoverclose"
                      >
                        <i className="mdi mdi-close"></i>
                      </span>
                      <CheckPopup checkImeiData={checkImeiData} />
                    </PopoverBody>
                  )}
                </Popover>
              </div>
            </div>
          </div>
        </Row>
      </div>
      <div className="modal-footer">
        <LoadingButton
          color="primary"
          className="btn btn-primary"
          loading={isLoadingModifyExpDate}
          disabled={isLoadingModifyExpDate}
          block={false}
          onClick={() => handleSubmit()}
        >
          Save changes
        </LoadingButton>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  checkImeiDefaultAction: bindActionToPromise(dispatch, checkImeiDefault),
  modifyExpiryDateAction: bindActionToPromise(dispatch, modifyExpiryDate),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModifyExpiryDate)
