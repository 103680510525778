/* eslint-disable */
import { call, put, takeLatest } from "redux-saga/effects"
import { CarryOutOutlined, FormOutlined } from "@ant-design/icons"

// Crypto Redux States
import { GET_TRACK_INITIAL } from "./actionTypes"

import { getTrackDetailsSuccess, getTrackDetailsFail } from "./actions"

//Include Both Helper File with needed methods
import { getTrackDetailsData } from "../../../helpers/fakebackend_helper"

function* fetchTrackDetails({ deviceId }) {
  try {
    const response = yield call(getTrackDetailsData, deviceId)
    yield put(getTrackDetailsSuccess(response))
  } catch (error) {
    yield put(getTrackDetailsFail(error))
  }
}


function* trackSaga() {
  yield takeLatest(GET_TRACK_INITIAL, fetchTrackDetails)
}

export default trackSaga
