/* eslint-disable */

import React, { useState } from "react"
import { Modal, InputGroup } from "reactstrap"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import lefticon17 from "../../../assets/images/icons/share.svg"
import Tooltip from "@mui/material/Tooltip"

const ShareLocation = () => {
  const [ShareLocation, setShareLocation] = useState(false)
  function ShareLocationBtn() {
    setShareLocation(!ShareLocation)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  return (
    <div>
      <span
        onClick={() => {
          ShareLocationBtn()
        }}
        className="cursor-pointer"
      >
        <Tooltip title="Share Location" placement="bottom" arrow>
          <img alt="" src={lefticon17} width={21} />
        </Tooltip>
      </span>

      <Modal
        isOpen={ShareLocation}
        size="md"
        toggle={() => {
          ShareLocationBtn()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Share Location</h5>
          <button
            type="button"
            onClick={() => {
              setShareLocation(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>

        <div className="form_share p-3">
          <div className="pb-2">
            <label>
              Device : <small className="text-danger">*</small>
            </label>
            <div>
              <select name="" id="" className="form-control">
                <option value="">Select</option>
              </select>
            </div>
          </div>
          <div className="pb-2">
            <label>
              Share Name <small className="text-danger">*</small>
            </label>
            <div>
              <input type="text" className="form-control" />
            </div>
          </div>
          <div className="pb-2">
            <label>
              Start time : <small className="text-danger">*</small>
            </label>
            <div>
              <InputGroup>
                <Flatpickr
                  className="form-control d-block"
                  placeholder=""
                  options={{
                    altInput: true,
                    enableTime: true,
                    dateFormat: "Y-m-d H:i",
                    time_24hr: true,
                    defaultDate: ["2016-10-20"],
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="pb-2">
            <label>
              End time : <small className="text-danger">*</small>
            </label>
            <div>
              <InputGroup>
                <Flatpickr
                  className="form-control d-block"
                  placeholder=""
                  options={{
                    altInput: true,
                    enableTime: true,
                    dateFormat: "Y-m-d H:i",
                    time_24hr: true,
                    defaultDate: ["2016-10-20"],
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="txt_link_2">
            <span className="ps-0">Today</span>
            <span>Tomorrow</span>
            <span className="border-end-0">Three Days</span>
          </div>
          <div className="pb-2">
            <label>
              Remarks <small className="text-danger">*</small>
            </label>
            <div>
              <textarea className="form-control" rows={1}></textarea>
            </div>
          </div>
          <div className="text-end">
            <button
              onClick={() => {
                setShareLocation(false)
              }}
              className="btn btn-sm btn-outline-primary me-2"
            >
              Cancel
            </button>
            <button className="btn btn-sm btn-primary">Submit</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ShareLocation
