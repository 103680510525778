/* eslint-disable */

import React, { useState } from "react"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import classnames from "classnames"
import Tooltip from "@mui/material/Tooltip"
import "./MapPopStyle.css"

const SendCommandInfo = () => {
  const [activeTab, setActiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <div>
      <div className="row gx-0">
        <div className="col-lg-4 border-end">
          <header className="p-2 border-bottom">
            <i className="bx bxs-down-arrow fs-7 me-2"></i>
            Command List
          </header>
          <div className="tabs_head_comnd">
            <Nav
              tabs
              className="position-relative flex-column d-flex"
              role="tablist"
            >
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggleTab("1")
                  }}
                >
                  Check Location
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggleTab("2")
                  }}
                >
                  Stop Engine
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    toggleTab("3")
                  }}
                >
                  Restore Engine
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "4",
                  })}
                  onClick={() => {
                    toggleTab("4")
                  }}
                >
                  SOS Number
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "5",
                  })}
                  onClick={() => {
                    toggleTab("5")
                  }}
                >
                  Check Status
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "6",
                  })}
                  onClick={() => {
                    toggleTab("6")
                  }}
                >
                  Check Parameter
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "7",
                  })}
                  onClick={() => {
                    toggleTab("7")
                  }}
                >
                  Check Setting
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "8",
                  })}
                  onClick={() => {
                    toggleTab("8")
                  }}
                >
                  Firmwave Version
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "9",
                  })}
                  onClick={() => {
                    toggleTab("9")
                  }}
                >
                  Reset
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "10",
                  })}
                  onClick={() => {
                    toggleTab("10")
                  }}
                >
                  More
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="tabs_info_command">
            <TabContent
              activeTab={activeTab}
              className="crypto-buy-sell-nav-content p-0 border-0"
            >
              <TabPane tabId="1">
                <div className="">
                  <header className="d-flex align-items-center border-bottom p-2">
                    <i className="bx bx-right-arrow-alt fs-5 me-2"></i>
                    Check Location
                    <Tooltip
                      title="Command fail? Please check the device is power on? Please check if the device location is with normal GSM/GPS signals Please check the device is supports for this command? "
                      placement="right"
                      arrow
                    >
                      <i className="bx bxs-info-circle fs-5 txt_link ms-2"></i>
                    </Tooltip>
                  </header>
                  <div className="p-2">
                    <div className="row pb-3">
                      <div className="col-3 text-end">
                        <span className="pt-1 d-block">Device Name</span>
                      </div>
                      <div className="col-8">
                        <input
                          type="text"
                          placeholder="Bike"
                          className="form-control w-auto"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8 offset-lg-3">
                        <div className="pt-3">
                          <button className="btn btn-sm btn-primary">
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>

              <TabPane tabId="2">
                <div className="">
                  <div className="">
                    <header className="d-flex align-items-center border-bottom p-2">
                      <i className="bx bx-right-arrow-alt fs-5 me-2"></i>
                      Stop Engine
                      <Tooltip
                        title="Command fail? Please check the device is power on? Please check if the device location is with normal GSM/GPS signals Please check the device is supports for this command? "
                        placement="right"
                        arrow
                      >
                        <i className="bx bxs-info-circle fs-5 txt_link ms-2"></i>
                      </Tooltip>
                    </header>
                    <div className="p-2">
                      <div className="row">
                        <div className="col-3 text-end">
                          <span className="pt-1 d-block">Device Name</span>
                        </div>
                        <div className="col-8">
                          <input
                            type="text"
                            readOnly
                            placeholder="Bike"
                            className="form-control w-auto"
                          />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-3 text-end">
                          <span className="pt-1 d-block">Password <small className="text-danger">*</small></span>
                        </div>
                        <div className="col-8">
                          <input
                            type="password"
                            className="form-control w-auto"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8 offset-lg-3">
                          <div className="pt-3">
                            <button className="btn btn-sm btn-primary">
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="">
                  <div className="">
                    <header className="d-flex align-items-center border-bottom p-2">
                      <i className="bx bx-right-arrow-alt fs-5 me-2"></i>
                      Restore Engine
                      <Tooltip
                        title="Command fail? Please check the device is power on? Please check if the device location is with normal GSM/GPS signals Please check the device is supports for this command? "
                        placement="right"
                        arrow
                      >
                        <i className="bx bxs-info-circle fs-5 txt_link ms-2"></i>
                      </Tooltip>
                    </header>
                    <div className="p-2">
                      <div className="row">
                        <div className="col-3 text-end">
                          <span className="pt-1 d-block">Device Name</span>
                        </div>
                        <div className="col-8">
                          <input
                            type="text"
                            readOnly
                            placeholder="Bike"
                            className="form-control w-auto"
                          />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-3 text-end">
                          <span className="pt-1 d-block">Password <small className="text-danger">*</small></span>
                        </div>
                        <div className="col-8">
                          <input
                            type="password"
                            className="form-control w-auto"
                          />
                          <span>Please input your password.</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8 offset-lg-3">
                          <div className="pt-3">
                            <button className="btn btn-sm btn-primary">
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div className="">
                  <div className="">
                    <header className="d-flex align-items-center border-bottom p-2">
                      <i className="bx bx-right-arrow-alt fs-5 me-2"></i>
                      SOS Number
                      <Tooltip
                        title="Command fail? Please check the device is power on? Please check if the device location is with normal GSM/GPS signals Please check the device is supports for this command? "
                        placement="right"
                        arrow
                      >
                        <i className="bx bxs-info-circle fs-5 txt_link ms-2"></i>
                      </Tooltip>
                    </header>
                    <div className="p-2">
                      <div className="row">
                        <div className="col-3 text-end">
                          <span className="pt-1 d-block">Device Name</span>
                        </div>
                        <div className="col-8">
                          <input
                            type="text"
                            readOnly
                            placeholder="Bike"
                            className="form-control w-auto"
                          />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-3 text-end">
                          <span className="pt-1 d-block">SOS No. 1</span>
                        </div>
                        <div className="col-8">
                          <input type="text" className="form-control w-auto" />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-3 text-end">
                          <span className="pt-1 d-block">SOS No. 2</span>
                        </div>
                        <div className="col-8">
                          <input type="text" className="form-control w-auto" />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-3 text-end">
                          <span className="pt-1 d-block">SOS No. 3</span>
                        </div>
                        <div className="col-8">
                          <input type="text" className="form-control w-auto" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8 offset-lg-3">
                          <div className="pt-3">
                            <button className="btn btn-sm btn-primary">
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div className="">
                  <header className="d-flex align-items-center border-bottom p-2">
                    <i className="bx bx-right-arrow-alt fs-5 me-2"></i>
                    Check Status
                    <Tooltip
                      title="Command fail? Please check the device is power on? Please check if the device location is with normal GSM/GPS signals Please check the device is supports for this command? "
                      placement="right"
                      arrow
                    >
                      <i className="bx bxs-info-circle fs-5 txt_link ms-2"></i>
                    </Tooltip>
                  </header>
                  <div className="p-2">
                    <div className="row">
                      <div className="col-3 text-end">
                        <span className="pt-1 d-block">Device Name</span>
                      </div>
                      <div className="col-8">
                        <input
                          type="text"
                          readOnly
                          placeholder="Bike"
                          className="form-control w-auto"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8 offset-lg-3">
                        <div className="pt-3">
                          <button className="btn btn-sm btn-primary">
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="6">
                <div className="">
                  <header className="d-flex align-items-center border-bottom p-2">
                    <i className="bx bx-right-arrow-alt fs-5 me-2"></i>
                    Check Parameter
                    <Tooltip
                      title="Command fail? Please check the device is power on? Please check if the device location is with normal GSM/GPS signals Please check the device is supports for this command? "
                      placement="right"
                      arrow
                    >
                      <i className="bx bxs-info-circle fs-5 txt_link ms-2"></i>
                    </Tooltip>
                  </header>
                  <div className="p-2">
                    <div className="row">
                      <div className="col-3 text-end">
                        <span className="pt-1 d-block">Device Name</span>
                      </div>
                      <div className="col-8">
                        <input
                          type="text"
                          readOnly
                          placeholder="Bike"
                          className="form-control w-auto"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8 offset-lg-3">
                        <div className="pt-3">
                          <button className="btn btn-sm btn-primary">
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="7">
                <div className="">
                  <header className="d-flex align-items-center border-bottom p-2">
                    <i className="bx bx-right-arrow-alt fs-5 me-2"></i>
                    Check Settings
                    <Tooltip
                      title="Command fail? Please check the device is power on? Please check if the device location is with normal GSM/GPS signals Please check the device is supports for this command? "
                      placement="right"
                      arrow
                    >
                      <i className="bx bxs-info-circle fs-5 txt_link ms-2"></i>
                    </Tooltip>
                  </header>
                  <div className="p-2">
                    <div className="row">
                      <div className="col-3 text-end">
                        <span className="pt-1 d-block">Device Name</span>
                      </div>
                      <div className="col-8">
                        <input
                          type="text"
                          readOnly
                          placeholder="Bike"
                          className="form-control w-auto"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8 offset-lg-3">
                        <div className="pt-3">
                          <button className="btn btn-sm btn-primary">
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="8">
                <div className="">
                  <header className="d-flex align-items-center border-bottom p-2">
                    <i className="bx bx-right-arrow-alt fs-5 me-2"></i>
                    Firmware Version
                    <Tooltip
                      title="Command fail? Please check the device is power on? Please check if the device location is with normal GSM/GPS signals Please check the device is supports for this command? "
                      placement="right"
                      arrow
                    >
                      <i className="bx bxs-info-circle fs-5 txt_link ms-2"></i>
                    </Tooltip>
                  </header>
                  <div className="p-2">
                    <div className="row">
                      <div className="col-3 text-end">
                        <span className="pt-1 d-block">Device Name</span>
                      </div>
                      <div className="col-8">
                        <input
                          type="text"
                          readOnly
                          placeholder="Bike"
                          className="form-control w-auto"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8 offset-lg-3">
                        <div className="pt-3">
                          <button className="btn btn-sm btn-primary">
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="9">
                <div className="">
                  <div className="">
                    <header className="d-flex align-items-center border-bottom p-2">
                      <i className="bx bx-right-arrow-alt fs-5 me-2"></i>
                      Reset
                      <Tooltip
                        title="Command fail? Please check the device is power on? Please check if the device location is with normal GSM/GPS signals Please check the device is supports for this command? "
                        placement="right"
                        arrow
                      >
                        <i className="bx bxs-info-circle fs-5 txt_link ms-2"></i>
                      </Tooltip>
                    </header>
                    <div className="p-2">
                      <div className="row">
                        <div className="col-3 text-end">
                          <span className="pt-1 d-block">Device Name</span>
                        </div>
                        <div className="col-8">
                          <input
                            type="text"
                            readOnly
                            placeholder="Bike"
                            className="form-control w-auto"
                          />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-3 text-end">
                          <span className="pt-1 d-block">Password <small className="text-danger">*</small></span>
                        </div>
                        <div className="col-8">
                          <input
                            type="password"
                            className="form-control w-auto"
                          />
                          <span>Please input your password.</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8 offset-lg-3">
                          <div className="pt-3">
                            <button className="btn btn-sm btn-primary">
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="10">
                <div className="">
                  <div className="">
                    <header className="d-flex align-items-center border-bottom p-2">
                      <i className="bx bx-right-arrow-alt fs-5 me-2"></i>
                      Reset
                      <Tooltip
                        title="Command fail? Please check the device is power on? Please check if the device location is with normal GSM/GPS signals Please check the device is supports for this command? "
                        placement="right"
                        arrow
                      >
                        <i className="bx bxs-info-circle fs-5 txt_link ms-2"></i>
                      </Tooltip>
                    </header>
                    <div className="p-2">
                      <div className="row">
                        <div className="col-3 text-end">
                          <span className="pt-1 d-block">Device Name</span>
                        </div>
                        <div className="col-8">
                          <input
                            type="text"
                            readOnly
                            placeholder="Bike"
                            className="form-control w-auto"
                          />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-3 text-end">
                          <span className="pt-1 d-block">Command <small className="text-danger">*</small></span>
                        </div>
                        <div className="col-8">
                          <input type="text" className="form-control w-auto" />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-3 text-end">
                          <span className="pt-1 d-block">Password <small className="text-danger">*</small></span>
                        </div>
                        <div className="col-8">
                          <input
                            type="password"
                            className="form-control w-auto"
                          />
                          <span>Please input your password.</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8 offset-lg-3">
                          <div className="pt-3">
                            <button className="btn btn-sm btn-primary">
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendCommandInfo
