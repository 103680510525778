import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  UPDATE_LOGIN_USER,
  UPDATE_LOGIN_USER_SUCCESS,
  UPDATE_LOGIN_USER_ERROR,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  LOGIN_USER_DETAIL,
  LOGIN_USER_DETAIL_SUCCESS,
  LOGIN_USER_DETAIL_ERROR,
} from "./actionTypes"

import { getLoggedInUser } from "../../../helpers/fakebackend_helper"

const userLocalData = getLoggedInUser()

const initialState = {
  error: "",
  loading: false,
  profileData: userLocalData,
  imageData: [],
  isLoading: false,
  loadingData: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        profileData: action.payload,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case UPDATE_LOGIN_USER:
      return {
        ...state,
        isLoading: true,
      }

    case UPDATE_LOGIN_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profileData: action.payload,
      }

    case UPDATE_LOGIN_USER_ERROR:
      return {
        ...state,
        isLoading: false,
      }

    case UPLOAD_IMAGE:
      state = {
        ...state,
        isLoading: true,
      }
      break

    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        imageData: action.payload,
        isLoading: false,
      }

    case UPLOAD_IMAGE_ERROR:
      return {
        ...state,
        isLoading: false,
      }

    case LOGIN_USER_DETAIL:
      state = {
        ...state,
        loadingData: true,
      }
      break

    case LOGIN_USER_DETAIL_SUCCESS:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          member: { ...state.profileData.member, ...action.payload },
        },
        loadingData: false,
      }

    case LOGIN_USER_DETAIL_ERROR:
      return {
        ...state,
        loadingData: false,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default login
