/* eslint-disable */

import React from "react"
import "./MonitorStyle.css"
import MonitorBottomBar from "../../components/Monitor/MonitorBottom"
import Track from "./track/Index"


const PagesStarter = () => {
  return (
    <React.Fragment>
      <Track />
     <div className="mapbottombar w-100">
      <MonitorBottomBar />
      </div> 
    </React.Fragment>
  )
}

export default PagesStarter
