import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Collapse } from "reactstrap"
import { NavLink } from "react-router-dom"
import UserLists from "../user/reportUserMenu"
import PropTypes from "prop-types"

const ReportAside = props => {
  const { selectedUser, setSelectedUser } = props
  const location = useLocation()
  const OffHeight = "h-50"
  const FullHeight = "h-100"
  const AccordianEnable = "bxs-down-arrow"
  const AccordianDisable = "bxs-right-arrow"
  const [MyUser, setMyuser] = useState(true)

  const Myuserbtn = () => {
    setMyuser(!MyUser)
  }
  const isOperationalReport =
    location.pathname.includes("movingoverview") ||
    location.pathname.includes("mileagereport") ||
    location.pathname.includes("speedingdetails") ||
    location.pathname.includes("parkingdetails")
  const [OperationStatistics, setOperationStatistics] = useState(
    isOperationalReport || false
  )
  const OperationStatisticsBtn = () => {
    setOperationStatistics(!OperationStatistics)
  }

  const isTripReport =
    location.pathname.includes("tripreport") ||
    location.pathname.includes("speedtripreport")
  const [Trip, setTrip] = useState(isTripReport || false)

  const TripsBtn = () => {
    setTrip(!Trip)
  }

  const isEngineReport =
    location.pathname.includes("engineoverview") ||
    location.pathname.includes("enginereport") ||
    location.pathname.includes("engineidlereport")
  const [engineReport, setEngineReport] = useState(isEngineReport || false)

  const isAlertReport =
    location.pathname.includes("alertoverview") ||
    location.pathname.includes("alertreport") ||
    location.pathname.includes("alertdetails") ||
    location.pathname.includes("geofencealert")
  const [alertReport, setAlertReport] = useState(isAlertReport || false)

  function handleCallBack(value, node) {
    setSelectedUser({ ...node })
  }

  return (
    <div className="col-auto">
      <div className="report_left h-100 d-flex flex-column">
        <div
          className={`panel_main d-flex flex-column ${
            MyUser ? OffHeight : null
          }`}
        >
          <div className="head_main cursor-pointer flx_0" onClick={Myuserbtn}>
            <i
              className={`bx fs-7 me-2 ${
                MyUser ? AccordianEnable : AccordianDisable
              }`}
            ></i>
            My users
          </div>
          <Collapse isOpen={MyUser} className="flx_1 over_auto box_shadow">
            <UserLists
              handleCallBack={handleCallBack}
              selectedValue={selectedUser?.id}
              // TreeHeight={Math.round(height)}
            />
          </Collapse>
        </div>

        <div
          className={`panel_main box_shadow d-flex flex-column over_auto ${
            MyUser ? OffHeight : FullHeight
          }`}
        >
          <div className="head_main cursor-pointer flx_0">
            <i className=" bx bx-bar-chart-alt"></i> Report Overview
          </div>
          <div className="ro_list flx_1 over_auto">
            <div className="ro_panel">
              <div
                className="accor_head cursor-pointer"
                onClick={OperationStatisticsBtn}
              >
                <i
                  className={`bx fs-7 pe-2 ${
                    OperationStatistics ? AccordianEnable : AccordianDisable
                  }`}
                ></i>
                Operation Statistics
              </div>
              <Collapse isOpen={OperationStatistics} className="bg-white">
                <div className="or_acc_menu">
                  <ul>
                    <li>
                      <NavLink
                        className="acco_link"
                        to="/report/movingoverview"
                      >
                        Moving overview{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="acco_link" to="/report/mileagereport">
                        Mileage report{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="acco_link"
                        to="/report/speedingdetails"
                      >
                        Speeding details{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="acco_link"
                        to="/report/parkingdetails"
                      >
                        Parking details
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Collapse>

              <div className="accor_head cursor-pointer" onClick={TripsBtn}>
                <i
                  className={`bx fs-7 pe-2 ${
                    Trip ? AccordianEnable : AccordianDisable
                  }`}
                ></i>
                Trip report
              </div>
              <Collapse isOpen={Trip} className="bg-white">
                <div className="or_acc_menu">
                  <ul>
                    <li>
                      <NavLink className="acco_link" to="/report/tripreport">
                        Trip report
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="acco_link"
                        to="/report/speedtripreport"
                      >
                        Speeding trip report
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Collapse>
              <div
                className="accor_head cursor-pointer"
                onClick={() => setEngineReport(!engineReport)}
              >
                <i
                  className={`bx fs-7 pe-2 ${
                    engineReport ? AccordianEnable : AccordianDisable
                  }`}
                ></i>
                Engine report
              </div>
              <Collapse isOpen={engineReport} className="bg-white">
                <div className="or_acc_menu">
                  <ul>
                    <li>
                      <NavLink
                        className="acco_link"
                        to="/report/engineoverview"
                      >
                        Engine overview{" "}
                      </NavLink>
                    </li>

                    <li>
                      <NavLink className="acco_link" to="/report/enginereport">
                        Engine report{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="acco_link"
                        to="/report/engineidlereport"
                      >
                        Engine idle report{" "}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Collapse>
              <div
                className="accor_head cursor-pointer"
                onClick={() => setAlertReport(!alertReport)}
              >
                <i
                  className={`bx fs-7 pe-2 ${
                    alertReport ? AccordianEnable : AccordianDisable
                  }`}
                ></i>
                Alert report
              </div>
              <Collapse isOpen={alertReport} className="bg-white">
                <div className="or_acc_menu">
                  <ul>
                    <li>
                      <NavLink className="acco_link" to="/report/alertoverview">
                        Alert Overview{" "}
                      </NavLink>
                    </li>

                    <li>
                      <NavLink className="acco_link" to="/report/alertreport">
                        Alert Report{" "}
                      </NavLink>
                    </li>

                    <li>
                      <NavLink className="acco_link" to="/report/alertdetails">
                        Alert Details{" "}
                      </NavLink>
                    </li>

                    <li>
                      <NavLink className="acco_link" to="/report/geofencealert">
                        Geo-fence alert report{" "}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ReportAside.propTypes = {
  setSelectedUser: PropTypes.func,
  selectedUser: PropTypes.object,
}

export default ReportAside
