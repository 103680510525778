import React, { useState, useEffect, memo } from "react"
//Import Breadcrumb
import { Popup, Tooltip, useMap } from "react-leaflet"
import PropTypes from "prop-types"
import airplaneIcon from "./arrow_head.png"
import L from "leaflet"
import "./playback.css"
import icon from 'leaflet/dist/images/marker-icon.png';
var myIcon = L.divIcon({ className: 'dot' });



import moment from "moment"



function AddBulkMarker({ markerData }) {
    const map = useMap();



    useEffect(() => {
        if (!map || !markerData || markerData.length === 0) return;

        const markers = [];

        for (const item of markerData) {
            // Check if lat and lng are valid numbers
            if (isNaN(item.lat) || isNaN(item.lng)) {
                console.error(`Invalid coordinates for marker: ${item.lat}, ${item.lng}`);
                continue; // Skip this marker
            }
            console.log(item)
            const marker = L.marker([item.lat, item.lng], {
                icon: myIcon, // Assuming 'icon' is correctly defined
            }).bindPopup(`<div class="play-table-detail-windows"><p>Datetime: ${moment(item.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</p>         <p>Coordinate: <a href="http://map.google.com/maps?q=${item.lat},${item.lng}" target="_blank">${item.lat},${item.lng}</a></p>         <p>Speed: ${item.speed}kph</p>         <p>Course: ${item.courseStatus.course}°</p>  </div>`).addTo(map);
            marker._icon.classList.add("dot");
            markers.push(marker);
        }

        if (markers.length === 0) {
            console.warn('No valid markers to display.');
            return;
        }

        // Calculate bounds only if there are valid markers
        const bounds = L.latLngBounds(markers.map(marker => marker.getLatLng()));
        map.fitBounds(bounds);
    }, [map]);

    return null;
}
AddBulkMarker.propTypes = {
    markerData: PropTypes.array
}

export default memo(AddBulkMarker)