/* eslint-disable */
import PropTypes from "prop-types"
import React from "react"
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"
// const obj = _.pickBy(d, (h) => !_.isUndefined(h));
// const _ = require('lodash');
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

import { Route, Redirect } from "react-router-dom"

// Import all middleware
import Authmiddleware from "./routes/route"

import { getLoggedInUser } from "./helpers/fakebackend_helper"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import axios from "axios"
// Import scss
import "./assets/scss/theme.scss"

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT';




// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// Activating fake backend
// fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  const userDetail = getLoggedInUser()
  const options = {
    // you can also just use 'bottom center'
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE
  }
  return (
    <React.Fragment>
      <AlertProvider template={AlertTemplate} {...options}>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
              route={route}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={route.NonAuthLayout ? route.NonAuthLayout : Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
              route={route}
            />
          ))}
          <Route
            path="*"
            render={props => {
              if (userDetail && userDetail.roles.includes("admin")) {
                return (
                  <Redirect
                    to={{
                      pathname: "/dashboard",
                      state: { from: props.location },
                    }}
                  />
                )
              }

              if (
                userDetail &&
                (userDetail.roles.includes("distributor") ||
                  userDetail.roles.includes("end_user"))
              ) {
                return (
                  <Redirect
                    to={{
                      pathname: "/monitor",
                      state: { from: props.location },
                    }}
                  />
                )
              }

              return (
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
              )
            }}
          />
        </Switch>
      </Router>
      </AlertProvider>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
