/* eslint-disable */

import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"

import { Row, Input } from "reactstrap"

import { connect } from "react-redux"

import LoadingButton from "../../components/Common/Button"

import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"

import { putDeviceByDeviceId } from "../../store/actions"

import { bindActionToPromise } from "../../utils/Common"

import { useSelector } from "react-redux"

function DeviceOtherInformation(props) {
  const {
    deviceOtherInformationInputs,
    updateDeviceOtherInformationInputs,
    selectedDevice,
    selectedUser,
    deviceInfoInputs,
    tog_model,
  } = props

  const [simple_color, setsimple_color] = useState(0)
  const onDrag = c1 => {
    updateDeviceOtherInformationInputs("color", c1)
  }

  const { isUpdateDeviceLoading } = useSelector(state => {
    return {
      isUpdateDeviceLoading: state.Device.isUpdateDeviceLoading,
    }
  })

  function generatePayloadForUpdate() {
    return {
      id: selectedDevice?.id,
      data: {
        name: deviceInfoInputs?.name,
        imei: deviceInfoInputs?.imei,
        memberId: selectedUser?.id,
        model: deviceInfoInputs?.model,
        deviceType: deviceInfoInputs?.deviceType,
        remark: deviceInfoInputs?.remark,
        vehiclePlatNumber: deviceInfoInputs?.vehiclePlatNumber,
        simCard: deviceInfoInputs?.simCard,
        ...(deviceInfoInputs?.exFactoryDate
          ? { exFactoryDate: deviceInfoInputs?.exFactoryDate }
          : {}),
        activatedDate: deviceInfoInputs?.activatedDate,
        platformExpirationDate: deviceInfoInputs?.platformExpirationDate,
        ...(deviceInfoInputs?.userDue
          ? { userDue: deviceInfoInputs?.userDue }
          : {}),
        icon: deviceInfoInputs?.icon,
        vehiclePicture: null,
        otherInfo: { ...deviceOtherInformationInputs },
      },
    }
  }

  return (
    <React.Fragment>
      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Driver Name:
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="driverName"
              type="text"
              className="form-control"
              onChange={e =>
                updateDeviceOtherInformationInputs("driverName", e.target.value)
              }
              value={deviceOtherInformationInputs.driverName}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">Mobile:</label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="mobile"
              type="text"
              className="form-control"
              onChange={e =>
                updateDeviceOtherInformationInputs("mobile", e.target.value)
              }
              value={deviceOtherInformationInputs.mobile}
            />
          </div>
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">VIN:</label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="vin"
              type="text"
              className="form-control"
              onChange={e =>
                updateDeviceOtherInformationInputs("vin", e.target.value)
              }
              value={deviceOtherInformationInputs.vin}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Engine Number:
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="engineNumber"
              type="text"
              className="form-control"
              onChange={e =>
                updateDeviceOtherInformationInputs(
                  "engineNumber",
                  e.target.value
                )
              }
              value={deviceOtherInformationInputs.engineNumber}
            />
          </div>
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">Color:</label>
          <div className="col-md-12 d-flex align-items-center">
            <div className="w-100 mb-3">
              <Input
                type="text"
                className="form-control colorpicker-default"
                value={deviceOtherInformationInputs.color}
                onClick={() => {
                  setsimple_color(!simple_color)
                }}
                readOnly
              />
              {simple_color ? (
                <ColorPicker
                  saturationHeight={100}
                  saturationWidth={100}
                  value={deviceOtherInformationInputs.color}
                  onDrag={onDrag}
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Vehicle Brand:
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="vehicleBrand"
              type="text"
              className="form-control"
              onChange={e =>
                updateDeviceOtherInformationInputs(
                  "vehicleBrand",
                  e.target.value
                )
              }
              value={deviceOtherInformationInputs.vehicleBrand}
            />
          </div>
        </div>
      </Row>

      <Row className="mb-1 mt-3">
        <div className="col-md-12 d-flex justify-content-end">
          <LoadingButton
            color="primary"
            className="btn btn-primary btn-right"
            loading={isUpdateDeviceLoading}
            disabled={isUpdateDeviceLoading}
            block={false}
            onClick={() => {
              props
                .putDeviceByDeviceIdAction(generatePayloadForUpdate())
                .then(() => {
                  tog_model()
                })
            }}
          >
            Submit
          </LoadingButton>
        </div>
      </Row>
    </React.Fragment>
  )
}

DeviceOtherInformation.propTypes = {
  deviceInfoInputs: PropTypes.obj,
  selectedDevice: PropTypes.obj,
  deviceOtherInformationInputs: PropTypes.obj,
  updateDeviceOtherInformationInputs: PropTypes.func,
  putDeviceByDeviceIdAction: PropTypes.func,
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  putDeviceByDeviceIdAction: bindActionToPromise(dispatch, putDeviceByDeviceId),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceOtherInformation)
