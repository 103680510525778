import {
  GET_MOVINGOVERVIEW_DETAILS,
  GET_MOVINGOVERVIEW_DETAILS_SUCCESS,
  GET_MOVINGOVERVIEW_DETAILS_ERROR,
  GET_MILEAGEREPORT,
  GET_MILEAGEREPORT_SUCCESS,
  GET_MILEAGEREPORT_ERROR,
  GET_SPEEDING_DETAILS,
  GET_SPEEDING_DETAILS_SUCCESS,
  GET_SPEEDING_DETAILS_ERROR,
  GET_PARKING_DETAILS,
  GET_PARKING_DETAILS_SUCCESS,
  GET_PARKING_DETAILS_ERROR,
  GET_TRIP_REPORT,
  GET_TRIP_REPORT_SUCCESS,
  GET_TRIP_REPORT_ERROR,
  GET_SPEEDING_TRIP_REPORT,
  GET_SPEEDING_TRIP_REPORT_SUCCESS,
  GET_SPEEDING_TRIP_REPORT_ERROR,
  GET_ENGINE_OVERVIEW,
  GET_ENGINE_OVERVIEW_SUCCESS,
  GET_ENGINE_OVERVIEW_ERROR,
  GET_ENGINE_REPORT,
  GET_ENGINE_REPORT_SUCCESS,
  GET_ENGINE_REPORT_ERROR,
  GET_ENGINE_IDLE_REPORT,
  GET_ENGINE_IDLE_REPORT_SUCCESS,
  GET_ENGINE_IDLE_REPORT_ERROR,
  GET_ALERT_OVERVIEW,
  GET_ALERT_OVERVIEW_SUCCESS,
  GET_ALERT_OVERVIEW_ERROR,
  GET_ALERT_REPORT,
  GET_ALERT_REPORT_SUCCESS,
  GET_ALERT_REPORT_ERROR,
  GET_ALERT_DETAILS,
  GET_ALERT_DETAILS_SUCCESS,
  GET_ALERT_DETAILS_ERROR,
  GET_GEO_FENCE_ALERT_REPORT,
  GET_GEO_FENCE_ALERT_REPORT_SUCCESS,
  GET_GEO_FENCE_ALERT_REPORT_ERROR,
} from "./actionTypes"

export const getMovingOverviewDetails = payload => {
  return {
    type: GET_MOVINGOVERVIEW_DETAILS,
    payload: payload,
  }
}

export const getMovingOverviewDetailsSuccess = payload => {
  return {
    type: GET_MOVINGOVERVIEW_DETAILS_SUCCESS,
    payload,
  }
}

export const getMovingOverviewDetailsError = error => {
  return {
    type: GET_MOVINGOVERVIEW_DETAILS_ERROR,
    payload: error,
  }
}

export const getMileageReport = payload => {
  return {
    type: GET_MILEAGEREPORT,
    payload: payload,
  }
}

export const getMileageReportSuccess = payload => {
  return {
    type: GET_MILEAGEREPORT_SUCCESS,
    payload,
  }
}

export const getMileageReportError = error => {
  return {
    type: GET_MILEAGEREPORT_ERROR,
    payload: error,
  }
}

export const getSpeedingDetails = payload => {
  return {
    type: GET_SPEEDING_DETAILS,
    payload: payload,
  }
}

export const getSpeedingDetailsSuccess = payload => {
  return {
    type: GET_SPEEDING_DETAILS_SUCCESS,
    payload,
  }
}

export const getSpeedingDetailsError = error => {
  return {
    type: GET_SPEEDING_DETAILS_ERROR,
    payload: error,
  }
}

export const getParkingDetails = payload => {
  return {
    type: GET_PARKING_DETAILS,
    payload: payload,
  }
}

export const getParkingDetailsSuccess = payload => {
  return {
    type: GET_PARKING_DETAILS_SUCCESS,
    payload,
  }
}

export const getParkingDetailsError = error => {
  return {
    type: GET_PARKING_DETAILS_ERROR,
    payload: error,
  }
}

export const getTripReport = payload => {
  return {
    type: GET_TRIP_REPORT,
    payload: payload,
  }
}

export const getTripReportSuccess = payload => {
  return {
    type: GET_TRIP_REPORT_SUCCESS,
    payload,
  }
}

export const getTripReportError = error => {
  return {
    type: GET_TRIP_REPORT_ERROR,
    payload: error,
  }
}

export const getSpeedingTripReport = payload => {
  return {
    type: GET_SPEEDING_TRIP_REPORT,
    payload: payload,
  }
}

export const getSpeedingTripReportSuccess = payload => {
  return {
    type: GET_SPEEDING_TRIP_REPORT_SUCCESS,
    payload,
  }
}

export const getSpeedingTripReportError = error => {
  return {
    type: GET_SPEEDING_TRIP_REPORT_ERROR,
    payload: error,
  }
}

export const getEngineOverview = payload => {
  return {
    type: GET_ENGINE_OVERVIEW,
    payload: payload,
  }
}

export const getEngineOverviewSuccess = payload => {
  return {
    type: GET_ENGINE_OVERVIEW_SUCCESS,
    payload,
  }
}

export const getEngineOverviewError = error => {
  return {
    type: GET_ENGINE_OVERVIEW_ERROR,
    payload: error,
  }
}

export const getEngineReport = payload => {
  return {
    type: GET_ENGINE_REPORT,
    payload: payload,
  }
}

export const getEngineReportSuccess = payload => {
  return {
    type: GET_ENGINE_REPORT_SUCCESS,
    payload,
  }
}

export const getEngineReportError = error => {
  return {
    type: GET_ENGINE_REPORT_ERROR,
    payload: error,
  }
}

export const getEngineIdleReport = payload => {
  return {
    type: GET_ENGINE_IDLE_REPORT,
    payload: payload,
  }
}

export const getEngineIdleReportSuccess = payload => {
  return {
    type: GET_ENGINE_IDLE_REPORT_SUCCESS,
    payload,
  }
}

export const getEngineIdleReportError = error => {
  return {
    type: GET_ENGINE_IDLE_REPORT_ERROR,
    payload: error,
  }
}

export const getAlertOverview = payload => {
  return {
    type: GET_ALERT_OVERVIEW,
    payload: payload,
  }
}

export const getAlertOverviewSuccess = payload => {
  return {
    type: GET_ALERT_OVERVIEW_SUCCESS,
    payload,
  }
}

export const getAlertOverviewError = error => {
  return {
    type: GET_ALERT_OVERVIEW_ERROR,
    payload: error,
  }
}

export const getAlertReport = payload => {
  return {
    type: GET_ALERT_REPORT,
    payload: payload,
  }
}

export const getAlertReportSuccess = payload => {
  return {
    type: GET_ALERT_REPORT_SUCCESS,
    payload,
  }
}

export const getAlertReportError = error => {
  return {
    type: GET_ALERT_REPORT_ERROR,
    payload: error,
  }
}

export const getAlertDetails = payload => {
  return {
    type: GET_ALERT_DETAILS,
    payload: payload,
  }
}

export const getAlertDetailsSuccess = payload => {
  return {
    type: GET_ALERT_DETAILS_SUCCESS,
    payload,
  }
}

export const getAlertDetailsError = error => {
  return {
    type: GET_ALERT_DETAILS_ERROR,
    payload: error,
  }
}

export const getGeoFenceAlertReport = payload => {
  return {
    type: GET_GEO_FENCE_ALERT_REPORT,
    payload: payload,
  }
}

export const getGeoFenceAlertReportSuccess = payload => {
  return {
    type: GET_GEO_FENCE_ALERT_REPORT_SUCCESS,
    payload,
  }
}

export const getGeoFenceAlertReportError = error => {
  return {
    type: GET_GEO_FENCE_ALERT_REPORT_ERROR,
    payload: error,
  }
}
