import moment from "moment"
import { dateDifferent } from "./dates"
export const renderSwitch = item => {
  let duration
  let status
  let classNameStatus, icnStatus

  switch (item.dataset) {
    case 1:
      classNameStatus = "grey"
      duration = null
      status = "Never Online"
      icnStatus = "grey"
      break
    case 2:
      classNameStatus = "status-static-idle"
      duration = dateDifferent(item.lastCoordinateUpdated, moment.utc())
      status = "Engine Idle"
      icnStatus = "orange"
      break
    case 3:
      classNameStatus = "status-static"
      duration = dateDifferent(item.lastCoordinateUpdated, moment.utc())
      status = "Static"
      icnStatus = "blue"
      break
    case 4:
      classNameStatus = "status-speeding"
      duration = null
      status = "Over Speed"
      icnStatus = "red"
      break
    case 5:
      classNameStatus = "status-moving"
      duration = null
      status = "Moving"
      icnStatus = "green"
      break
    case 6:
      classNameStatus = "status-offline"
      duration = dateDifferent(item.lastCoordinateUpdated, moment.utc())
      status = "Expired"
      icnStatus = "grey"
      break
    case 7:
      classNameStatus = "status-offline"
      duration = dateDifferent(item.updatedAt, moment.utc())
      status = "Offline"
      icnStatus = "grey"
      break
    case 8:
      classNameStatus = "status-offline"
      duration = null
      status = "Blocked"
      icnStatus = "grey"
      break
    default:
      classNameStatus = "status-static"
      status = "Never Online"
      icnStatus = "blue"
      break
  }
  return { classNameStatus, duration, status, icnStatus }
}
