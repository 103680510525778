import React from "react"
import { Link } from "react-router-dom"

const ImportFromExcelFile = () => {
  return (
    <div>
      <div className="p-3">
        <p>
          You can import POI by uploading Excel file with related information.
          Please follow the format of example to prepare the file. Following
          fields are available for import:
        </p>
        <div className="box_cus_1">
          <ul className="list_disc">
            <li>
              <b>Name</b> - Required,Name can not be more than 32 characters.
            </li>
            <li>
              <b>Icon</b> - Required, Value
              <span>
                1- <i className="bx bxs-map pe-2 fs-3"></i>
              </span>
              <span>
                2- <i className="bx bxs-map pe-2 fs-3"></i>
              </span>
            </li>

            <li>
              <b>Latitude</b> - Required
            </li>
            <li>
              <b>Longitude</b> - Required
            </li>
            <li>
              <b>Group </b>- Optional,Group can not be more than 32 characters.
            </li>
            <li>
              <b>Remark</b> - Optional,Remark can not be more than 80 characters
            </li>
          </ul>
        </div>

        <div className="pt-3 pb-3">
          MS Excel file for import (XLS, XLSX, CSV; maximum size 2 Mb):
        </div>
        <div>
          <button className="file_upload_btn btn btn-primary btn-sm me-2">
            <input type="file" />
            Upload
          </button>
          <Link href="#" target="_blank" class="link_txt ps-2">
            File Example
          </Link>
        </div>

        <div className="pt-3">
          <button className="btn btn-primary btn-sm me-2">Save</button>
          <button className="btn btn-outline-primary btn-sm">Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default ImportFromExcelFile
