/* eslint-disable */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { getTreeMunu } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"

import { connect } from "react-redux"

import { Link } from "react-router-dom"
import AddUsersMain from "../Shared/AddUsersMain"

import { bindActionToPromise } from "../../utils/Common"

import LoadingButton from "../../components/Common/Button"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Tooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Label,
  Button,
  Form,
  Input,
  NavLink,
  InputGroup,
  Spinner,
} from "reactstrap"

import classnames from "classnames"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import ChangeAndRestPass from "./ChangeAndResetPass"

import Information from "../../components/Info/Information"

import { getUserDetail, deleteUser, getSubUsers } from "../../store/actions"

const SubUser = props => {
  const { selectedUser } = props
  const [selectedSubUser, setSelectedUser] = useState({})
  const [deleteUserId, setDeleteUserId] = useState("")

  const [dataList, setDataList] = useState([])
  const [count, setCount] = useState(0)

  const [paginationOptions, setPaginationOptions] = useState({
    sizePerPage: 5,
    totalSize: 5,
    custom: true,
    page: 1,
  })

  const dispatch = useDispatch()

  const { userDetail, loading, deleteUserLoading, subUser, isLoadingSubUser } =
    useSelector(state => {
      return {
        userDetail: state.AddUser.userDetail,
        loading: state.AddUser.lodaingForDetail,
        deleteUserLoading: state.TreeMenu.isLoadingDelete,
        subUser: state.TreeMenu.subUser,
        isLoadingSubUser: state.TreeMenu.isLoading,
      }
    })

  useEffect(() => {
    if (subUser) {
      setDataList(subUser.rows)
      setCount(subUser.count)
    }
  }, [subUser])

  useEffect(() => {
    if (selectedSubUser?.id) {
      dispatch(getUserDetail(selectedSubUser?.id))
    }
  }, [selectedSubUser])

  const getData = () => {
    dispatch(
      getSubUsers({
        parentId: selectedUser?.id,
        data: {
          offset:
            paginationOptions?.sizePerPage * (paginationOptions?.page - 1),
          limit: paginationOptions?.sizePerPage,
        },
      })
    )
  }

  useEffect(() => {
    getData()
  }, [paginationOptions, selectedUser])

  const [modal_center, setmodal_center] = useState(false)
  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const [modal_center2, setmodal_center2] = useState(false)
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }

  const [modal_center3, setmodal_center3] = useState(false)
  function tog_center3() {
    setmodal_center3(!modal_center3)
    removeBodyCss()
  }

  const [modal_center4, setmodal_center4] = useState(false)
  function tog_center4(user = {}) {
    setSelectedUser(user)
    setmodal_center4(!modal_center4)
    removeBodyCss()
  }

  const [modal_center5, setmodal_center5] = useState(false)
  function tog_center5(user = {}) {
    setDeleteUserId(user?.id)
    setmodal_center5(!modal_center5)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const onSizePerPageChange = sizePerPage => {
    setPaginationOptions({
      ...paginationOptions,
      sizePerPage: sizePerPage,
      page: 1,
    })
  }

  const onPageChange = (page, sizePerPage) => {
    setPaginationOptions({
      ...paginationOptions,
      page: page,
    })
  }

  const columns = [
    {
      dataField: "name",
      text: "Account",
      sort: true,
      style: { width: "10%", whiteSpace: "normal", wordBreak: "break-all" },
    },
    {
      dataField: "role",
      text: "role",
      sort: true,
      style: { width: "10%", whiteSpace: "normal", wordBreak: "break-all" },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      style: { width: "25%", whiteSpace: "normal", wordBreak: "break-all" },
    },
    {
      dataField: "contactNumber",
      text: "Contact Number",
      sort: true,
      style: { width: "15%", whiteSpace: "normal", wordBreak: "break-all" },
    },
    {
      dataField: "telephone",
      text: "telephone",
      sort: true,
      style: { width: "15%", whiteSpace: "normal", wordBreak: "break-all" },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      style: { width: "25%" },
      formatter: (cellContent, user) => (
        <>
          <div className="d-flex">
            <Label
              className="me-2 text-secondary cursor-pointer"
              onClick={() => tog_center4(user)}
            >
              <u>Detail</u>
            </Label>
            <Label
              onClick={() => tog_center3()}
              className="me-2 text-secondary cursor-pointer"
            >
              <u>Reset Password</u>
            </Label>
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="card-drop text-success"
                tag="span"
              >
                More <i className=" fas fa-sort-down"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem>Move</DropdownItem>
                <DropdownItem>Monitor</DropdownItem>
                <DropdownItem onClick={() => tog_center2()}>
                  Modify password
                </DropdownItem>
                <DropdownItem onClick={() => tog_center5(user)}>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: paginationOptions?.sizePerPage || 5,
    totalSize: count,
    custom: true,
    page: paginationOptions?.page || 1,
  }

  const selectRow = {
    mode: "checkbox",
  }

  if (isLoadingSubUser) {
    return (
      <Spinner
        className={classnames({
          "position-relative": true,
          "button-style": false,
          visible: true,
          "left-50": true,
        })}
        size="lg"
        // type="grow"
      />
    )
  }

  return (
    <>
      <Row>
        <Col md="4" className="d-inline-flex align-items-center btnsmall">
          <button
            className="cus_btn_1"
            onClick={() => {
              tog_center()
            }}
          >
            <i className="bx bxs-plus-circle plus_icon_1"></i> New
          </button>
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
          {dataList && (
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="id"
              columns={columns}
              data={dataList}
              remote
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={dataList}
                  remote
                  // search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-3 justify-content-between">
                        <Col md="4" className="text-end">
                          {/* <div className="search-box me-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar
                              {...toolkitProps.searchProps}
                              className="mb-0"
                            />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div> */}
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="12">
                          <div className="">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                              sizePerPageList={[5, 10, 15, 20]}
                              onSizePerPageChange={onSizePerPageChange}
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone
                              {...paginationProps}
                              onPageChange={onPageChange}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          )}
        </Col>
      </Row>
      <Modal
        size="lg"
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add user</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <AddUsersMain selectedUser={selectedUser} />
        </div>
      </Modal>

      <Modal
        size="md"
        isOpen={modal_center2}
        toggle={() => {
          tog_center2()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Modify Password</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center2(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <ChangeAndRestPass type="change" />
        </div>
      </Modal>
      <Modal
        size="md"
        isOpen={modal_center3}
        toggle={() => {
          tog_center3()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Reset Password</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center3(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <ChangeAndRestPass type="reset" tog_center3={tog_center3} />
        </div>
      </Modal>
      <Modal
        size="lg"
        isOpen={modal_center4}
        toggle={() => {
          tog_center4()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Detail</h5>
          <button
            type="button"
            onClick={() => {
              tog_center4()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <Information
            userDetail={userDetail ? userDetail : {}}
            isLoading={loading}
          />
        </div>
      </Modal>
      <Modal
        size="md"
        isOpen={modal_center5}
        toggle={() => {
          tog_center5()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Delete User</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center5(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <Row className="mb-1 pl-4">Delete the account?</Row>
          <Row className="">
            <div className="d-flex justify-content-end">
              <LoadingButton
                color="secondary"
                className="btn btn-primary submitbtn btn-left"
                loading={false}
                disabled={false}
                block={false}
                onClick={() => tog_center5()}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                color="primary"
                className="btn btn-primary submitbtn "
                loading={deleteUserLoading}
                disabled={deleteUserLoading}
                block={false}
                onClick={() => {
                  props
                    .deleteUserAction({
                      memberId: deleteUserId,
                      parentId: selectedUser?.id,
                    })
                    .then(() => {
                      tog_center5()
                    })
                }}
              >
                Ok
              </LoadingButton>
            </div>
          </Row>
        </div>
      </Modal>
    </>
  )
}

SubUser.propTypes = {
  subUserList: PropTypes.Array,
  selectedUser: PropTypes.object,
  profileData: PropTypes.object,
  deleteUserAction: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    profileData: state.Login.profileData,
  }
}

const mapDispatchToProps = dispatch => ({
  deleteUserAction: bindActionToPromise(dispatch, deleteUser),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubUser)
