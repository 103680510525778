/* eslint-disable */

import React, { useState } from "react"
import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import { Container, Modal } from "reactstrap"
import { NavLink } from "react-router-dom"
import menuicon1 from "../../assets/images/icons/monitor-globe.svg"
import menuicon2 from "../../assets/images/icons/report.svg"
import menuicon3 from "../../assets/images/icons/device.svg"
import menuicon4 from "../../assets/images/icons/setting.svg"
import menuicon5 from "../../assets/images/icons/mail.svg"
import menuicon6 from "../../assets/images/icons/support.svg"
import menuicon7 from "../../assets/images/icons/logout.svg"

import { logoutUser } from "../../store/actions"

import { connect } from "react-redux"

import Profile from "../Profile/Profile"

import { useDispatch } from "react-redux"

import { getLoggedInUser } from "../../helpers/fakebackend_helper"

import ChangePassword from "../ChangePassword/ChangePassword"

import { useHistory, Link } from "react-router-dom"

import MessageTabsInfo from "../Message/MessageTabsInfo"

//Import Breadcrumb
import Breadcrumbs from "../Common/Breadcrumb"
import "./Monitor.css"

const NavigationMenu = props => {
  const userDetail = getLoggedInUser()
  const history = useHistory()

  const dispatch = useDispatch()

  const [modal_center, setmodal_center] = useState(false)
  const [modal_center2, setmodal_center2] = useState(false)
  const [TopBarMessage, setTopBarMessage] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }

  function TopBarMessageBtn() {
    setTopBarMessage(!TopBarMessage)
    removeBodyCss()
  }

  return (
    <React.Fragment>
      <div className="monitermenu">
        <div className="navmonitor">
          <ul>
            <li className="has-sub">
              {" "}
              <a>
                <img
                  src={
                    userDetail?.member?.profileImage ||
                    "https://images.protrack365.com/portrait/default_s.jpg"
                  }
                  width={50}
                  height={50}
                />
                <span>{userDetail?.member?.name}</span>
              </a>
              <ul>
                <li className="has-sub">
                
                  <NavLink
                    to="#"
                    onClick={() => {
                      tog_center2()
                    }}
                  >
                    <span>Account</span>
                  </NavLink>
                </li>
                <li className="has-sub">
                 
                  <NavLink
                    to="#"
                    onClick={() => {
                      tog_center()
                    }}
                  >
                    <span>Modify Password</span>
                  </NavLink>
                </li>
                <li className="has-sub">
                  {" "}
                  <NavLink to="#">
                    <span>Login Log</span>
                  </NavLink>
                </li>
                <li className="has-sub">
                  {" "}
                  <NavLink to="#">
                    <span>User Manual</span>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="has-sub">
              <NavLink to="/monitor">
                <img
                  className="logo-icon"
                  src={menuicon1}
                  width={25}
                  alt="varjo"
                />
                <span>Monitor</span>
              </NavLink>
            </li>

            <li className="has-sub">
            
              <a to="#">
                <img src={menuicon2} width={25} /> <span>Report</span>
              </a>
              <ul>
                <li>
                  <h4>Reports Overview</h4>
                </li>
                <li>
                  <hr />
                </li>
                <li className="has-sub">
                  {" "}
                  <NavLink to="#">
                    <span>Operation Statistics</span>
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink to="/report/movingoverview">
                        Moving overview
                      </NavLink>
                    </li>
                    <li>
                    <NavLink to="/report/mileagereport">
                        Mileage report
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/report/speedingdetails">
                        Speeding details
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/report/parkingdetails">
                        Parking details
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="has-sub">
                  <NavLink to="#">
                    <span>Trip report</span>
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink to="/report/tripreport">
                        <span>Trip report</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/report/speedtripreport">
                        <span>Speeding trip report</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

               
                <li className="has-sub">
                
                  <NavLink to="#">
                    <span>Engine report</span>
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink to="/report/engineoverview">
                        <span>Engine overview</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/report/enginereport">
                        <span>Engine report</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/report/engineidlereport">
                        <span>Engine idle report</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

               

               
                <li className="has-sub">
                  <NavLink to="#">
                    <span>Alert report</span>
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink to="/report/alertoverview">
                        <span>Alert overview</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/report/alertreport">
                        <span>Alert report</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/report/alertdetails">
                        <span>Alert details</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/report/geofencealert">
                        <span>Geo-fence alert report</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <hr />
                </li>
                <li>
                  <h4>Schedule for report</h4>
                </li>
                <li className="has-sub">
                  <NavLink to="/apps/monitor/report/schedulereport">
                    <span>Report tasks | New</span>
                  </NavLink>
                </li>
                <li className="has-sub">
                  <NavLink to="#">
                    <span>Generated reports</span>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="has-sub">
              <a to="#">
                <img src={menuicon3} width={25} /> <span>Device</span>
              </a>
              <ul>
                <li>
                  <NavLink to="#">
                    <span>Device setting</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    <span>Alert details</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    <span>Schedule for command</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    <span>Remind management</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    <span>Maintenance management</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    <span>Share location management</span>
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="navmonitor navmonitor2">
          <ul>
            <li className="has-sub">
              <a to="#">
                <img src={menuicon4} width={25} /> <span>Settings</span>
              </a>
              <ul>
                <li className="has-sub">
                  <NavLink to="#">
                    <span>Preferences</span>
                  </NavLink>
                </li>
                <li className="has-sub">
                
                  <NavLink to="#">
                    <span>Notification</span>
                  </NavLink>
                </li>
                <li className="has-sub">
                  
                  <NavLink to="#">
                    <span>Google Maps API Key</span>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="has-sub">
              <a onClick={() => TopBarMessageBtn()}>
                <img src={menuicon5} width={25} /> <span>Messages</span>
              </a>
            </li>
            <li className="has-sub">
              <a to="#">
                <img src={menuicon6} width={25} /> <span>Questions</span>
              </a>
              <ul>
                <li>
                  <NavLink to="#">
                    <span>Supplier</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    <span>Feedback</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#">
                    <span>Help</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="has-sub">
              <a to="#" onClick={() => dispatch(logoutUser(history))}>
                <img src={menuicon7} width={25} /> <span>Logout</span>
              </a>
            </li>
          </ul>
        </div>

        <Modal
          isOpen={modal_center}
          toggle={() => {
            tog_center()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Change Password</h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="mdi mdi-close"></i>
            </button>
          </div>
          <div className="modal-body">
            <ChangePassword history={history} />
          </div>
        </Modal>
        <Modal
          size="lg"
          isOpen={modal_center2}
          toggle={() => {
            tog_center2()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">My Profile</h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center2(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="mdi mdi-close"></i>
            </button>
          </div>
          <div className="modal-body">
            <Profile />
          </div>
        </Modal>
        <Modal
          isOpen={TopBarMessage}
          size="lg"
          toggle={() => {
            TopBarMessageBtn()
          }}
          centered
        >
          <div className="modal-header mb-3">
            <h5 className="modal-title mt-0">Message</h5>
            <button
              type="button"
              onClick={() => {
                setTopBarMessage(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="mdi mdi-close"></i>
            </button>
          </div>
          <MessageTabsInfo />
        </Modal>
      </div>
    </React.Fragment>
  )
}

NavigationMenu.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return { ...state, profileData: state.Login.profileData }
}

export default connect(mapStateToProps, {})(NavigationMenu)
