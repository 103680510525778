import {
  ADD_DEVICE_GROUP,
  ADD_DEVICE_GROUP_SUCCESS,
  ADD_DEVICE_GROUP_ERROR,
  GET_DEVICE_GROUP,
  GET_DEVICE_GROUP_SUCCESS,
  GET_DEVICE_GROUP_ERROR,
  PUT_DEVICE_GROUP,
  PUT_DEVICE_GROUP_SUCCESS,
  PUT_DEVICE_GROUP_ERROR,
} from "./actionTypes"

export const addDeviceGroup = payload => {
  return {
    type: ADD_DEVICE_GROUP,
    payload: payload,
  }
}

export const addDeviceGroupSuccess = () => {
  return {
    type: ADD_DEVICE_GROUP_SUCCESS,
  }
}

export const addDeviceGroupError = () => {
  return {
    type: ADD_DEVICE_GROUP_ERROR,
  }
}

export const getDeviceGroup = payload => ({
  type: GET_DEVICE_GROUP,
  payload,
})

export const getDeviceGroupSuccess = messages => ({
  type: GET_DEVICE_GROUP_SUCCESS,
  payload: messages,
})

export const getDeviceGroupFail = error => ({
  type: GET_DEVICE_GROUP_ERROR,
  payload: error,
})

export const putDeviceGroup = payload => ({
  type: PUT_DEVICE_GROUP,
  payload,
})

export const putDeviceGroupSuccess = messages => ({
  type: PUT_DEVICE_GROUP_SUCCESS,
  payload: messages,
})

export const putDeviceGroupFail = error => ({
  type: PUT_DEVICE_GROUP_ERROR,
  payload: error,
})
