import React, { useState } from "react"
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { Link } from "react-router-dom"
import classnames from "classnames"
import Sale from "../../components/Business/Sale"
import BulkSale from "../../components/Business/BulkSale"
import MoveDevice from "../../components/Business/MoveDevice"
import Recharge from "../../components/Business/Recharge"
import ModifyExpiryDate from "../../components/Business/ModifyExpiryDate"

const SocialSource = () => {
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <Card className="h-100 mb-0">
        <CardBody className="p-0 pt-0">
          <Nav tabs className="nav-tabs-custom navhometabs">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Sell</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-user"></i>
                </span>
                <span className="d-none d-sm-block">Bulk Sale</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "3",
                })}
                onClick={() => {
                  toggleCustom("3")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-envelope"></i>
                </span>
                <span className="d-none d-sm-block">Move</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "4",
                })}
                onClick={() => {
                  toggleCustom("4")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-cog"></i>
                </span>
                <span className="d-none d-sm-block">Recharge</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "5",
                })}
                onClick={() => {
                  toggleCustom("5")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-cog"></i>
                </span>
                <span className="d-none d-sm-block">Modify expiry date</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent
            activeTab={customActiveTab}
            className="p-3 pb-0 text-muted"
          >
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CardText className="mb-0 tabshomefoo">
                    <Sale userInfo={{}}  />
                  </CardText>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <CardText className="mb-0">
                    <BulkSale />
                  </CardText>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <CardText className="mb-0">
                    <MoveDevice />
                  </CardText>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col sm="12">
                  <CardText className="mb-0">
                    <Recharge />
                  </CardText>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="5">
              <Row>
                <Col sm="12">
                  <CardText className="mb-0">
                    <ModifyExpiryDate />
                  </CardText>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default SocialSource
