/* eslint-disable */

import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  Modal,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import Tooltip from "@mui/material/Tooltip"
import lefticon8 from "../../assets/images/icons/route.svg"

//i18n
import { withTranslation } from "react-i18next"
import ImportExcelPopsApikey from "./ImportFromExcelFile"
const MonitorBottomRoute = props => {
  const [Defaultgrp, setDefaultgrp] = useState(false)
  const [singlebtn, setSinglebtn] = useState(false)
  const [menu, setMenu] = useState(false)
  const [col6, setcol6] = useState(true)
  const t_col6 = () => {
    setcol6(!col6)
  }
  const [ImportExcelPop, setImportExcelPop] = useState(false)
  function ImportExcel() {
    setMenu(!menu)
    setImportExcelPop(!ImportExcelPop)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="div"
      >
        <DropdownToggle className="no-button ms-4" tag="button">
          <Tooltip title="Route" placement="top" arrow>
            <img src={lefticon8} width={30} />
          </Tooltip>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu mb_route_center p-0">
          <div className="d-flex align-items-center bot_pop_head">
            <i className="bx bxs-map pe-2 flx_0 font-size-17"></i>
            <span className="flx_1"> Route</span>
            <div className="flx_0 ps-2 pt-2">
              <span
                onClick={() => setMenu(!menu)}
                className="bx bx-message-alt-minus font-size-17 cursor-pointer"
              ></span>
            </div>
          </div>
          <SimpleBar style={{ height: "360px" }}>
            <ul className="deviceshown route_list_item p-2">
              <li>
                <div className="d-flex align-items-start w-100">
                  <div className="form-check flx_0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                  </div>
                  <div className="flx_1">
                    <span className="">Pandian 2040</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-start w-100">
                  <div className="form-check flx_0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                  </div>
                  <div className="flx_1">
                    <span className="">Pandian 2040</span>
                  </div>
                </div>
              </li>
            </ul>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}
export default withTranslation()(MonitorBottomRoute)
