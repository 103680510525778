/* eslint-disable */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import Profile from "../../Profile/Profile"

import ChangePassword from "../../ChangePassword/ChangePassword"

// users
import user1 from "../../../assets/images/alhadeed.png"

const ProfileMenu = props => {
  const { profileData } = props
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  // modal start
  const [modal_center, setmodal_center] = useState(false)
  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  const [modal_center2, setmodal_center2] = useState(false)
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  // modal end

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profileData?.member?.profileImage || user1}
            alt="login user"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {profileData?.member?.name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              tog_center()
            }}
          >
            <span>Password</span>
          </button>

          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              tog_center2()
            }}
          >
            <span>My Profile</span>
          </button>

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Change Password</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <ChangePassword history={props.history} />
        </div>
      </Modal>
      <Modal
        size="lg"
        isOpen={modal_center2}
        toggle={() => {
          tog_center2()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">My Profile</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center2(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <Profile />
        </div>
      </Modal>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

const mapStatetoProps = state => {
  return { ...state, profileData: state.Login.profileData }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
