/* eslint-disable */

import React, { useState, useEffect } from "react"

import { NavLink, Row, Input } from "reactstrap"

import PropTypes from "prop-types"
import moment from "moment"
import { connect } from "react-redux"
import { fileToBase64 } from "../../utils/Common"
import LoadingButton from "../../components/Common/Button"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { putDeviceByDeviceId } from "../../store/actions"
import { bindActionToPromise } from "../../utils/Common"
import { useSelector } from "react-redux"

import vehicleicons from "../../assets/images/icons/vehicle/icons.svg"
import vehicleicons2 from "../../assets/images/icons/vehicle/icons2.svg"
import vehicleicons3 from "../../assets/images/icons/vehicle/icons3.svg"
import vehicleicons4 from "../../assets/images/icons/vehicle/icons4.svg"
import vehicleicons5 from "../../assets/images/icons/vehicle/icons5.svg"
import vehicleicons6 from "../../assets/images/icons/vehicle/icons6.svg"
import vehicleicons7 from "../../assets/images/icons/vehicle/icons7.svg"
import vehicleicons8 from "../../assets/images/icons/vehicle/icons8.svg"
import vehicleicons9 from "../../assets/images/icons/vehicle/icons9.svg"
import vehicleicons10 from "../../assets/images/icons/vehicle/icons10.svg"
import vehicleicons11 from "../../assets/images/icons/vehicle/icons11.svg"
import vehicleicons12 from "../../assets/images/icons/vehicle/icons12.svg"
import vehicleicons13 from "../../assets/images/icons/vehicle/icons13.svg"
import vehicleicons14 from "../../assets/images/icons/vehicle/icons14.svg"
import vehicleicons15 from "../../assets/images/icons/vehicle/icons15.svg"
import vehicleicons16 from "../../assets/images/icons/vehicle/icons16.svg"
import vehicleicons17 from "../../assets/images/icons/vehicle/icons17.svg"
import vehicleicons18 from "../../assets/images/icons/vehicle/icons18.svg"

function DeviceInfo1(props) {
  const {
    deviceInfoInputs,
    updateDeviceInfoInputs,
    selectedDevice,
    selectedUser,
    deviceOtherInformationInputs,
    tog_model,
  } = props

  const [deviceImage, setDeviceImage] = useState(
    deviceInfoInputs?.vehiclePicture
  )
  const [file, setFileData] = useState()

  useEffect(() => {
    setDeviceImage(deviceInfoInputs?.vehiclePicture)
  }, [deviceInfoInputs?.vehiclePicture])

  const { isUpdateDeviceLoading } = useSelector(state => {
    return {
      isUpdateDeviceLoading: state.Device.isUpdateDeviceLoading,
    }
  })

  const onImageChange = e => {
    fileToBase64(e).then(res => {
      setDeviceImage(res.file)
      setFileData(e.target.files[0])
    })
  }

  function generatePayloadForUpdate() {
    return {
      id: selectedDevice?.id,
      data: {
        name: deviceInfoInputs?.name,
        imei: deviceInfoInputs?.imei,
        memberId: selectedUser?.id,
        model: deviceInfoInputs?.model,
        deviceType: deviceInfoInputs?.deviceType,
        remark: deviceInfoInputs?.remark,
        vehiclePlatNumber: deviceInfoInputs?.vehiclePlatNumber,
        simCard: deviceInfoInputs?.simCard,
        ...(deviceInfoInputs?.exFactoryDate
          ? { exFactoryDate: deviceInfoInputs?.exFactoryDate }
          : {}),
        activatedDate: deviceInfoInputs?.activatedDate,
        platformExpirationDate: deviceInfoInputs?.platformExpirationDate,
        ...(deviceInfoInputs?.userDue
          ? { userDue: deviceInfoInputs?.userDue }
          : {}),
        icon: deviceInfoInputs?.icon,
        vehiclePicture: deviceInfoInputs?.vehiclePicture,
        otherInfo: { ...deviceOtherInformationInputs },
      },
      isImageUpload: deviceImage !== deviceInfoInputs?.vehiclePicture,
      files:
        deviceImage !== deviceInfoInputs?.vehiclePicture
          ? [
              {
                file,
              },
            ]
          : [],
    }
  }

  return (
    <React.Fragment>
      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">Name</label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="name"
              type="text"
              className="form-control"
              onChange={e => updateDeviceInfoInputs("name", e.target.value)}
              value={deviceInfoInputs.name}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">IMEI</label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="imei"
              type="text"
              className="form-control"
              disabled
              value={deviceInfoInputs.imei}
            />
          </div>
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Vehicle plate number:
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="vehiclePlatNumber"
              type="text"
              className="form-control"
              onChange={e =>
                updateDeviceInfoInputs("vehiclePlatNumber", e.target.value)
              }
              value={deviceInfoInputs.vehiclePlatNumber}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            SIM card:
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="simCard"
              type="text"
              className="form-control"
              onChange={e => updateDeviceInfoInputs("simCard", e.target.value)}
              value={deviceInfoInputs.simCard}
            />
          </div>
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">Model:</label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="model"
              type="text"
              className="form-control"
              disabled
              value={deviceInfoInputs.model}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Last Update :
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="updatedAt"
              type="text"
              className="form-control"
              disabled
              value={moment(deviceInfoInputs?.updatedAt).format(
                "YYYY-MM-DD hh:mm:ss"
              )}
            />
          </div>
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Ex-factory date :
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <DatePicker
              name="exFactoryDate"
              className="form-control"
              selected={
                (deviceInfoInputs?.exFactoryDate &&
                  new Date(deviceInfoInputs.exFactoryDate)) ||
                null
              }
              disabled
              onChange={date => updateDeviceInfoInputs("exFactoryDate", date)}
              placeholderText=""
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Activated Date:
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <DatePicker
              name="activatedDate"
              className="form-control"
              selected={
                (deviceInfoInputs?.activatedDate &&
                  new Date(deviceInfoInputs.activatedDate)) ||
                null
              }
              // disabled
              onChange={date => updateDeviceInfoInputs("activatedDate", date)}
              placeholderText=""
            />
          </div>
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Platform expiration date:
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <DatePicker
              name="platformExpirationDate"
              className="form-control"
              selected={
                (deviceInfoInputs?.platformExpirationDate &&
                  new Date(deviceInfoInputs.platformExpirationDate)) ||
                null
              }
              // disabled
              onChange={date =>
                updateDeviceInfoInputs("platformExpirationDate", date)
              }
              placeholderText=""
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            User due :
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <DatePicker
              name="userDue"
              className="form-control"
              selected={
                (deviceInfoInputs?.userDue &&
                  new Date(deviceInfoInputs.userDue)) ||
                null
              }
              disabled
              onChange={date => updateDeviceInfoInputs("userDue", date)}
              placeholderText=""
            />
          </div>
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">Icon :</label>
          <div className="col-md-12 d-flex checuserimg flex-wrap">
            <NavLink
              to="/#"
              className={deviceInfoInputs.icon === 1 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 1)}
            >
              <img alt="" src={vehicleicons} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 2 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 2)}
            >
              <img alt="" src={vehicleicons2} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 3 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 3)}
            >
              <img alt="" src={vehicleicons3} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 4 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 4)}
            >
              <img alt="" src={vehicleicons4} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 5 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 5)}
            >
              <img alt="" src={vehicleicons5} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 6 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 6)}
            >
              <img alt="" src={vehicleicons6} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 7 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 7)}
            >
              <img alt="" src={vehicleicons7} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 8 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 8)}
            >
              <img alt="" src={vehicleicons8} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 9 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 9)}
            >
              <img alt="" src={vehicleicons9} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 10 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 10)}
            >
              <img alt="" src={vehicleicons10} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 11 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 11)}
            >
              <img alt="" src={vehicleicons11} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 12 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 12)}
            >
              <img alt="" src={vehicleicons12} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 13 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 13)}
            >
              <img alt="" src={vehicleicons13} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 14 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 14)}
            >
              <img alt="" src={vehicleicons14} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 15 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 15)}
            >
              <img alt="" src={vehicleicons15} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 16 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 16)}
            >
              <img alt="" src={vehicleicons16} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 17 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 17)}
            >
              <img alt="" src={vehicleicons17} />
            </NavLink>

            <NavLink
              to="/"
              className={deviceInfoInputs.icon === 18 ? "selected-icon" : ""}
              onClick={() => updateDeviceInfoInputs("icon", 18)}
            >
              <img alt="" src={vehicleicons18} />
            </NavLink>
          </div>
        </div>
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Vehicle Picture :
          </label>

          <div className="col-md-12 d-flex">
            <div className="profi_img_row position-relative me-1">
              <img className="profile_img" src={deviceImage} alt="" />
              <div className="upload_cus">
                <i className="bx bxs-pencil upload_edit d-flex align-items-center justify-content-center"></i>
                <input type="file" name="file" onChange={onImageChange} />
              </div>
            </div>
          </div>
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-12">
          <label className="col-md-12 col-form-label text-start">Remark</label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              type="textarea"
              placeholder="Enter Remark"
              value={deviceInfoInputs.remark}
              onChange={e => updateDeviceInfoInputs("remark", e.target.value)}
              maxLength={300}
              rows={5}
            />
          </div>
        </div>
      </Row>

      <Row className="mb-1 mt-3">
        <div className="col-md-12 d-flex justify-content-end">
          <LoadingButton
            color="primary"
            className="btn btn-primary btn-right"
            loading={isUpdateDeviceLoading}
            disabled={isUpdateDeviceLoading}
            block={false}
            onClick={() => {
              props
                .putDeviceByDeviceIdAction(generatePayloadForUpdate())
                .then(() => {
                  tog_model()
                })
            }}
          >
            Submit
          </LoadingButton>
        </div>
      </Row>
    </React.Fragment>
  )
}

DeviceInfo1.propTypes = {
  deviceInfoInputs: PropTypes.obj,
  selectedDevice: PropTypes.obj,
  deviceOtherInformationInputs: PropTypes.obj,
  updateDeviceInfoInputs: PropTypes.func,
  putDeviceByDeviceIdAction: PropTypes.func,
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  putDeviceByDeviceIdAction: bindActionToPromise(dispatch, putDeviceByDeviceId),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInfo1)
