import {
  GET_MONITOR_INITIAL,
  GET_MONITOR_DETAILS_FAIL,
  GET_MONITOR_DETAILS_SUCCESS,
} from "./actionTypes"

export const getMonitorDetails = selectedData => ({
  type: GET_MONITOR_INITIAL,
  selectedData,
})

export const getMonitorDetailsSuccess = deviceDetails => ({
  type: GET_MONITOR_DETAILS_FAIL,
  payload: deviceDetails,
})
export const getMonitorDetailsFail = error => ({
  type: GET_MONITOR_DETAILS_SUCCESS,
  payload: error,
})
