/* eslint-disable */
import React, { useState, useEffect } from "react"

import PropTypes from "prop-types"

import { Row, Input } from "reactstrap"

import { connect } from "react-redux"

import { fileToBase64 } from "../../utils/Common"

import LoadingButton from "../../components/Common/Button"

import { bindActionToPromise } from "../../utils/Common"

import { useSelector } from "react-redux"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { putDeviceInstallationInfo } from "../../store/actions"

function InstallationInformation(props) {
  const {
    deviceInstallationInfoInputs,
    updateDeviceInstallationInfoInputs,
    selectedDevice,
    tog_model,
  } = props

  const [deviceImage, setDeviceImage] = useState(
    deviceInstallationInfoInputs?.image
  )
  const [file, setFileData] = useState()

  const { isUpdateDeviceLoading } = useSelector(state => {
    return {
      isUpdateDeviceLoading: state.Device.isUpdateDeviceLoading,
    }
  })

  useEffect(() => {
    setDeviceImage(deviceInstallationInfoInputs?.image)
  }, [deviceInstallationInfoInputs?.image])

  const onImageChange = e => {
    fileToBase64(e).then(res => {
      setDeviceImage(res.file)
      setFileData(e.target.files[0])
    })
  }

  function generatePayloadForUpdate() {
    return {
      data: {
        deviceId: Number(selectedDevice?.id),
        person: deviceInstallationInfoInputs?.person || undefined,
        time: deviceInstallationInfoInputs?.time || undefined,
        company: deviceInstallationInfoInputs?.company || undefined,
        position: deviceInstallationInfoInputs?.position || undefined,
        address: deviceInstallationInfoInputs?.address || undefined,
        remark: deviceInstallationInfoInputs?.remark || undefined,
        image: deviceInstallationInfoInputs?.image || undefined,
      },
      isImageUpload: deviceImage !== deviceInstallationInfoInputs?.image,
      files:
        deviceImage !== deviceInstallationInfoInputs?.image
          ? [
              {
                file,
              },
            ]
          : [],
    }
  }

  return (
    <React.Fragment>
      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Installation person:
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="person"
              type="text"
              className="form-control"
              onChange={e =>
                updateDeviceInstallationInfoInputs("person", e.target.value)
              }
              value={deviceInstallationInfoInputs.person}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Installation time:
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <DatePicker
              name="time"
              className="form-control"
              selected={
                (deviceInstallationInfoInputs?.time &&
                  new Date(deviceInstallationInfoInputs.time)) ||
                null
              }
              onChange={date =>
                updateDeviceInstallationInfoInputs("time", date)
              }
              placeholderText=""
            />
          </div>
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Installation company:
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="company"
              type="text"
              className="form-control"
              onChange={e =>
                updateDeviceInstallationInfoInputs("company", e.target.value)
              }
              value={deviceInstallationInfoInputs.company}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Installation position:
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="position"
              type="text"
              className="form-control"
              onChange={e =>
                updateDeviceInstallationInfoInputs("position", e.target.value)
              }
              value={deviceInstallationInfoInputs.position}
            />
          </div>
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Installation address:
          </label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              name="address"
              type="text"
              className="form-control"
              onChange={e =>
                updateDeviceInstallationInfoInputs("address", e.target.value)
              }
              value={deviceInstallationInfoInputs.address}
            />
          </div>
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-6">
          <label className="col-md-12 col-form-label text-start">
            Vehicle Picture :
          </label>

          <div className="col-md-12 d-flex mb-2">
            <div className="profi_img_row position-relative me-1">
              <img className="profile_img" src={deviceImage} alt="" />
            </div>
          </div>
          <Input
            className="form-control"
            type="file"
            id="formFile"
            onChange={onImageChange}
          />
        </div>
      </Row>

      <Row className="mb-1">
        <div className="col-md-12">
          <label className="col-md-12 col-form-label text-start">Remark</label>
          <div className="col-md-12 d-flex align-items-center">
            <Input
              type="textarea"
              placeholder="Enter Remark"
              value={deviceInstallationInfoInputs.remark}
              onChange={e =>
                updateDeviceInstallationInfoInputs("remark", e.target.value)
              }
              maxLength={300}
              rows={5}
            />
          </div>
        </div>
      </Row>

      <Row className="mb-1 mt-3">
        <div className="col-md-12 d-flex justify-content-end">
          <LoadingButton
            color="primary"
            className="btn btn-primary btn-right"
            loading={isUpdateDeviceLoading}
            disabled={isUpdateDeviceLoading}
            block={false}
            onClick={() => {
              props
                .putDeviceInstallationInfoAction(generatePayloadForUpdate())
                .then(() => {
                  tog_model()
                })
            }}
          >
            Submit
          </LoadingButton>
        </div>
      </Row>
    </React.Fragment>
  )
}

InstallationInformation.propTypes = {
  deviceInstallationInfoInputs: PropTypes.obj,
  selectedDevice: PropTypes.obj,
  tog_model: PropTypes.func,
  updateDeviceInstallationInfoInputs: PropTypes.func,
  putDeviceInstallationInfoAction: PropTypes.func,
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  putDeviceInstallationInfoAction: bindActionToPromise(
    dispatch,
    putDeviceInstallationInfo
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallationInformation)
