/* eslint-disable */
import React from "react"
import {
  GET_PLAYBACK_HISTORY_INITIAL,
  GET_PLAYBACK_HISTORY_DETAILS_FAIL,
  GET_PLAYBACK_HISTORY_DETAILS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  playBackHistoryData:[],
  error: {},
  isLoading: false,
}

const playBackHistoryDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PLAYBACK_HISTORY_INITIAL:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PLAYBACK_HISTORY_DETAILS_SUCCESS:
      return {
        ...state,
        playBackHistoryData: action.payload,
        isLoading: false,
      }

    case GET_PLAYBACK_HISTORY_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    default:
      return state
  }
}

export default playBackHistoryDetails
