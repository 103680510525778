/* eslint-disable */
import { takeEvery, fork, put, all, call, delay } from "redux-saga/effects"

// Crypto Redux States
import {
  CHECK_IME_INITIAL,
  POST_SALE_INITIAL,
  POST_MOVE_ACCOUNT,
} from "./actionTypes"

import {
  checkImeNumberSuccess,
  checkImeNumberFail,
  addSalesSuccess,
  addSalesFail,
  moveAccountDefaultSuccess,moveAccountDefaultFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getIMEDetailsData,
  addSalesDetailsRequest,
  moveAccountToTarget,
} from "../../helpers/fakebackend_helper"
import { showToastPopup } from "../../utils/Common"
function* fetchIMEDetails({ payload }) {
  const {
    meta: { resolve, reject },
  } = payload

  try {
    const response = yield call(getIMEDetailsData, payload.payload)

    yield put(checkImeNumberSuccess(response))
    yield call(resolve, response)
  } catch (error) {
    yield put(checkImeNumberFail(error))
    yield call(reject, error)
   showToastPopup("Imie number is not found ", false)
    
  }
}
function* addSalesDetails({ payload }) {
     const {
       meta: { resolve, reject },
     } = payload

  try {
    const response = yield call(addSalesDetailsRequest, payload.payload)
      yield put(addSalesSuccess(response))
      yield call(resolve, response)
      showToastPopup("Sales Added successfully !", true)
  } catch (error) {
    yield put(addSalesFail(error))
    showToastPopup(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again1.",
      false
    )
    yield call(reject, error?.response?.data?.message)
  }
}

function* moveAccountDetails({ payload }) {
  const {
    meta: { resolve, reject },
  } = payload

  try {
    const response = yield call(moveAccountToTarget, payload.payload)
    yield put(moveAccountDefaultSuccess(response))
    yield call(resolve, response)
    showToastPopup("Account Moved successfully !", true)
  } catch (error) {
    yield put(moveAccountDefaultFail(error))
    showToastPopup(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again1.",
      false
    )
    yield call(reject, error?.response?.data?.message)
  }
}

function* sharedDataSaga() {
  yield takeEvery(CHECK_IME_INITIAL, fetchIMEDetails)
  yield takeEvery(POST_SALE_INITIAL, addSalesDetails)
  yield takeEvery(POST_MOVE_ACCOUNT, moveAccountDetails)
}

export default sharedDataSaga
