import React from "react"
import ColorPicker from "./ColorPicker"

const GeoFenceEditPop = () => {
  return (
    <div className="GeoFencEdit">
      <div className="GeoFencEdit_row p-2">
        <div className="row pb-3 gx-0">
          <div className="col-lg-9 offset-lg-1">
            <div className="d-flex align-items-center">
              <div className="flx_0">
                <i className="bx bx-crosshair fs-5"></i>
              </div>
              <div className="flx_1 ps-2">
                <input type="text" className="form-control" />
              </div>
              <div className="flx_0 ps-2">
                <i className="bx bxs-dashboard fs-5"></i>
              </div>
              <div className="flx_0 position-relative ps-2">
                <ColorPicker />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center pb-3">
          <div className="flx_0">
            <input
              type="checkbox"
              className="form-check-input"
              id="customCheckcolor1"
            />
          </div>
          <div className="flx_0 ps-2">
            <i className="bx bxs-tachometer fs-5"></i>
          </div>
          <div className="flx_1 ps-2">Speeding Alert</div>
        </div>
        <div className="d-flex align-items-center pb-3">
          <div className="flx_0">
            <input
              type="checkbox"
              className="form-check-input"
              id="customCheckcolor1"
            />
          </div>
          <div className="flx_0 ps-2">
            <i className="bx bx-time fs-5"></i>
          </div>
          <div className="flx_1 ps-2">Enable Alert Whole Day</div>
        </div>
        <div className="row pb-3">
          <div className="col-lg-9 offset-lg-1">
            <div className="ps-4">
              <button className="btn btn-primary btn-sm me-2">Save</button>
              <button className="btn btn-outline-primary btn-sm me-2">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeoFenceEditPop
