/* eslint-disable */

import React, { useEffect, useState } from "react"
import { InputGroup } from "reactstrap"
import moment from "moment"
import "./ReportStyle.css"
import { MultiSelect } from "react-multi-select-component"
import MileageReportGrid from "./MileageReportGrid"
import ReturnNormalFlatPicker from "./DatePickr/ReturnNormalFlatPicker"
import ReturnFlatPickerWithTime from "./DatePickr/ReturnFlatPickerWithTime"
import { getDevicesByMemberId, getMileageReport } from "store/actions"
import PropTypes from "prop-types"
import { useSelector, useDispatch, connect } from "react-redux"
import { bindActionToPromise } from "utils/Common"

const MileageOverviewDetails = props => {
  const { selectedUser } = props
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [isDaily, setIsDaily] = useState(true)

  const [selected, setSelected] = useState([])

  const [dataList, setDataList] = useState([])
  const [count, setCount] = useState(0)

  const [paginationOptions, setPaginationOptions] = useState({
    sizePerPage: 5,
    totalSize: 5,
    custom: true,
    page: 1,
  })

  const { mileageReport, loading } = useSelector(state => {
    return {
      mileageReport: state.Reports.mileageReport,
      loading: state.Reports.loading,
    }
  })

  useEffect(() => {
    if (mileageReport && mileageReport.data) {
      setDataList(mileageReport.data) //mileageReport.rows
      setCount(mileageReport.data.length) //mileageReport.count
    }
  }, [mileageReport])

  const getData = () => {
    props.getMileageReportAction({
      offset: paginationOptions?.sizePerPage * (paginationOptions?.page - 1),
      limit: paginationOptions?.sizePerPage,
    })
  }

  useEffect(() => {
    getData()
  }, [paginationOptions])

  const { deviceByMember } = useSelector(state => {
    return {
      deviceByMember: state.Device.deviceByMember,
    }
  })

  useEffect(() => {
    dispatch(
      getDevicesByMemberId({
        parentId: selectedUser?.id,
      })
    )
  }, [selectedUser])

  const options = deviceByMember?.rows
    ? deviceByMember?.rows.map(ele => ({
        label: ele.name || ele.model,
        value: ele.id,
      }))
    : []
  return (
    <div className="panel_main">
      <div className="head_main cursor-pointer">Mileage report </div>
      <div className="p-3">
        <div className="pd_1">
          <div className="d-flex flex-wrap align-items-start">
            <label className="pe-2 flx_0 pt-1">Device</label>
            <div className="w-50c me-2">
              <MultiSelect
                options={options}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
              />
            </div>

            <div className="d-inline-flex  align-items-center pe-4 pt-2 pb-1">
              <div className="form-check me-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="option1"
                  checked={isDaily}
                  onClick={() => setIsDaily(true)}
                />
                <label className="form-check-label " htmlFor="exampleRadios1">
                  Daily Details
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  value="option2"
                  checked={!isDaily}
                  onClick={() => setIsDaily(false)}
                />
                <label className="form-check-label" htmlFor="exampleRadios2">
                  Period Details
                </label>
              </div>
            </div>

            <div className="d-inline-flex flex-column pe-4 pb-1">
              <div>
                <div className="d-inline-flex  align-items-center pe-2 w-50c">
                  <label className="pe-2 flx_0 pt-1">Date</label>
                  <div className="flx_1">
                    <InputGroup>
                      {isDaily ? (
                        <ReturnNormalFlatPicker
                          dateValue={startDate}
                          updateDate={setStartDate}
                        />
                      ) : (
                        <ReturnFlatPickerWithTime
                          dateValue={startDate}
                          updateDate={setStartDate}
                        />
                      )}
                    </InputGroup>
                  </div>
                </div>
                <label className="pe-2 flx_0 pt-1">To</label>
                <div className="d-inline-flex align-items-center">
                  <div className="flx_1">
                    <InputGroup>
                      {isDaily ? (
                        <ReturnNormalFlatPicker
                          dateValue={endDate}
                          updateDate={setEndDate}
                        />
                      ) : (
                        <ReturnFlatPickerWithTime
                          dateValue={endDate}
                          updateDate={setEndDate}
                        />
                      )}
                    </InputGroup>
                  </div>
                  <div className="flx_1 ms-2 w-50c">
                    <button className="btn btn-sm btn-primary ">Check </button>
                    <button className="btn btn-sm btn-secondary ms-2">
                      Batch Download{" "}
                    </button>
                  </div>
                </div>
              </div>

              <div className="d-flex w-100 pt-1 ps-3 pb-3">
                <span className="ps-4 form-text">
                  Maximum time range is 30 days. Please schedule for more
                  reports.
                </span>
                <span className="txt_link form-text ps-2">Schedule now</span>
              </div>
            </div>

            <div className=""></div>
          </div>
        </div>
        <div className="pd_2 text-end pb-2 mb-4 datseparate">
          <div>
            <span
              className="txt_link"
              onClick={() => {
                setStartDate(moment().subtract(1, "days").toDate())
                setEndDate(moment().subtract(1, "days").toDate())
              }}
            >
              Yesterday
            </span>
            <span
              className="txt_link"
              onClick={() => {
                setStartDate(moment().startOf("week").toDate())
                setEndDate(moment().toDate())
              }}
            >
              This week
            </span>
            <span
              className="txt_link"
              onClick={() => {
                setStartDate(
                  moment().subtract(1, "weeks").startOf("week").toDate()
                )
                setEndDate(moment().subtract(1, "weeks").endOf("week").toDate())
              }}
            >
              Last week
            </span>
            <span
              className="txt_link"
              onClick={() => {
                setStartDate(moment().startOf("month").toDate())
                setEndDate(moment().toDate())
              }}
            >
              This month
            </span>
            <span
              className="txt_link border-end-0"
              onClick={() => {
                setStartDate(
                  moment().subtract(1, "month").startOf("month").toDate()
                )
                setEndDate(
                  moment().subtract(1, "month").endOf("month").toDate()
                )
              }}
            >
              Last month
            </span>
          </div>
        </div>

        <div className="report_park_grid">
          <MileageReportGrid
            paginationOptions={paginationOptions}
            setPaginationOptions={setPaginationOptions}
            dataList={dataList}
            count={count}
          />
        </div>
      </div>
    </div>
  )
}

MileageOverviewDetails.propTypes = {
  selectedUser: PropTypes.obj,
  getMileageReportAction: PropTypes.func,
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  getMileageReportAction: bindActionToPromise(dispatch, getMileageReport),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MileageOverviewDetails)
