/* eslint-disable */

import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import "./ReportStyle.css"
import { Link } from "react-router-dom"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

const ReportParkGrid = () => {
  const columns = [
    {
      dataField: "sno",
      text: "#",
      sort: false,
      classes: 'columsno'

    },
    {
      dataField: "tstate",
      text: "Trip State",
      sort: false,
      classes: 'columwidt'
    },
    {
      dataField: "dtime",
      text: "Datetime",
      sort: false,
      classes: 'columwidt',
      formatter: (cellContent, address) => (
        <>
<Link className="txt_colr_1">2022-05-04 12:05:51~2022-05-04 12:27:38</Link>
        </>
      ),
    },
    {
      dataField: "mileage",
      text: "Mileage(KM)",
      sort: false,
      classes: 'columwidt'
    },
    {
      dataField: "duration",
      text: "Duration",
      sort: false,
      classes: 'columwidt'
    },
    {
      dataField: "slocation",
      text: "Start location",
      sort: false,
      classes: 'columsloca',
      formatter: (cellContent, address) => (
        <>
<Link className="txt_colr_1">  12.989682,80.193344 <br />
5/13, Thillaiganga Nagar, Nanganallur, Chennai, Tamil Nadu 600061, India</Link>
        </>
      ),
    },
    {
      dataField: "elocation",
      text: "End location",
      sort: false,
      classes: 'columsloca',
      formatter: (cellContent, address) => (
        <>
<Link className="txt_colr_1">  12.989682,80.193344 <br />
5/13, Thillaiganga Nagar, Nanganallur, Chennai, Tamil Nadu 600061, India</Link>
        </>
      ),
    },


    
  ]

  // Table Data
  const productData = [
    {
      sno: "1",
      tstate: "Driving",
      mileage: "8.44",
      duration: "21min47s",
    },
    {
      sno: "2",
      tstate: "Driving",
      mileage: "8.44",
      duration: "21min47s",
    },
    {
      sno: "3",
      tstate: "Driving",
      mileage: "8.44",
      duration: "21min47s",
    },
    {
      sno: "4",
      tstate: "Driving",
      mileage: "8.44",
      duration: "21min47s",
    },
    {
      sno: "5",
      tstate: "Driving",
      mileage: "8.44",
      duration: "21min47s",
    },
    {
      sno: "6",
      tstate: "Driving",
      mileage: "8.44",
      duration: "21min47s",
    },
    {
      sno: "7",
      tstate: "Driving",
      mileage: "8.44",
      duration: "21min47s",
    },
    {
      sno: "8",
      tstate: "Driving",
      mileage: "8.44",
      duration: "21min47s",
    },
    {
      sno: "9",
      tstate: "Driving",
      mileage: "8.44",
      duration: "21min47s",
    },
    {
      sno: "10",
      tstate: "Driving",
      mileage: "8.44",
      duration: "21min47s",
    },
    {
      sno: "11",
      tstate: "Driving",
      mileage: "8.44",
      duration: "21min47s",
    },

    
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 5,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: productData.length },
  ]

  const { SearchBar } = Search

  return (
    <React.Fragment>
       
      <Row className="mb-5">
        <Col className="col-12">
          
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={columns}
            data={productData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={productData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive ">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle table-nowrap table-bordered"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                          
                        </div>



                        <div className="text-md-right ms-auto endpush">
                        <PaginationListStandalone {...paginationProps} />
                        
                        </div>



                      </Col>
                    </Row>


                    
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Col>
      </Row>

    
      
      <div className="posexcelsheet">
<span className="me-2"><i className="bx  bx bxs-bulb orangecol"></i>Google Address :</span>
      <div
                          className="form-check form-switch mb-0 d-inline-block me-3"

                        >


                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitch1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customSwitch1"
                          >
                          unavailable
                          </label>
                        </div>

            <button className="btn btn-success btn-sm me-3 pt-1 pb-1 cursor-pointer">
              Excel
            </button>
            <button className="btn btn-danger-2 text-white btn-sm me-3 pt-1 pb-1 cursor-pointer">
              PDF
            </button>
            <button className="btn btn-info btn-sm pt-1 pb-1 cursor-pointer">
              Print
            </button>
          </div>

    </React.Fragment>
  )
}

export default ReportParkGrid
