/* eslint-disable */
import React, { memo, useState, useEffect, useRef } from "react"
import Geocode from "react-geocode"
import _ from "lodash"
import moment from "moment"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { useParams, NavLink } from "react-router-dom"
//Import Breadcrumb
import { Marker, Popup, Tooltip, Polyline } from "react-leaflet"
import MarkerClusterGroup from "react-leaflet-cluster"
import L, { MarkerCluster } from "leaflet"
import ReactLeafletDriftMarker from "react-leaflet-drift-marker"
import center from "@turf/center"
import { points } from "@turf/helpers"
import LeafMap from "../../../components/Monitor/component/LeafMap"
Geocode.setApiKey("AIzaSyCVPM_AKcsmM6GvZmx5xpizXasMuho4c24")
Geocode.enableDebug()
import Counter from "../../../components/Monitor/counterTrack"
import { getTrackDetails } from "../../../store/actions"
import "../../../components/Monitor/Monitor.css"
import * as url from "../../../helpers/url_helper"
import { renderSwitch } from "../../../utils/vehicleStatus"
import iconComponents from "../../../utils/Icons"
import { dateDifferent } from "../../../utils/dates"
const API_URL = process.env.REACT_APP_API_URL
import locationJSON from "./location.json"
export const apiConfig = (formdata = false) => {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  if (formdata && authUser.accessToken) {
    return {
      headers: {
        Authorization: authUser?.accessToken,
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,PATCH,DELETE",
      },
    }
  }
  if (authUser.accessToken) {
    return {
      headers: {
        Authorization: authUser?.accessToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,PATCH,DELETE",
      },
    }
  }

  return {}
}
const InfoWindow = ({ deviceInfo }) => {
  const { device, deviceStatusDetails } = deviceInfo
  const id = device.show ? "simple-popover" : undefined
  let engine = "OFF"
  if (device.acc) {
    engine = "ON"
  }

  let engineDuration = dateDifferent(device.accLastStatusAt, moment.utc())
  return (
    <>
      <div className="row d-flex">
        <div className="leftpop col-md-8">
          <span>
            <h5 className="text-info">
              {device.name ? `${device.name}(${device.model})` : device.model}
            </h5>{" "}
          </span>
          <span>
            <b>Status:</b> {deviceStatusDetails.status}
            {deviceStatusDetails.duration
              ? `(${deviceStatusDetails.duration})`
              : ""}
          </span>
          <span>
            <b>Engine:</b> {engine}{" "}
            {engineDuration ? `(${engineDuration})` : ""}
          </span>
          {device?.info?.voltage && engine != "OFF" ? (
            <span>
              <b>External Power:</b>{" "}
              {device.info.voltage == "No power(shutdown)" ? "OFF" : "ON"}
            </span>
          ) : null}

          <span>
            <b>IMEI:</b> {device.imei}
          </span>
          <span>
            <b>Coordinate:</b>
            <b className="text-info">
              {" "}
              {device.lat.length > 10
                ? device.lat.substring(0, 10)
                : device.lat}
              ,
              {device.lng.length > 10
                ? device.lng.substring(0, 10)
                : device.lng}
            </b>
          </span>
        </div>
      </div>
    </>
  )
}

const Track = () => {
  const dispatch = useDispatch();
  const [locationData, setLocationData] = useState([]);
  const [trackData, setTrackData] = useState([]);
  const [otherLocationData, setOtherLocationData] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState();
  const { deviceId } = useParams();
  const [lastLocationId, setLastLocationId] = useState();
  const [vehicleIcon, setVehicleIcon] = useState();
  const [vehicleStatus, setVehicleStatus] = useState();
  const [viewData, setViewData] = useState();
  const [location, setLocation] = useState();
  const [showLocation, setShowLocation] = useState(false);
  const [intervalTimeTrack, setIntervalTimeTrack] = useState(3000);
  const mapRef = useRef(null);
  const markerRef = useRef([]);

  const getData = async (urls) => {
    try {
      if (urls.length === 0) {
        return [];
      }
      const promiseArrayUrl = urls.map((url) => axios.get(`${API_URL}${url}`, apiConfig()));
      const result = (await Promise.all(promiseArrayUrl)).map((response) => response.data);
      return result;
    } catch (error) {
      console.log("Issue in fetching data", error);
    }
  };

  useEffect(() => {
    (async () => {
      const initialData = await getData([
        `${url.GET_TRACK_INITIAL_DATA}${deviceId}/CurrentLocationInitial`,
        `${url.GET_TRACK_INITIAL_DATA}${deviceId}`,
      ]);

      const vehicleStatus = renderSwitch(initialData[1]);
      setVehicleStatus(vehicleStatus);
      setVehicleIcon(`car${vehicleStatus.icnStatus}left`);
      setOtherLocationData(initialData[0]);
      const initialLocationData = initialData[0].map((locData) => [
        parseFloat(locData.lat),
        parseFloat(locData.lng),
      ]);
      setTrackData(initialLocationData);
      setLocationData(initialLocationData);
      setDeviceDetails(initialData[1]);

      const map = mapRef.current;
      if (map) {
        map.target.flyTo(
          [initialLocationData[0][0], initialLocationData[0][1]],
          17
        );
      }
    })();
  }, []);

  useEffect(() => {
    if (otherLocationData.length === 0) return;
    console.log("-==============Data enters================", otherLocationData, "-==============Data ================")
    const interval = setInterval(async () => {
      const nextLocationData = await getData([
        `${url.GET_TRACK_INITIAL_DATA}${deviceId}/CurrentLocation?locationId=${otherLocationData[otherLocationData.length - 1].id
        }`,
        `${url.GET_TRACK_INITIAL_DATA}${deviceId}`,
      ]);
      const nextLocationDataSort= nextLocationData[0].sort((a, b) => parseInt(a.id) - parseInt(b.id));
      const vehicleStatus = renderSwitch(nextLocationData[1]);
      setVehicleStatus(vehicleStatus);
      setVehicleIcon(`car${vehicleStatus.icnStatus}left`);
      setDeviceDetails(nextLocationData[1]);

      if (nextLocationDataSort.length > 0) {
        const allLocData = nextLocationDataSort.map((locData) => [
          parseFloat(locData.lat),
          parseFloat(locData.lng),
        ]);

        // setTrackData((prevTrackData) => [...prevTrackData, ...allLocData]);
        // setLocationData((prevLocationData) => [...prevLocationData, ...allLocData]);
        // setOtherLocationData((prevOther) => [...prevOther, ...nextLocationData[0]]);

        setTrackData((prevTrackData) => {
          const last10LocationsTrack = prevTrackData.slice(-10);
          const finalArrTrack = allLocData.filter(val => !last10LocationsTrack.includes(val));
          return [...prevTrackData, ...finalArrTrack]
        });
        setLocationData((prevLocationData) => {
          const last10LocationsLocation = prevLocationData.slice(-10);
          const finalArrLoc = allLocData.filter(val => !last10LocationsLocation.includes(val));
          return [...prevLocationData, ...finalArrLoc]
        }
        );
        
        setOtherLocationData((prevOther) => {
          console.log("sate=======================", prevOther, nextLocationDataSort)
          return [...prevOther, ...nextLocationDataSort]
        });


      }
    }, 10000);

    return () => clearInterval(interval);
  }, [otherLocationData]);

  useEffect(() => {
    if (locationData.length === 0) return;

    let i = trackData.length;
    const interval = setInterval(() => {
      if (!locationData[i]) {
        console.log('No more data to add.');
        clearInterval(interval);
        return;
      }

      const newLocation = locationData[i];

      if (trackData.length === 0) {
        // If trackData is empty, add the first location
        setTrackData([newLocation]);
      } else {

        const newLocation = locationData[locationData.length - 1]; // Get the latest location

        // Check if the new location is not in the last 10 values of trackData
        const last10Locations = trackData.slice(-10);
        const isNewLocationUnique = !last10Locations.some(
          (location) =>
            location[0] === newLocation[0] && location[1] === newLocation[1]
        );
        if (
          isNewLocationUnique
        ) {
          setTrackData((prevTrackData) => [...prevTrackData, newLocation]);
        } else {
          console.log('Duplicate data detected:', newLocation);
        }
      }

      i++;
    }, intervalTimeTrack);

    return () => clearInterval(interval);
  }, [locationData, intervalTimeTrack, trackData]);

  console.log("trackData:", trackData.length, trackData, "==================", "locationData:", locationData.length, locationData)

  const limeOptions = {
    color: "#7fff00",
    weight: 5,
    lineCap: "round",
    bubblingMouseEvents: true,
  }
  console.log(trackData, "trackData")
  return (
    <>
      {showLocation && (
        <div className="curmaplocation">
          <span>{location}</span>
        </div>
      )}
      <Counter />
      <LeafMap
        whenReady={map => {

          mapRef.current = map
        }}
        zoom={7}
        center={[13.063036666666667, 80.27216888888888]}
      >
        {trackData && trackData.length > 0 && vehicleIcon && (
          <ReactLeafletDriftMarker
            position={trackData[trackData.length - 1]}
            duration={500}
            keepAtCenter={false}
            icon={iconComponents[vehicleIcon]}
            eventHandlers={{
              dblclick: () => {
                Geocode.fromLatLng(
                  trackData[trackData.length - 1][0],
                  trackData[trackData.length - 1][1]
                )
                  .then(response => {
                    const address = response.results[0].formatted_address
                    setShowLocation(true)
                    setLocation(address)
                  })
                  .catch(error => {
                    console.error(error)
                  })
              },
              click: () => {
                setShowLocation(false)
              },
            }}
          >
            {" "}
            {deviceDetails && (
              <>
                <Popup>
                  <InfoWindow
                    deviceInfo={{
                      device: deviceDetails,
                      deviceStatusDetails: vehicleStatus,
                    }}
                  />
                </Popup>
                <Tooltip>
                  {deviceDetails.name
                    ? deviceDetails.name
                    : deviceDetails.model}
                </Tooltip>
              </>
            )}
            <Polyline pathOptions={limeOptions} positions={trackData} />
          </ReactLeafletDriftMarker>
        )}
      </LeafMap>
    </>
  )
}

export default memo(Track)
