/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { Tooltip, InputGroup, Input } from "reactstrap"
import NotificationAlertEmail from "./NotificationAlertEmail"
import Flatpickr from "react-flatpickr"

import { bindActionToPromise } from "../../../utils/Common"

import { connect } from "react-redux"

import { putPreferences } from "../../../store/actions"

import { useSelector } from "react-redux"

import LoadingButton from "../../../components/Common/Button"

const NotificationAlert = props => {
  const {
    preferenceInput,
    notificationAlertInput,
    updateNotificationAlertField,
    notificationServiceInput,
    selectedUser,
    tog_preferences,
  } = props

  const { emailsList, alertList, fromTime, toTime } = notificationAlertInput

  const { isLoading } = useSelector(state => {
    return {
      isLoading: state.Preferences.isLoading,
    }
  })

  const [ttop, setttop] = useState(false)

  const isAllAlertSetValue =
    alertList.findIndex(ele => !ele.isAlert) < 0 ? true : false

  const isAllEmailSetValue =
    alertList.findIndex(ele => !ele.isEmail) < 0 ? true : false

  const isAllAppSetValue =
    alertList.findIndex(ele => !ele.isApp) < 0 ? true : false

  const setAlertAllChange = value => {
    let newAlertList = alertList.map(ele => {
      let newEle = { ...ele, isAlert: value }
      return newEle
    })
    updateNotificationAlertField("alertList", [...newAlertList])
  }

  const setEmailALLChange = value => {
    let newAlertList = alertList.map(ele => {
      let newEle = { ...ele, isEmail: value }
      return newEle
    })
    updateNotificationAlertField("alertList", [...newAlertList])
  }

  const setAppAllChange = value => {
    let newAlertList = alertList.map(ele => {
      let newEle = { ...ele, isApp: value }
      return newEle
    })
    updateNotificationAlertField("alertList", [...newAlertList])
  }

  const addNewEmail = () => {
    const newEmailList = emailsList
    newEmailList.push({
      email: "",
      isVerified: false,
    })
    updateNotificationAlertField("emailsList", newEmailList)
  }

  const removeEmail = index => {
    const newEmailList = emailsList.filter((ele, i) => i !== index)
    updateNotificationAlertField("emailsList", newEmailList)
  }

  const updateEmail = (index, value) => {
    const newEmailList = [...emailsList]
    newEmailList[index].email = value
    updateNotificationAlertField("emailsList", newEmailList)
  }

  const updateAlertList = (key, val, index) => {
    let newAlertList = alertList
    newAlertList[index][key] = val

    updateNotificationAlertField("alertList", newAlertList)
  }

  const secondToDateFromTime = seconds => {
    const d = Number(seconds)
    const h = Math.floor(d / 3600)
    const m = Math.floor((d % 3600) / 60)
    const s = Math.floor((d % 3600) % 60)
    return new Date(new Date(new Date().setHours(h)).setMinutes(m)).setSeconds(
      s
    )
  }

  const secondToDateToTime = seconds => {
    const d = Number(seconds)
    const h = Math.floor(d / 3600)
    const m = Math.floor((d % 3600) / 60)
    const s = Math.floor((d % 3600) % 60)
    return new Date(new Date(new Date().setHours(h)).setMinutes(m)).setSeconds(
      s
    )
  }



  return (
    <div>
      <div className="pb-3">
        <span className="pe-2">
          <Input
            type="checkbox"
            className="form-check-Input"
            id="customSwitchsizemd-message"
            onClick={e =>
              updateNotificationAlertField(
                "isAlertNotification",
                Number(e.target.checked)
              )
            }
            checked={notificationAlertInput?.isAlertNotification}
            defaultChecked={notificationAlertInput?.isAlertNotification}
          />
        </span>
        <span>Receive alert notification</span>
      </div>
      {notificationAlertInput?.isAlertNotification ? (
        <div className="notif_panel_1">
          <div className="cus_br p-2 mb-3">
            Alert duration:
            <div className="pt-2 d-flex align-items-center">
              <span className="pe-2">
                <Input
                  type="checkbox"
                  className="form-check-Input"
                  id="customSwitchsizemd-message"
                  onClick={e =>
                    updateNotificationAlertField(
                      "isAllDay",
                      Number(e.target.checked)
                    )
                  }
                  checked={notificationAlertInput?.isAllDay}
                  defaultChecked={notificationAlertInput?.isAllDay}
                />
              </span>
              All day
              {!notificationAlertInput?.isAllDay && (
                <>
                  <div className="w100 ms-2">
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="From Time"
                        data-enable-time
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                        }}
                        value={secondToDateFromTime(fromTime)}
                        onClose={date =>
                          updateNotificationAlertField(
                            "fromTime",
                            new Date(date).getHours() * 3600 +
                              new Date(date).getMinutes() * 60 +
                              new Date(date).getSeconds()
                          )
                        }
                      />
                    </InputGroup>
                  </div>
                  <div className="ms-2"> -</div>
                  <div className="w100 ms-2">
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="To Time"
                        data-enable-time
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                        }}
                        value={secondToDateToTime(
                          notificationAlertInput?.toTime
                        )}
                        onChange={date =>
                          updateNotificationAlertField(
                            "toTime",
                            new Date(date).getHours() * 3600 +
                              new Date(date).getMinutes() * 60 +
                              new Date(date).getSeconds()
                          )
                        }
                      />
                    </InputGroup>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="cus_br p-2 mb-3">
            <table className="table mb-0 table header-fixed">
              <thead>
                <tr>
                  <th>Alert type</th>
                  <th width="120">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="customSwitchsizemd-message"
                      onClick={e => setAlertAllChange(e.target.checked)}
                      checked={isAllAlertSetValue}
                      defaultChecked={isAllAlertSetValue}
                    />{" "}
                    Alert list
                  </th>
                  <th width="80">
                    <Input
                      type="checkbox"
                      className="form-check-Input"
                      id="customSwitchsizemd-email"
                      onClick={e => {
                        setEmailALLChange(e.target.checked)
                      }}
                      checked={isAllEmailSetValue}
                      defaultChecked={isAllEmailSetValue}
                    />{" "}
                    Email
                  </th>
                  <th width="120">
                    <div className="d-flex align-items-center">
                      <Input
                        type="checkbox"
                        className="form-check-Input pr-1"
                        id="customSwitchsizemd-app"
                        onClick={e => {
                          setAppAllChange(e.target.checked)
                        }}
                        checked={isAllAppSetValue}
                        defaultChecked={isAllAppSetValue}
                      />{" "}
                      <span style={{ paddingLeft: "3px" }}>App</span>
                      <i
                        title="This is APP notification, not alert list."
                        className="helpIcon  bx bxs-info-circle fs-5 ms-2"
                      ></i>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {alertList.map((alertElement, index) => (
                  <tr key={index}>
                    <td>{alertElement?.alertType}</td>
                    <td>
                      <Input
                        type="checkbox"
                        className="form-check-Input"
                        id="customSwitchsizemd-email"
                        onClick={e =>
                          updateAlertList("isAlert", e.target.checked, index)
                        }
                        checked={alertElement.isAlert}
                        defaultChecked={alertElement.isAlert}
                      />
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        className="form-check-Input"
                        id="customSwitchsizemd-email"
                        onClick={e =>
                          updateAlertList("isEmail", e.target.checked, index)
                        }
                        checked={alertElement.isEmail}
                        defaultChecked={alertElement.isEmail}
                        disabled={!alertElement?.isAlert}
                      />
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        className="form-check-Input"
                        id="customSwitchsizemd-isapp"
                        onClick={e =>
                          updateAlertList("isApp", e.target.checked, index)
                        }
                        checked={alertElement.isApp}
                        defaultChecked={alertElement.isApp}
                        disabled={!alertElement?.isAlert}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="cus_br p-2 mb-3">
            Mobile:{" "}
            <Tooltip
              placement="top"
              isOpen={ttop}
              target="TooltipTop1"
              toggle={() => {
                setttop(!ttop)
              }}
              className="fs-2 text-left"
            >
              SMS Notification 1 Only few device models (VT05S, VT08S, GT06N
              etc.) support this function,Please confirm with your supplier
              first if you need it. 2 Only support geofence-in/out SMS alerts. 3
              Suggestion:Do NOT allow it ON. Since SMS alert will have extra
              cost, the SIM card may have no balance if send too many SMS and
              the GPS device will be offline.
            </Tooltip>
            <span id="TooltipTop1">
              <i className=" bx bxs-info-circle txt_colr_1"></i>
            </span>
            <div className="pt-2">
              <Input
                name="mobile"
                type="text"
                className="form-control"
                onChange={e =>
                  updateNotificationAlertField("mobile", e.target.value)
                }
                value={notificationAlertInput.mobile}
              />
            </div>
          </div>
        </div>
      ) : null}

      <NotificationAlertEmail
        emailsList={emailsList}
        removeEmail={removeEmail}
        addNewEmail={addNewEmail}
        updateEmail={updateEmail}
      />

      <div className="border-top p-3 mt-2 text-end">
        <LoadingButton
          color="secondary"
          className="btn btn-outline-primary submitbtn btn-right-margin"
          loading={false}
          disabled={false}
          block={false}
          onClick={() => tog_preferences()}
        >
          Cancel
        </LoadingButton>

        <LoadingButton
          color="primary"
          className="btn btn-primary submitbtn "
          loading={isLoading}
          disabled={isLoading}
          block={false}
          onClick={() =>
            props
              .putPreferencesAction({
                data: {
                  preferences: {
                    ...preferenceInput,
                  },
                  notificationAlert: {
                    ...notificationAlertInput,
                  },
                  notificationService: {
                    ...notificationServiceInput,
                  },
                },
                memberId: selectedUser?.id,
              })
              .then(() => {
                tog_preferences()
              })
          }
        >
          Save
        </LoadingButton>
      </div>
    </div>
  )
}

NotificationAlert.propTypes = {
  preferenceInput: PropTypes.obj,
  notificationAlertInput: PropTypes.obj,
  notificationServiceInput: PropTypes.obj,
  updateNotificationServiceField: PropTypes.func,
  updateNotificationAlertField: PropTypes.func,
  selectedUser: PropTypes.obj,
  tog_preferences: PropTypes.func,
  putPreferencesAction: PropTypes.func,
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  putPreferencesAction: bindActionToPromise(dispatch, putPreferences),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationAlert)
