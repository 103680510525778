export const GET_CARDS_DATA = "GET_CARDS_DATA"
export const GET_CARDS_DATA_FAIL = "GET_CARDS_DATA_FAIL"
export const GET_CARDS_DATA_SUCCESS = "GET_CARDS_DATA_SUCCESS"

export const ADD_CARD_DEFAULT = "ADD_CARD_DEFAULT"
export const ADD_CARD_DEFAULT_FAIL = "ADD_CARD_DEFAULT_FAIL"
export const ADD_CARD_DEFAULT_SUCCESS = "ADD_CARD_DEFAULT_SUCCESS"

export const GET_DISTRIBUTOR_DATA_DEFAULT = "GET_DISTRIBUTOR_DATA_DEFAULT"
export const GET_DISTRIBUTOR_DATA_FAIL = "GET_DISTRIBUTOR_DATA_FAIL"
export const GET_DISTRIBUTOR_DATA_FAIL_SUCCESS = "GET_DISTRIBUTOR_DATA_FAIL_SUCCESS"

export const GET_CARD_COUNT_DATA_DEFAULT = "GET_CARD_COUNT_DATA_DEFAULT"
export const GET_CARD_COUNT_DATA_FAIL = "GET_CARD_COUNT_DATA_FAIL"
export const GET_CARD_COUNT_DATA_SUCCESS = "GET_CARD_COUNT_DATA_SUCCESS"

export const GET_CARD_BALANCE_DATA_DEFAULT = "GET_CARD_BALANCE_DATA_DEFAULT"
export const GET_CARD_BALANCE_DATA_FAIL = "GET_CARD_BALANCE_DATA_FAIL"
export const GET_CARD_BALANCE_DATA_SUCCESS = "GET_CARD_BALANCE_DATA_SUCCESS"

export const ADD_TRANSFER_CARD_DEFAULT = "ADD_TRANSFER_CARD_DEFAULT"
export const ADD_TRANSFER_CARD_FAIL = "ADD_TRANSFER_CARD_FAIL"
export const ADD_TRANSFER_CARD_SUCCESS = "ADD_TRANSFER_CARD_SUCCESS"