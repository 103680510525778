/* eslint-disable */

import React, { useState, useEffect } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import TreeSelect from "../../components/user/commonPopUpTree"

//Store Operation
import { checkImeNumber, addSales } from "../../store/actions"
import LoadingButton from "../../components/Common/Button"
import { bindActionToPromise } from "utils/Common"




const Sale = ({
  userInfo,
  addSaleDeviceToOthersAction,
  checkIMENumberAction,
  updatePopupClose,
}) => {
  const { checkIME, isLoadingCheckIME, isLoadingAddToSales } = useSelector(
    state => {
      return {
        checkIME: state.SharedDetails.imeNumberDetails.devices,
        isLoadingCheckIME: state.SharedDetails.checkIMIELoading,
        isLoadingAddToSales: state.SharedDetails.addSalesLoading,
      }
    }
  )
  const [parentID, setParentID] = useState(userInfo?.id || "")
  const [errorParentId, setErrorParentId] = useState("")

  const [IMEInput, setIMEInput] = useState("")
  const [errorIME, setErrorIME] = useState("")
  const [deviceName, setDeviceName] = useState("")
  const [accountName, setAccountName] = useState("")
  const [simCard, setSimCard] = useState("")
  const [dueDate, setDueDate] = useState("")
  const handleSetData = childData => {
    setParentID(childData)
  }

  useEffect(() => {
    if (checkIME) {
      setDeviceName(checkIME?.name)
      setAccountName(checkIME?.members[0].name)
      setSimCard(checkIME?.simCard)
      setDueDate(checkIME?.platformExpirationDate)
    }
  }, [checkIME])

  const handleSubmit = () => {
    console.log("entered")
    if (parentID === "") setErrorParentId("Please Select Target User")
    if (IMEInput === "") setErrorIME("Please Enter IMEI Number")

    if (parentID != "" && IMEInput != "") {
      const payloadData = {
        targetMemberId: parentID,
        imei: IMEInput,
        userDue: dueDate,
        deviceName,
        simCard,
      }

      console.log(payloadData, "payloadData")

      if (payloadData) {
        addSaleDeviceToOthersAction(payloadData).then(data => {
          updatePopupClose(false)
        })
      }
    }
  }
  const handleCheck = () => {
    if (IMEInput === "") setErrorIME("Please Enter IMEI Number")
    const IMEData = {
      imei: IMEInput,
    }

    checkIMENumberAction(IMEData).then(data => {
      console.log(data)
    })
  }

  return (
    <div>
      <form>
        <div className="modal-body">
          <Row className="mb-3">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label text-start"
                  >
                    Target user <small className="text-danger">*</small>
                  </label>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  {" "}
                  <TreeSelect
                    parentCallback={handleSetData}
                    selectedValue={userInfo}
                  />
                  {errorParentId ? (
                    <FormFeedback type="invalid" style={{ display: "block" }}>
                      {errorParentId}
                    </FormFeedback>
                  ) : null}
                  {/* <Button color="secondary" className="col-auto btnadd" outline>
                  Add +
                </Button> */}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label text-start"
                  >
                    IMEI <small className="text-danger">*</small>
                  </label>
                  {errorIME ? (
                    <FormFeedback type="invalid" style={{ display: "block" }}>
                      {errorIME}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <input
                    className="form-control"
                    type="text"
                    value={IMEInput}
                    onChange={e => setIMEInput(e.target.value)}
                    name="IMEInfo"
                  />
                  <LoadingButton
                    id="Popoverright"
                    color="primary"
                    className="btn btn-primary btn-sm"
                    loading={isLoadingCheckIME}
                    disabled={isLoadingCheckIME || IMEInput === ""}
                    onClick={() => {
                      handleCheck()
                    }}
                  >
                    Search
                  </LoadingButton>
                </div>
              </div>
            </div>
          </Row>

          <Row className="mb-3">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label text-start"
                  >
                    Device name <small className="text-danger">*</small>
                  </label>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  {" "}
                  <input
                    className="form-control"
                    type="text"
                    value={deviceName}
                    onChange={e => setDeviceName(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label text-start"
                  >
                    Account
                  </label>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <input
                    className="form-control"
                    type="text"
                    Name="accountName"
                    value={accountName}
                    onChange={e => setAccountName(e.target.value)}
                    disabled
                  />
                </div>
              </div>
            </div>
          </Row>

          <Row className="mb-3">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label text-start"
                  >
                    SIM card
                  </label>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  {" "}
                  <input
                    className="form-control"
                    type="text"
                    value={simCard}
                    onChange={e => setSimCard(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label text-start"
                  >
                    User due <small className="text-danger">*</small>
                  </label>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <input
                    className="form-control"
                    type="date"
                    value={dueDate}
                    onChange={e => setDueDate(e.target.value)}
                    id="example-date-input"
                  />
                </div>
              </div>
            </div>
          </Row>

          <Row className="mb-3">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-12 col-form-label text-start"
                  >
                    Remark
                  </label>
                </div>
                <div className="col-md-10 d-flex align-items-center">
                  {" "}
                  <textarea rows="4" className="form-control"></textarea>
                </div>
              </div>
            </div>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="modal-footer">
            <LoadingButton
              color="primary"
              className="btn btn-primary"
              loading={isLoadingAddToSales}
              disabled={isLoadingAddToSales}
              block={false}
              onClick={() => handleSubmit()}
            >
              Submit
            </LoadingButton>
          </div>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  checkIMENumberAction: bindActionToPromise(dispatch, checkImeNumber),
  addSaleDeviceToOthersAction: bindActionToPromise(dispatch, addSales),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sale)
