export const GET_GEO_FENCE_DETAILS = "GET_GEO_FENCE_DETAILS"
export const GET_GEO_FENCE_DETAILS_SUCCESS = "GET_GEO_FENCE_DETAILS_SUCCESS"
export const GET_GEO_FENCE_DETAILS_ERROR = "GET_GEO_FENCE_DETAILS_ERROR"

export const GET_GEO_FENCE_ADD = "GET_GEO_FENCE_ADD"
export const GET_GEO_FENCE_ADD_SUCCESS = "GET_GEO_FENCE_ADD_SUCCESS"
export const GET_GEO_FENCE_ADD_ERROR = "GET_GEO_FENCE_ADD_ERROR"

export const GET_GEO_FENCE_EDIT = "GET_GEO_FENCE_EDIT"
export const GET_GEO_FENCE_EDIT_SUCCESS = "GET_GEO_FENCE_EDIT_SUCCESS"
export const GET_GEO_FENCE_EDIT_ERROR = "GET_GEO_FENCE_EDIT_ERROR"


export const GET_GEO_FENCE_DEVICE_LINK = "GET_GEO_FENCE_DEVICE_LINK"
export const GET_GEO_FENCE_DEVICE_LINK_SUCCESS = "GET_GEO_FENCE_LINK_SUCCESS"
export const GET_GEO_FENCE_DEVICE_LINK_ERROR = "GET_GEO_FENCE_LINK_ERROR"


export const GET_GEO_FENCE_GROUP_LINK = "GET_GEO_FENCE_GROUP_LINK"
export const GET_GEO_FENCE_GROUP_LINK_SUCCESS = "GET_GEO_FENCE_GROUP_LINK_SUCCESS"
export const GET_GEO_FENCE_GROUP_LINK_ERROR = "GET_GEO_FENCE_GROUP_LINK_ERROR"
