/* eslint-disable */

import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import CheckPopup from "./CheckPopup"
import TreeSelect from "../../components/user/treeMenuSelectBox"
import { checkImeiDefault, bulkSaleDefault } from "../../store/actions"
import { connect, useDispatch, useSelector } from "react-redux"
import LoadingButton from "../../components/Common/Button"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { bindActionToPromise } from "utils/Common"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

// const validationSchema = yup.object().shape({
//   dueDate: yup.string().required('DueDate is required'),
//   imei: yup.string().required('Imei is required'),
// })

function BulkSale(props) {
  const dispatch = useDispatch()
  const { selectedUser = {}, selectedRows } = props
  const today = new Date()
  const minDateDisable = new Date(today)
  minDateDisable.setDate(minDateDisable.getDate() + 1)
  const [popoverright, setpopoverright] = useState(false)
  const [parentID, setParentID] = useState(selectedUser?.id || "")
  const [userDue, setUserDue] = useState(minDateDisable)
  const [imeiInput, setImeiInput] = useState([])
  const [errorParentId, setErrorParentId] = useState("")
  const [errorUserDue, setErrorUserDue] = useState("")
  const [errorImei, setErrorImei] = useState("")

  const { checkImeiData, isLoadingCheckImei, isLoadingBulkSale } = useSelector(
    state => {
      return {
        checkImeiData: state.AddDevice.imeiResData,
        isLoadingCheckImei: state.AddDevice.isLoadingCheckImei,
        isLoadingBulkSale: state.AddDevice.isLoadingBulkSale,
      }
    }
  )

  useEffect(() => {
    if (selectedRows && selectedRows.length > 0) {
      setImeiInput(selectedRows)
    }
  }, [])

  const handleSetData = childData => {
    setErrorParentId("")
    setParentID(childData)
  }

  const updateImei = value => {
    setErrorImei("")
    const updateArray = value ? value.split("\n") : []
    setImeiInput(updateArray)
  }

  const updateUserDue = value => {
    setErrorUserDue("")
    setUserDue(value)
  }

  const handleCheck = () => {
    const imeiData = {
      imei: imeiInput,
    }
    props.checkImeiDefaultAction(imeiData).then(() => {
      setpopoverright(true)
    })
  }

  const handleSubmit = () => {
    if (parentID === "") {
      setErrorParentId("Please Select Target User")
    }

    if (userDue === "" || userDue === null) {
      setErrorUserDue("Please Select User due")
    }

    if (imeiInput.length <= 0) setErrorImei("Please Enter IMEI")

    if (
      userDue !== "" &&
      userDue !== null &&
      imeiInput.length > 0 &&
      parentID !== ""
    ) {
      const bulkSale = {
        targetMemberId: parentID,
        imei: imeiInput,
        dueDate: userDue,
      }

      props
        .bulkSaleDefaultAction(bulkSale)
        .then(() => {
          props.setmodal_center(false)
        })
        .catch(e => {
          props.setmodal_center(false)
        })
    }
  }

  return (
    <>
      <form>
        <div>
          <div className="modal-body">
            <Row className="mb-3">
              <label
                htmlFor="example-text-input"
                className="col-md-4 col-form-label text-start"
              >
                Target user <small className="text-danger">*</small>
              </label>
              <div className="col-md-8">
                <TreeSelect
                  parentCallback={handleSetData}
                  selectedValue={parentID}
                />
                {errorParentId ? (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    {errorParentId}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="example-text-input"
                className="col-md-4 col-form-label text-start"
              >
                User due <small className="text-danger">*</small>
              </label>
              <div className="col-md-8">
                <DatePicker
                  name="platformExpirationDate"
                  className="form-control"
                  selected={userDue || null}
                  onChange={date => updateUserDue(date)}
                  placeholderText=""
                  minDate={minDateDisable}
                />
                {errorUserDue ? (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    {errorUserDue}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="example-text-input"
                className="col-md-4 col-form-label text-start"
              >
                IMEI <small className="text-danger">*</small>
              </label>
              <div className="col-md-8">
                <div className="row gx-2">
                  <div className="col">
                    <Input
                      type="textarea"
                      placeholder="Enter Imei"
                      value={imeiInput ? imeiInput.join("\n") : ""}
                      onChange={e => updateImei(e.target.value)}
                      rows={5}
                    />
                    <span className="font-size-12">
                      One batch for 200 IMEI at most.
                    </span>
                  </div>
                  {errorImei ? (
                    <FormFeedback type="invalid" style={{ display: "block" }}>
                      {errorImei}
                    </FormFeedback>
                  ) : null}
                  <div className="col-auto">
                    <LoadingButton
                      id="Popoverright"
                      color="primary"
                      className="btn btn-primary btn-sm"
                      loading={isLoadingCheckImei}
                      disabled={isLoadingCheckImei || imeiInput.length <= 0}
                      block={false}
                      onClick={() => {
                        handleCheck()
                      }}
                    >
                      Check
                    </LoadingButton>

                    {checkImeiData && !isLoadingCheckImei && (
                      <Popover
                        placement="right"
                        isOpen={popoverright}
                        target="Popoverright"
                        toggle={() => {
                          setpopoverright(!popoverright)
                        }}
                        className="width350"
                      >
                        <PopoverBody>
                          <span
                            id="Popoverright"
                            onClick={() => {
                              setpopoverright(!popoverright)
                            }}
                            className="popoverclose"
                          >
                            <i className="mdi mdi-close"></i>
                          </span>
                          <CheckPopup checkImeiData={checkImeiData} />
                        </PopoverBody>
                      </Popover>
                    )}
                  </div>
                </div>
              </div>
            </Row>
          </div>
          <div className="modal-footer">
            <LoadingButton
              color="primary"
              className="btn btn-primary"
              loading={isLoadingBulkSale}
              disabled={isLoadingBulkSale}
              block={false}
              onClick={() => handleSubmit()}
            >
              Save changes
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  checkImeiDefaultAction: bindActionToPromise(dispatch, checkImeiDefault),
  bulkSaleDefaultAction: bindActionToPromise(dispatch, bulkSaleDefault),
})

export default connect(mapStateToProps, mapDispatchToProps)(BulkSale)
