/* eslint-disable */

import React, { useEffect, useState } from "react"

import {
  CardText,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap"

import { Link } from "react-router-dom"
import classnames from "classnames"
import vehicleicons from "../../assets/images/icons/vehicle.png"
import DeviceInfo1 from "../../components/Business/DeviceInfo1"
import InstallationInformation from "../../components/Business/InstallationInformation"
import DeviceOtherInformation from "../../components/Business/DeviceOtherInformation"
import DeviceAlertSettings from "../../components/Business/DeviceAlertSettings"
import FuelInformation from "../../components/Business/FuelInformation"

import { useSelector, useDispatch } from "react-redux"

import { getDeviceByDeviceId } from "../../store/actions"

const deviceInfoInputsInitialInputs = {
  name: "",
  imei: "",
  vehiclePlatNumber: "",
  simCard: "",
  model: "",
  exFactoryDate: null,
  activatedDate: null,
  platformExpirationDate: null,
  userDue: null,
  icon: "",
  vehiclePicture: "",
  remark: "",
  updatedAt: "",
  deviceType: "",
}

const deviceInstallationInfoInputsInitialInputs = {
  person: "",
  time: "",
  company: "",
  position: "",
  address: "",
  image: "",
  remark: "",
}

const fuelInformationInitialInputs = {
  consumption: 0,
  tank: {
    shape: "Cube",
    cube: {
      length: 0,
      width: 0,
      height: 0,
    },
    horizontal: {
      length: 0,
      diameter: 0,
    },
    volume: 0,
  },
  sensor: {
    input: "Analogue input 1",
    type: "Default",
  },
  calibration: {
    input: "Analogue input 1",
    values: [
      {
        id: 1,
        value_l: "",
        sensorValue: "",
      },
      {
        id: 2,
        value_l: "",
        sensorValue: "",
      },
      {
        id: 3,
        value_l: "",
        sensorValue: "",
      },
      {
        id: 4,
        value_l: "",
        sensorValue: "",
      },
      {
        id: 5,
        value_l: "",
        sensorValue: "",
      },
      {
        id: 6,
        value_l: "",
        sensorValue: "",
      },
      {
        id: 7,
        value_l: "",
        sensorValue: "",
      },
    ],
  },
}

const deviceOtherInformationInitialInputs = {
  driverName: "",
  mobile: "",
  vin: "",
  engineNumber: "",
  color: "red",
  vehicleBrand: "",
}

const deviceAlertSettingsInitialInputs = {
  engineOff: false,
  engineOn: false,
  doorOpen: false,
  doorClose: false,
  oilLeakAlert: false,
  refueling: false,
  speedingAlert: "",
  engineIdle: "",
  offline: false,
  parking: "",
  highTemperature: "",
  lowTemperature: "",
  fatigueDriving: "",
  restrictedDriving: [],
  minimumRest: "",
  geoFence: {
    in: true,
    out: true,
    speeding: true,
  },
}

function DeviceDetails(props) {
  const { selectedDevice, selectedUser, tog_model } = props
  console.log(selectedDevice, selectedUser, tog_model,"DeviceDetails props");
  const dispatch = useDispatch()
  const [deviceInfoInputs, setDeviceInfoInputs] = useState(
    deviceInfoInputsInitialInputs
  )
  const [deviceInstallationInfoInputs, setDeviceInstallationInfoInputs] =
    useState(deviceInstallationInfoInputsInitialInputs)

  const [fuelInformationInputs, setFuelInformationInputs] = useState(
    fuelInformationInitialInputs
  )

  const [deviceOtherInformationInputs, setDeviceOtherInformationInputs] =
    useState(deviceOtherInformationInitialInputs)

  const [deviceAlertSettingsInputs, setDeviceAlertSettingsInputs] = useState(
    deviceAlertSettingsInitialInputs
  )

  const updateDeviceInfoInputs = (key, value) => {
    setDeviceInfoInputs({
      ...deviceInfoInputs,
      [key]: value,
    })
  }

  const updateDeviceInstallationInfoInputs = (key, value) => {
    setDeviceInstallationInfoInputs({
      ...deviceInstallationInfoInputs,
      [key]: value,
    })
  }

  const updateFuelInformationInputs = (key, value) => {
    setFuelInformationInputs({
      ...fuelInformationInputs,
      [key]: value,
    })
  }

  const updateDeviceOtherInformationInputs = (key, value) => {
    setDeviceOtherInformationInputs({
      ...deviceOtherInformationInputs,
      [key]: value,
    })
  }

  const updateDeviceAlertSettingsInputs = (key, value) => {
    setDeviceAlertSettingsInputs({
      ...deviceAlertSettingsInputs,
      [key]: value,
    })
  }

  const [activeTab, setactiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const { deviceDetail, isLoadingDetail } = useSelector(state => {
    return {
      deviceDetail: state.Device.deviceDetail,
      isLoadingDetail: state.Device.isLoadingDetail,
    }
  })

  const addEmptyNRecord = (array, start, end) => {
    for (let i = start; i <= end; i++) {
      array.push({
        id: i,
        value_l: "",
        sensorValue: "",
      })
    }
    return array
  }

  useEffect(() => {
    if (selectedDevice?.id) {
      dispatch(getDeviceByDeviceId(selectedDevice.id))
    }
  }, [selectedDevice])

  useEffect(() => {
    if (deviceDetail && Object.keys(deviceDetail).length > 0) {
      const {
        name = "",
        imei,
        vehiclePlatNumber,
        simCard,
        model,
        exFactoryDate,
        activatedDate,
        platformExpirationDate,
        userDue,
        icon,
        vehiclePicture,
        remark,
        updatedAt,
        deviceType,
        installationInfo,
        otherInfo,
        fuelInfo,
        alert,
      } = deviceDetail
      setDeviceInfoInputs({
        name,
        imei,
        vehiclePlatNumber,
        simCard,
        model,
        exFactoryDate,
        activatedDate,
        platformExpirationDate,
        userDue,
        icon,
        vehiclePicture,
        remark,
        updatedAt,
        deviceType,
      })

      if (installationInfo) {
        const {
          person,
          time,
          company,
          position,
          address,
          image,
          remark: remarkInstallationInfo,
        } = installationInfo

        setDeviceInstallationInfoInputs({
          person,
          time,
          company,
          position,
          address,
          image,
          remark: remarkInstallationInfo,
        })
      }

      if (otherInfo) {
        const { driverName, mobile, vin, engineNumber, color, vehicleBrand } =
          otherInfo
        setDeviceOtherInformationInputs({
          driverName,
          mobile,
          vin,
          engineNumber,
          color,
          vehicleBrand,
        })
      }

      if (fuelInfo) {
        let { consumption, tank, sensor, calibration } = fuelInfo
        if (tank?.cube) {
          tank = { ...tank, shape: "Cube" }
        } else {
          tank = { ...tank, shape: "Horizontal" }
        }

        calibration = {
          ...calibration,
          values: calibration?.values.map((val, index) => ({
            ...val,
            id: index + 1,
          })),
        }

        if (calibration?.values?.length < 7) {
          calibration = {
            ...calibration,
            values: addEmptyNRecord(
              calibration?.values,
              calibration?.values?.length + 1,
              7
            ),
          }
        }

        setFuelInformationInputs({ consumption, tank, sensor, calibration })
      }

      
if(alert){
     const {
    deviceId,
    lowTemperature,
    engineIdle,
    idleAlertMin,
    speedingAlert,
    speedingKM,
    restrictedDriving,
    engineOff,
    engineOn,
    doorClose,
    doorOpen,
    oilLeakAlert,
    offline,
    parking,
    parkingAlertMin,
    refueling,
    geoFence
     } = alert

      setDeviceAlertSettingsInputs({
        deviceId,
        lowTemperature,
        engineIdle,
        idleAlertMin,
        speedingAlert,
        speedingKM,
        restrictedDriving,
        engineOff,
        engineOn,
        doorClose,
        doorOpen,
        oilLeakAlert,
        offline,
        parking,
        parkingAlertMin,
        refueling,
        geoFence,
        idleAlertMin,
        speedingKM,
        parkingAlertMin,
      })
      }


    }
  }, [deviceDetail])

  if (isLoadingDetail) {
    return (
      <Spinner
        className={classnames({
          "position-relative": true,
          "button-style": false,
          visible: true,
          "left-50": true,
        })}
        size="lg"
        // type="grow"
      />
    )
  }

  return (
    <React.Fragment>
      <Nav tabs className="nav-tabs-custom mb-2" role="tablist">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "1",
            })}
            onClick={() => {
              toggle("1")
            }}
          >
            Device Info
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "2",
            })}
            onClick={() => {
              toggle("2")
            }}
          >
            Installation information
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "3",
            })}
            onClick={() => {
              toggle("3")
            }}
          >
            Fuel
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "4",
            })}
            onClick={() => {
              toggle("4")
            }}
          >
            Other Information
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "5",
            })}
            onClick={() => {
              toggle("5")
            }}
          >
            Alert settings
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="text-muted">
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <DeviceInfo1
                selectedDevice={selectedDevice}
                selectedUser={selectedUser}
                deviceInfoInputs={deviceInfoInputs}
                updateDeviceInfoInputs={updateDeviceInfoInputs}
                deviceOtherInformationInputs={deviceOtherInformationInputs}
                tog_model={tog_model}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <InstallationInformation
                selectedDevice={selectedDevice}
                deviceInstallationInfoInputs={deviceInstallationInfoInputs}
                updateDeviceInstallationInfoInputs={
                  updateDeviceInstallationInfoInputs
                }
                tog_model={tog_model}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              <FuelInformation
                selectedDevice={selectedDevice}
                fuelInformationInputs={fuelInformationInputs}
                updateFuelInformationInputs={updateFuelInformationInputs}
                tog_model={tog_model}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col sm="12">
              <DeviceOtherInformation
                selectedDevice={selectedDevice}
                selectedUser={selectedUser}
                deviceInfoInputs={deviceInfoInputs}
                deviceOtherInformationInputs={deviceOtherInformationInputs}
                updateDeviceOtherInformationInputs={
                  updateDeviceOtherInformationInputs
                }
                tog_model={tog_model}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="5">
          <Row>
            <Col sm="12">
              <DeviceAlertSettings
                selectedDevice={selectedDevice}
                selectedUser={selectedUser}
                deviceAlertSettingsInputs={deviceAlertSettingsInputs}
                updateDeviceAlertSettingsInputs={
                  updateDeviceAlertSettingsInputs
                }
                tog_model={tog_model}
              />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </React.Fragment>
  )
}

export default DeviceDetails
