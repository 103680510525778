import React, {useState, memo,useEffect } from "react"

const Counter = props => { 
  const [seconds, setSeconds] = useState(10)
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => {
        if (seconds === 1) {
          return setSeconds(10)
        }

        return seconds - 1
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  function refreshComponent(){
    return setSeconds(10)
  }
  return (
    <>
      <div className="refreshmap">
        Refresh after<span>{seconds}</span>seconds <span onClick={()=>{ refreshComponent() }}>Refresh</span>
      </div>
    </>
  )
}

export default memo(Counter)