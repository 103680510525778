/* eslint-disable */
import React, { useState, useEffect } from "react"

import PropTypes from "prop-types"

import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap"

import classnames from "classnames"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import PreferenceTabs from "./PreferenceTabs"
import MonitorBottomNotification from "../Monitor/MonitorBottomNotification"

import { getPreferences } from "../../store/actions"

import { useSelector, useDispatch } from "react-redux"

const INITIAL_PREFERENCE_INPUT = {
  distanceSpeedUnit: "Kilometer",
  pressureUnit: "Pounds Per Square Inch(PSI)",
  temperatureUnit: "Celsius(C)",
  volumeUnit: "Litres(L)",
  fuelMetrics: "L/100KM",
  timeZone: "+00:00",
  firstDayOfWeek: "Monday",
  engineIdle: false,
  commandPassword: true,
  enableIMEI: false,
}

const INITIAL_NOTIFICATIONALERT_INPUT = {
  isAlertNotification: 1,
  isAllDay: 1,
  fromTime: 28800,
  toTime: 79200,
  mobile: "",
  alertList: [
    {
      alertType: "SOS",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Low Battery",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "External power disconnect",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Vibration",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Geo-fence in",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Geo-fence out",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Speeding alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Towing",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Engine ON",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Engine OFF",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Tire temperature",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Tire leak pressure",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Tire low pressure",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Tire high pressure",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "GPS dead zone in",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "GPS dead zone out",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "OBD DTC code",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Remove",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Power off",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Collision alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Drop alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Route Alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Rapid acceleration alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Rapid deceleration alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Sharp turn alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Door open alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Door close alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Offline alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Engine idle alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Parking alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Refueling alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Oil leak alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Jamming Alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Restricted driving alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Low temperature alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "High temperature alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Dump Switch ON",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Dump Switch OFF",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Arm Alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Rollover Alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Fatigue driving alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
    {
      alertType: "Minimum rest alert",
      isAlert: 1,
      isEmail: 1,
      isApp: 1,
    },
  ],
  emailsList: [
    {
      email: "",
      isVerified: 0,
    },
  ],
}

const INITIAL_NOTIFICATIONSERVICE_INPUT = {
  serviceList: [
    {
      serviceType: "Expire",
      isMessage: false,
      isEmail: false,
      isApp: false,
    },
  ],
  emailsList: [
    {
      email: "",
      isVerified: false,
    },
  ],
}

function Preference(props) {
  const dispatch = useDispatch()
  const { selectedUser, tog_preferences, tog_center4 } = props
  const [preferenceInput, setPreferenceInput] = useState(
    INITIAL_PREFERENCE_INPUT
  )

  const [notificationAlertInput, setNotificationAlertInput] = useState(
    INITIAL_NOTIFICATIONALERT_INPUT
  )

  const [notificationServiceInput, setNotificationServiceInput] = useState(
    INITIAL_NOTIFICATIONSERVICE_INPUT
  )

  const updatePreferenceField = (key, value) => {
    setPreferenceInput(previousState => {
      return { ...previousState, [key]: value }
    })
  }

  const updateNotificationAlertField = (key, value) => {
    setNotificationAlertInput(previousState => {
      return { ...previousState, [key]: value }
    })
  }

  const updateNotificationServiceField = (key, value) => {
    setNotificationServiceInput(previousState => {
      return { ...previousState, [key]: value }
    })
  }

  const [activeTab, setactiveTab] = useState("1")

  const { loading, preferences } = useSelector(state => {
    return {
      loading: state.Preferences.loading,
      preferences: state.Preferences.preferences,
    }
  })

  useEffect(() => {
    if (preferences && Object.keys(preferences).length > 0) {
      const {
        notificationAlert,
        notificationService,
        preferences: preferencesValue,
      } = preferences
      setPreferenceInput(preferencesValue)
      setNotificationServiceInput(notificationService)
      setNotificationAlertInput(notificationAlert)
    }
  }, [preferences])

  useEffect(() => {
    dispatch(getPreferences(selectedUser?.id))
  }, [])

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  if (loading) {
    return (
      <Spinner
        className={classnames({
          "position-relative": true,
          "button-style": false,
          visible: true,
          "left-50": true,
        })}
        size="lg"
        // type="grow"
      />
    )
  }

  return (
    <React.Fragment>
      <Nav tabs className="nav-tabs-custom">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "1",
            })}
            onClick={() => {
              toggle("1")
            }}
          >
            Preferences
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "2",
            })}
            onClick={() => {
              toggle("2")
            }}
          >
            Notification
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className="py-2 text-muted px-0">
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              {activeTab === "1" && (
                <PreferenceTabs
                  preferenceInput={preferenceInput}
                  updatePreferenceField={updatePreferenceField}
                  notificationAlertInput={notificationAlertInput}
                  notificationServiceInput={notificationServiceInput}
                  selectedUser={selectedUser}
                  tog_preferences={tog_preferences}
                  tog_center4={tog_center4}
                  isTemplate={false}
                />
              )}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          {activeTab === "2" && (
            <MonitorBottomNotification
              preferenceInput={preferenceInput}
              notificationServiceInput={notificationServiceInput}
              notificationAlertInput={notificationAlertInput}
              updateNotificationServiceField={updateNotificationServiceField}
              updateNotificationAlertField={updateNotificationAlertField}
              selectedUser={selectedUser}
              tog_preferences={tog_preferences}
            />
          )}
        </TabPane>
      </TabContent>
    </React.Fragment>
  )
}

Preference.propTypes = {
  selectedUser: PropTypes.obj,
  tog_preferences: PropTypes.func,
  tog_center4: PropTypes.func,
}

export default Preference
