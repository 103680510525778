import {
  ADD_MESSAGE,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_ERROR,
  GET_SENT_MESSAGE,
  GET_SENT_MESSAGE_SUCCESS,
  GET_SENT_MESSAGE_ERROR,
  GET_RECEIVED_MESSAGE,
  GET_RECEIVED_MESSAGE_SUCCESS,
  GET_RECEIVED_MESSAGE_ERROR,
  DELETE_MESSAGE,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_ERROR,
  PUT_MESSAGE,
  PUT_MESSAGE_SUCCESS,
  PUT_MESSAGE_ERROR,
} from "./actionTypes"

const initialState = {
  loading: false,
  listLoading: false,
  sentMessages: {},
  receivedMessage: {},
}

const message = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_MESSAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_MESSAGE_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case GET_SENT_MESSAGE:
      state = {
        ...state,
        listLoading: true,
      }
      break
    case GET_SENT_MESSAGE_SUCCESS:
      state = {
        ...state,
        sentMessages: action.payload,
        listLoading: false,
      }
      break
    case GET_SENT_MESSAGE_ERROR:
      state = {
        ...state,
        listLoading: false,
      }
      break

    case GET_RECEIVED_MESSAGE:
      state = {
        ...state,
        listLoading: true,
      }
      break
    case GET_RECEIVED_MESSAGE_SUCCESS:
      state = {
        ...state,
        receivedMessage: action.payload,
        listLoading: false,
      }
      break
    case GET_RECEIVED_MESSAGE_ERROR:
      state = {
        ...state,
        listLoading: false,
      }
      break
    case DELETE_MESSAGE:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELETE_MESSAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case DELETE_MESSAGE_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case PUT_MESSAGE:
      state = {
        ...state,
        loading: true,
      }
      break
    case PUT_MESSAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case PUT_MESSAGE_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default message
