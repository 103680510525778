import { toast } from "react-toastify"

export const showToast = (msg, status) => {
  if (status) {
    toast.success(msg, {
      theme: "colored",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
    })
  } else {
    toast.error(msg, {
      theme: "colored",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
    })
  }
}
export const showToastPopup = (msg, status) => {
  if (status) {
    toast.success(msg, {
      theme: "colored",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    })
  } else {
    toast.error(msg, {
      theme: "colored",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    })
  }
}
export const getFileType = type => {
  if (type.startsWith("video/")) {
    return "video"
  }
  if (type.startsWith("image/")) {
    return "image"
  }
  return type
}

export const fileToBase64 = e => {
  return new Promise((resolve, reject) => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      const reader = new FileReader()

      reader.onload = function (event) {
        resolve({
          file: event.target.result,
          type: getFileType(file.type),
        })
      }

      reader.readAsDataURL(file)
    } else {
      reject(new Error("Something went wrong."))
    }
  })
}

export const bindActionToPromise = (dispatch, actionCreator) => payload => {
  return new Promise((resolve, reject) =>
    dispatch(actionCreator({ payload: payload, meta: { resolve, reject } }))
  )
}

export const TimeZoneOptionList = () => {
  return [
    { value: "-12:00", label: "UTC-12.00" },
    { value: "-11:00", label: "UTC-11.00" },
    { value: "-10:00", label: "UTC-10.00" },
    { value: "-09:30", label: "UTC-09.30" },
    { value: "-09:00", label: "UTC-09.00" },
    { value: "-08:00", label: "UTC-08.00" },
    { value: "-07:00", label: "UTC-07.00" },
    { value: "-06:00", label: "UTC-06.00" },
    { value: "-05:00", label: "UTC-05.00" },
    { value: "-04:30", label: "UTC-04.30" },
    { value: "-04:00", label: "UTC-04.00" },
    { value: "-03:30", label: "UTC-03.30" },
    { value: "-03:00", label: "UTC-03.00" },
    { value: "-02:00", label: "UTC-02.00" },
    { value: "-01:00", label: "UTC-01.00" },
    { value: "+00:00", label: "UTC+00.00" },
    { value: "+01:00", label: "UTC+01.00" },
    { value: "+02:00", label: "UTC+02.00" },
    { value: "+03:00", label: "UTC+03.00" },
    { value: "+03:30", label: "UTC+03.30" },
    { value: "+04:00", label: "UTC+04.00" },
    { value: "+04:30", label: "UTC+04.30" },
    { value: "+05:00", label: "UTC+05.00" },
    { value: "+05:30", label: "UTC+05.30" },
    { value: "+05:45", label: "UTC+05.45" },
    { value: "+06:00", label: "UTC+06.00" },
    { value: "+06:30", label: "UTC+06.30" },
    { value: "+07:00", label: "UTC+07.00" },
    { value: "+08:00", label: "UTC+08.00" },
    { value: "+08:30", label: "UTC+08.30" },
    { value: "+08:45", label: "UTC+08.45" },
    { value: "+09:00", label: "UTC+09.00" },
    { value: "+09:30", label: "UTC+09.30" },
    { value: "+10:00", label: "UTC+10.00" },
    { value: "+10:30", label: "UTC+10.30" },
    { value: "+11:00", label: "UTC+11.00" },
    { value: "+12:00", label: "UTC+12.00" },
    { value: "+12:45", label: "UTC+12.45" },
    { value: "+13:00", label: "UTC+13.00" },
    { value: "+14:00", label: "UTC+14.00" },
  ]
}
