import {
  GET_CARDS_DATA,
  GET_CARDS_DATA_FAIL,
  GET_CARDS_DATA_SUCCESS,
  ADD_CARD_DEFAULT,
  ADD_CARD_DEFAULT_FAIL,
  ADD_CARD_DEFAULT_SUCCESS,
  ADD_TRANSFER_CARD_DEFAULT,
  ADD_TRANSFER_CARD_FAIL,
  ADD_TRANSFER_CARD_SUCCESS,
  GET_DISTRIBUTOR_DATA_DEFAULT,
  GET_DISTRIBUTOR_DATA_FAIL,
  GET_DISTRIBUTOR_DATA_FAIL_SUCCESS,
  GET_CARD_COUNT_DATA_DEFAULT,
  GET_CARD_COUNT_DATA_FAIL,
  GET_CARD_COUNT_DATA_SUCCESS,
  GET_CARD_BALANCE_DATA_DEFAULT,
  GET_CARD_BALANCE_DATA_SUCCESS,
  GET_CARD_BALANCE_DATA_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  isLoading: false,
  cardsData: {},
  cardData: [],
  transferCard: {},
  distributorData: [],
  cardsCount: [],
  cardBalanceData: [],
  isCardTransferLoading: false,
}

const getAllCardData = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_DISTRIBUTOR_DATA_DEFAULT:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DISTRIBUTOR_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
      }

    case GET_DISTRIBUTOR_DATA_FAIL_SUCCESS:
      return {
        ...state,
        distributorData: action.payload,
        isLoading: false,
      }
    case GET_CARDS_DATA:
      return {
        ...state,
        isLoading: true,
      }
    case GET_CARDS_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
      }

    case GET_CARDS_DATA_SUCCESS:
      return {
        ...state,
        cardsData: action.payload,
        isLoading: false,
      }
    case GET_CARD_BALANCE_DATA_DEFAULT:
      return {
        ...state,
        isLoading: true,
      }
    case GET_CARD_BALANCE_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
      }

    case GET_CARD_BALANCE_DATA_SUCCESS:
      return {
        ...state,
        cardBalanceData: action.payload,
        isLoading: false,
      }
    case GET_CARD_COUNT_DATA_DEFAULT:
      return {
        ...state,
        isLoading: true,
      }
    case GET_CARD_COUNT_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
      }

    case GET_CARD_COUNT_DATA_SUCCESS:
      return {
        ...state,
        cardsCount: action.payload,
        isLoading: false,
      }

    case ADD_CARD_DEFAULT:
      return {
        ...state,
        isLoading: true,
      }
    case ADD_CARD_DEFAULT_SUCCESS:
      return {
        ...state,
        cardData: [...state.cardData, action.payload],
        isLoading: false,
      }

    case ADD_CARD_DEFAULT_FAIL:
      return {
        ...state,
        isLoading: false,
      }

    case ADD_TRANSFER_CARD_DEFAULT:
      return {
        ...state,
        isCardTransferLoading: true,
      }

    case ADD_TRANSFER_CARD_SUCCESS:
      return {
        ...state,
        transferCard: action.payload,
        isCardTransferLoading: false,
      }

    case ADD_TRANSFER_CARD_FAIL:
      return {
        ...state,
        isCardTransferLoading: false,
      }
    default:
      return state
  }
}

export default getAllCardData
