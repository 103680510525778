/* eslint-disable */

import React, { useState, useEffect,memo } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  Modal,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import Tooltip from "@mui/material/Tooltip"
import lefticon9 from "../../assets/images/icons/fence.svg"

//i18n
import { withTranslation } from "react-i18next"
import ImportExcelPopsApikey from "./ImportFromExcelFile"
import AddGroupGeoFence from "./AddGroupGeoFence"
import GeoFenceEditPop from "./GeoFenceEditPop"
import LinkGeoFenceInfo from "./LinkGeoFenceInfo"
import { getFenceDetails } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"



const MonitorBottomGeoFence = () => {


   const userDetails = JSON.parse(localStorage.getItem("authUser"))

  const [optionDropDown, setOptionDropDown] = useState(false)
  const [shapeSelectDropDown, setShapeSelectDropDown] = useState(false)
  const [menu, setMenu] = useState(false)
  const [toggleForGroup, setToggleForGroup] = useState(true)


  //Get geo fence data from redux 

  const {
    geoFenceDetails,
    geoFenceFetchLoading,
    GeoFenceFetchFailed,
    previousMemberId,
  } = useSelector(state => {
    return {
      geoFenceDetails: state.GeoFenceDetails.geoFenceData,
      geoFenceFetchLoading: state.GeoFenceDetails.isLoading,
      GeoFenceFetchFailed: state.GeoFenceDetails.isFailed,
      previousMemberId: state.GeoFenceDetails.GeoFenceCurrentMemberId,
    }
  })




  const dispatch = useDispatch()
  const groupToggleHandler = () => {
    setToggleForGroup(!toggleForGroup)
  }
  const [ImportExcelPop, setImportExcelPop] = useState(false)
  function ImportExcel() {
    setMenu(!menu)
    setImportExcelPop(!ImportExcelPop)
    removeBodyCss()
  }

  const [addGroup, setAddGroup] = useState(false)
  function AddGroup() {
    setMenu(!menu)
    setAddGroup(!addGroup)
    removeBodyCss()
  }

  const [GeoFenceEditOn, setGeoFenceEditOn] = useState(false)
  const GeoFenceEditBtn = () => {
    setGeoFenceEditOn(!GeoFenceEditOn)
  }

  const [LinkGeoFence, setLinkGeoFence] = useState(false)
  function LinkGeoFenceBtn() {
    setMenu(!menu)
    setLinkGeoFence(!LinkGeoFence)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  // //get Geo fence data list

  // useEffect(() => {
  //   if (!selectedUser || previousMemberId === selectedUser) return false;
  //     dispatch(
  //       getFenceDetails(selectedUser ? selectedUser : userDetails.memberId)
  //     )
  // }, [selectedUser])


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="div"
      >
        <DropdownToggle className="no-button ms-4" tag="button">
          <Tooltip title="Geo Fence" placement="top" arrow>
            <img src={lefticon9} width={30} />
          </Tooltip>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu geo_fence_row p-0">
          <div className="d-flex align-items-center bot_pop_head">
            <i className="bx bxs-map pe-2 flx_0 font-size-17"></i>
            <span className="flx_1"> Geofence</span>
            <div className="flx_0 ps-2 pt-2">
              <span
                onClick={() => setMenu(!menu)}
                className="bx bx-message-alt-minus font-size-17 cursor-pointer"
              ></span>
            </div>
          </div>
          <SimpleBar style={{ height: "360px" }}>
            <div className="poi_header bg_cus_2 p-2 d-flex align-items-center flex-row">
              <div className="flx_0 pe-2">
                <input type="checkbox" className="form-check-input" />
              </div>
              <div className="flx_1 pe-2">
                <div className="clos_input position-relative">
                  <input className="form-control poi_head_input" type="text" />
                  <span className="txt_clr d-flex align-items-center justify-content-center">
                    X
                  </span>
                </div>
              </div>
              <div className="flx_0 pe-2">
                <button
                  onClick={() => {
                    LinkGeoFenceBtn()
                  }}
                  className="btn btn-primary btn-sm cus_icon_btn"
                >
                  <i className="bx bx-bell"></i>
                </button>
              </div>
              <div className="flx_0 br_1">
                <Dropdown
                  isOpen={shapeSelectDropDown}
                  toggle={() => setShapeSelectDropDown(!shapeSelectDropDown)}
                >
                  <DropdownToggle className="btn btn-primary btn-sm cus_icon_btn ps-0 pe-0">
                    <i className="bx bx-plus"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={GeoFenceEditBtn}>
                      <i className="bx bx-circle"></i> Circle
                    </DropdownItem>
                    <DropdownItem onClick={GeoFenceEditBtn}>
                      <i className="bx bx-polygon"></i> Polygon
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="flx_0">
                <Dropdown
                  isOpen={optionDropDown}
                  toggle={() => setOptionDropDown(!optionDropDown)}
                >
                  <DropdownToggle className="btn btn-primary btn-sm cus_icon_btn ps-0 pe-0">
                    <i className="bx bx-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <span
                        className="d-block"
                        onClick={() => {
                          ImportExcel()
                        }}
                      >
                        Import from Excel file
                      </span>
                      <Modal
                        isOpen={ImportExcelPop}
                        size="lg"
                        toggle={() => {
                          ImportExcel()
                        }}
                        centered
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0">
                            Import from Excel file
                          </h5>
                          <button
                            type="button"
                            onClick={() => {
                              setMenu(true)
                              setImportExcelPop(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="mdi mdi-close"></i>
                          </button>
                        </div>
                        <ImportExcelPopsApikey />
                      </Modal>
                    </DropdownItem>
                    <DropdownItem>Clone</DropdownItem>
                    <DropdownItem>
                      <span
                        className="d-block"
                        onClick={() => {
                          AddGroup()
                        }}
                      >
                        Group
                      </span>

                      <Modal
                        isOpen={addGroup}
                        toggle={() => {
                          AddGroup()
                        }}
                        centered
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0">Group</h5>
                          <button
                            type="button"
                            onClick={() => {
                              setMenu(true)
                              setAddGroup(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="mdi mdi-close"></i>
                          </button>
                        </div>
                        <AddGroupGeoFence />
                      </Modal>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>

            <div className="route_info">
              <div className="d-flex flex-row align-items-center p-2 bg-greyl">
                <header className="Accord_head">
                  <div className="" onClick={groupToggleHandler}>
                    <i className="bx bxs-down-arrow font-size-12 me-2"></i>
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      id="customCheckcolor1"
                    />
                    Default group ({geoFenceDetails?.count || 0})
                  </div>
                </header>
              </div>
              <Collapse isOpen={toggleForGroup} className="over_auto ps-2 pe-2">
                <ul className="deviceshown poi_list_item">
                  {geoFenceDetails &&
                    geoFenceDetails?.rows?.map((geoFenceItem, index) => {
                      let typeClass = "bx bx-circle"
                      if (geoFenceItem.type==='polygon'){
                       typeClass = "bx bx-polygon"
                      }
                        return (
                          <li key={index}>
                            <div className="d-flex align-items-start w-100">
                              <div className="flx_1">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                  />
                                  <i
                                    className={`${typeClass} font-size-20 me-1`}
                                  ></i>
                                  <span className="">{geoFenceItem.name}</span>
                                </div>
                              </div>
                              <div className="flx_0 d-flex flex-row hover_poi">
                                <i
                                  onClick={() => {
                                    LinkGeoFenceBtn()
                                  }}
                                  className="bx bx-link font-size-20 me-1 cursor-pointer"
                                ></i>

                                <Modal
                                  isOpen={LinkGeoFence}
                                  size="lg"
                                  toggle={() => {
                                    LinkGeoFenceBtn()
                                  }}
                                  centered
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title mt-0">
                                      Link geo-fence with device
                                    </h5>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setLinkGeoFence(false)
                                      }}
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <i className="mdi mdi-close"></i>
                                    </button>
                                  </div>
                                  <LinkGeoFenceInfo />
                                </Modal>

                                <i
                                  onClick={GeoFenceEditBtn}
                                  className="bx bxs-pencil font-size-20 me-1 cursor-pointer"
                                ></i>
                                <i className="bx bxs-trash font-size-20 me-1 cursor-pointer"></i>
                              </div>
                            </div>
                          </li>
                        )})}
                </ul>
              </Collapse>
            </div>
          </SimpleBar>
          {GeoFenceEditOn ? <GeoFenceEditPop /> : null}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}
export default memo(withTranslation()(MonitorBottomGeoFence))
