import React, { useState, useEffect, memo } from "react"
//Import Breadcrumb
import { Popup, Tooltip, useMap } from "react-leaflet"
import PropTypes from "prop-types"
import airplaneIcon from "./arrow_head.png"
import L from "leaflet"
import "./playback.css"
import icon from 'leaflet/dist/images/marker-icon.png';

const iconData = L.icon({
    iconSize: [50, 50],
    popupAnchor: [2, -20],
    iconUrl: icon,
})

import moment from "moment"
function AddStartAndEnd({ startAndEndPoint }) {
    const map = useMap();



    useEffect(() => {
        if (!map || !startAndEndPoint || startAndEndPoint.length === 0) return;

        const markers = [];

        for (const item of startAndEndPoint) {
            // Check if lat and lng are valid numbers
            if (isNaN(item.lat) || isNaN(item.lng)) {
                console.error(`Invalid coordinates for marker: ${item.lat}, ${item.lng}`);
                continue; // Skip this marker
            }
            console.log(item)
            const marker = L.marker([item.lat, item.lng], {
                icon: iconData, // Assuming 'icon' is correctly defined
            }).bindPopup(`<div class="play-table-detail-windows"><p>Datetime: ${moment(item.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</p>         <p>Coordinate: <a href="http://map.google.com/maps?q=${item.lat},${item.lng}" target="_blank">${item.lat},${item.lng}</a></p>         <p>Speed: ${item.speed}kph</p>         <p>Course: ${item.courseStatus.course}°</p>  </div>`).addTo(map);
        }
    }, [map]);

    return null;
}
AddStartAndEnd.propTypes = {
    startAndEndPoint: PropTypes.array
}

export default memo(AddStartAndEnd)