export const ADD_DEVICE_GROUP = "ADD_DEVICE_GROUP"
export const ADD_DEVICE_GROUP_SUCCESS = "ADD_DEVICE_GROUP_SUCCESS"
export const ADD_DEVICE_GROUP_ERROR = "ADD_DEVICE_GROUP_ERROR"

export const GET_DEVICE_GROUP = "GET_DEVICE_GROUP"
export const GET_DEVICE_GROUP_SUCCESS = "GET_DEVICE_GROUP_SUCCESS"
export const GET_DEVICE_GROUP_ERROR = "GET_DEVICE_GROUP_ERROR"

export const PUT_DEVICE_GROUP = "PUT_DEVICE_GROUP"
export const PUT_DEVICE_GROUP_SUCCESS = "PUT_DEVICE_GROUP_SUCCESS"
export const PUT_DEVICE_GROUP_ERROR = "PUT_DEVICE_GROUP_ERROR"
