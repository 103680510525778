/* eslint-disable */

import React from 'react'
import MetaTags from 'react-meta-tags';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

const PlayBackDetailsTab = () => {
  return (
    <div>
        <div className="table-responsive" style={{"height":"135px", "overflow":"auto"}}>
                    <Table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>#</th>
                          <th>Datetime</th>
                          <th>Longitude</th>
                          <th>Latitude</th>
                          <th>Speed(kph)</th>
                          <th>Course</th>
                          <th>Location  <span> <input type="checkbox" className='me-2 ms-2' name="" id="" /> Get all address  </span></th>
                        </tr>
                      </thead>
                      <tbody>
                       
                      </tbody>
                    </Table>
                  </div>
    </div>
  )
}

export default PlayBackDetailsTab