/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects"

import { SET_SELECTED_DEVICE_INITIAL } from "./actionTypes"

import {
  setSelectedDevicePopupSuccess,
  setSelectedDevicePopupFail,
} from "./actions"

function* SetSelectedDeviceMap({ selectedData }) {
  try {
    yield put(setSelectedDevicePopupSuccess(selectedData))
  } catch (error) {
    yield put(setSelectedDevicePopupFail({error}))
  }
}


function* MapSelectedSaga() {
  yield takeEvery(SET_SELECTED_DEVICE_INITIAL, SetSelectedDeviceMap)
}

export default MapSelectedSaga
