/* eslint-disable */

import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import * as yup from "yup"
import { connect } from "react-redux"

import { bindActionToPromise } from "../../utils/Common"
import Select from "react-select"

import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import LoadingButton from "components/Common/Button"

import { addCardDefault } from "../../store/actions"

const INITIAL_VALUES = {
  name: "New Card",
  price: null,
  cardCount: 1,
}

const CreateCard = props => {
  const [cardData, setCardData] = useState(INITIAL_VALUES)

  const { loading } = useSelector(state => {
    return {
      loading: state.GetCardsDataReducer.isLoading,
    }
  })

  const optionsCard = [
    { value: "New Card", label: "New Card" },
    { value: "Lifelong Card", label: "Lifelong Card" },
    { value: "Yearly Card", label: "Yearly Card" },
  ]

  const onChangeInput = (key, value) => {
    setCardData(previousCard => ({ ...previousCard, [key]: value }))
  }

  const incNum = () => {
    if (cardData.cardCount < 1000) {
      onChangeInput("cardCount", Number(cardData.cardCount) + 1)
    }
  }

  const decNum = () => {
    if (cardData.cardCount > 1) {
      onChangeInput("cardCount", cardData.cardCount - 1)
    }
  }

  const submitForm = () => {
    const payload = {
      name: cardData.name,
      price: cardData.price,
      cardCount: cardData.cardCount,
      isNew: cardData.name === "New Card",
      validity: cardData.name === "Lifelong Card" ? undefined : 12,
    }

    props.addCardDefaultAction(payload).then(() => {
      setCardData(INITIAL_VALUES)
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Business</title>
        </MetaTags>
        <div>
          <Container fluid>
            <Row className="mt-3">
              <div className="col-md-12">
                <Card className="mb-3">
                  <CardBody className="d-flex align-items-center justify-content-between p-2">
                    <div className="page-title-box ps-3">
                      <h3 className="mb-0 font-size-16 d-flex align-items-center">
                        Create card
                      </h3>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Container>

          <Container fluid>
            <Row>
              <div className="col-md-12">
                <Card>
                  <CardBody className="d-flex align-items-center justify-content-between px-3 py-4">
                    <div className="crypto-buy-sell-nav w-100">
                      <div className="row mb-3">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                        >
                          Card Type<b>*</b>
                        </Label>
                        <Col className="col-xl-4 col-lg-6 col-sm-6">
                          <Select
                            classNamePrefix="select2-selection"
                            placeholder="Please Select"
                            title="name"
                            name="name"
                            isClearable={false}
                            options={optionsCard}
                            isSearchable={false}
                            onChange={selectedOption =>
                              onChangeInput("name", selectedOption?.value)
                            }
                            value={optionsCard.filter(option => {
                              if (option.value == cardData.name) return option
                            })}
                          />
                        </Col>
                      </div>
                      <div className="row mb-3">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                        >
                          Card Count
                        </Label>
                        <Col className="col-xl-4 col-lg-6 col-sm-6">
                          <div className="col-xl-12">
                            <div className="input-group card-inc">
                              <div className="input-group-prepend">
                                <button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={() => decNum()}
                                >
                                  -
                                </button>
                              </div>
                              <Input
                                type="text"
                                className="form-control text-center"
                                name="cardCount"
                                value={cardData.cardCount}
                                onChange={e => {
                                  e.target.value > 0 &&
                                    e.target.value < 1000 &&
                                    onChangeInput("cardCount", e.target.value)
                                }}
                              />
                              <div className="input-group-prepend">
                                <button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={() => incNum()}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                      <div className="row mb-3">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                        >
                          Card Price
                        </Label>
                        <Col className="col-xl-4 col-lg-6 col-sm-6">
                          <Input
                            className="form-control"
                            type="number"
                            name="price"
                            value={
                              cardData.price === null ? "" : cardData.price
                            }
                            onChange={e => {
                              e.target.value > 0 &&
                                onChangeInput("price", e.target.value)
                            }}
                            min={0}
                          />
                        </Col>
                      </div>

                      <div className="row mb-3">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                        ></Label>
                        <Col className="col-xl-4 col-lg-6 col-sm-6">
                          <LoadingButton
                            color="primary"
                            className="btn btn-primary btn-right"
                            loading={loading}
                            disabled={loading}
                            block={false}
                            onClick={() => submitForm()}
                          >
                            {" "}
                            Submit
                          </LoadingButton>
                          <Button
                            type="reset"
                            color="secondary"
                            className="btnwid"
                            outline
                            onClick={() => setCardData(INITIAL_VALUES)}
                          >
                            Clear
                          </Button>
                        </Col>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  addCardDefaultAction: bindActionToPromise(dispatch, addCardDefault),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateCard)
