import {
  CHECK_IME_INITIAL,
  CHECK_IME_FAIL,
  CHECK_IME_SUCCESS,
  POST_SALE_INITIAL,
  POST_SALE_FAIL,
  POST_SALE_SUCCESS,
  POST_MOVE_ACCOUNT,
  POST_MOVE_ACCOUNT_FAIL,
  POST_MOVE_ACCOUNT_SUCCESS,
} from "./actionTypes"

//Check IMEI
export const checkImeNumber = payload => ({
  type: CHECK_IME_INITIAL,
  payload,
})

export const checkImeNumberSuccess = imeNumberDetails => ({
  type: CHECK_IME_SUCCESS,
  payload: imeNumberDetails,
})
export const checkImeNumberFail = error => ({
  type: CHECK_IME_FAIL,
  payload: error,
})

//ADD SALES
export const addSales = payload => ({
  type: POST_SALE_INITIAL,
  payload,
})

export const addSalesSuccess = saleDetails => ({
  type: POST_SALE_SUCCESS,
  payload: saleDetails,
})
export const addSalesFail = error => ({
  type: POST_SALE_FAIL,
  payload: error,
})

//ADD MOVE ACCOUNT
export const moveAccountDefault = payload => ({
  type: POST_MOVE_ACCOUNT,
  payload,
})

export const moveAccountDefaultSuccess = moveDetails => ({
  type: POST_MOVE_ACCOUNT_SUCCESS,
  payload: moveDetails,
})
export const moveAccountDefaultFail = error => ({
  type: POST_MOVE_ACCOUNT_FAIL,
  payload: error,
})
