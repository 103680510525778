/* eslint-disable */

import React, { useState } from "react";
import MetaTags from "react-meta-tags"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Container,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames";

import ExchangeLifeCardHistory from "./ExchangeLifeCardHistory"

import "./Card.css"

function TransferCard() {
   
  const [activeTab, setactiveTab] = useState("1");
  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  let [num, setNum]= useState(0);
    let incNum =()=>{
      if(num<1000)
      {
      setNum(Number(num)+1);
      }
    };
    let decNum = () => {
       if(num>0)
       {
        setNum(num - 1);
       }
    }
   let handleChange = (e)=>{
     setNum(e.target.value);
    }




  return (
    <React.Fragment>
         <div className="page-content">
        <MetaTags>
          <title>Exchange Lifelong Card</title>
        </MetaTags>
    <div>
    <Container fluid>
            <Row className="mt-3">
              <Card className="mb-3">
                <CardBody className="d-flex align-items-center justify-content-between p-2">
                  <div className="page-title-box">
                    <h3 className="mb-0 font-size-16 d-flex align-items-center">
                    Exchange Lifelong Card
                    </h3>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>

          <Container fluid>
            <Row>
              <Card>
                <CardBody className="px-2 py-3">
                <Nav tabs className="nav-tabs-custom">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Exchange Lifelong Card
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        History
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row className="mt-2">
                        <Col sm="12">
                        <div className="crypto-buy-sell-nav w-100">



 <div className="row mb-3">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                      >
                        Target user<b>*</b>
                      </Label>
                      <Col  className="col-xl-4 col-lg-6 col-sm-6">
                      <select
                      defaultValue="1"
                      className="form-select"
                      name="users"
                    >
                      <option value="0">Select</option>
                      <option value="User1">User1</option>
                      
                    </select>
                      </Col>
                    </div>


                    <div className="row mb-3">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                      >
                        Card balance
                      </Label>
                      <Col  className="col-xl-4 col-lg-6 col-sm-6">

                      <div className="row mb-3">
                      <Col sm={6} className="text-center">
                        <h5>5</h5>
                        <span>New card to lifelong</span>
                      </Col>
                      <Col sm={6} className="text-center">
                      <h5>5</h5>
                      <span>Lifelong card</span>
                      </Col>
                    </div>

                      </Col>
                    </div>



                    <div className="row mb-3">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                      >
                        Card balance
                      </Label>
                      <Col  className="col-xl-4 col-lg-6 col-sm-6">
                      <div className="mt-2">
                        <div className="form-check mb-3 me-3 d-lock">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value="option1"
                            defaultChecked
                          />
                          <label
                            className="form-check-label formlab"
                            htmlFor="exampleRadios1"
                          >
                           <span className="bg-light text-dark p-1">New card to lifelong</span> <i className=" bx bx-cookie"></i>  <span className="bg-light text-dark p-1">Lifelong card</span>
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios2"
                            value="option2"
                          />
                          <label
                            className="form-check-label formlab"
                            htmlFor="exampleRadios2"
                          >
                           
                            <span className="bg-light text-dark p-1"> Lifelong Card</span> <i className=" bx bx-cookie"></i>  <span className="bg-light text-dark p-1">New card to lifelong</span>
                          </label>
                        </div>
                      </div>
                      </Col>
                    </div>


                    <div className="row mb-3">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                      >
                       Quantity <b>*</b>
                      </Label>
                      <Col  className="col-xl-4 col-lg-6 col-sm-6">
                      <div className="col-xl-12">
    <div className="input-group">
  <div className="input-group-prepend">
    <button className="btn btn-secondary" type="button" onClick={decNum}>-</button>
  </div>
  <input type="text" className="form-control text-center" value={num} onChange={handleChange}/>
  <div className="input-group-prepend">
    <button className="btn btn-secondary" type="button" onClick={incNum}>+</button>
  </div>
</div>
</div>
                      </Col>
                    </div>


                   


                    <div className="row mb-3">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                      >
                      Remark
                      </Label>
                      <Col  className="col-xl-4 col-lg-6 col-sm-6">
                      <textarea rows="4" className="form-control"></textarea>
                      </Col>
                    </div>

                    <div className="row mb-3">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                      >
                     
                      </Label>
                      <Col  className="col-xl-4 col-lg-6 col-sm-6">
                      <Button type="submit" color="primary" className="me-3 btnwid">
                  Submit
                </Button>
                <Button
                  type="reset"
                  color="secondary"
                  className="btnwid"
                  outline
                >
                  Clear
                </Button>
                      </Col>
                    </div>
                   

                  </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                          <ExchangeLifeCardHistory />
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>

                  </TabContent>
                </CardBody>
              </Card>
            </Row>
          </Container>


    </div>
        </div>
    </React.Fragment>
  )
}

export default TransferCard
