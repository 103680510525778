import { TreeSelect } from "antd"
import React, { useState } from "react"
import usicons1 from "../../assets/images/user.svg"
import usicons2 from "../../assets/images/user2.svg"
import usicons3 from "../../assets/images/user3.svg"
import usicons4 from "../../assets/images/user4.svg"
import PropTypes from "prop-types"
import TreeRightMonitor from "./TreeRightMoniotor"
import { useSelector, useDispatch } from "react-redux"
import { getMonitorDetails } from "../../store/actions"
import axios from "axios"
import { Modal } from "reactstrap"
//Popup operations


import Information from "../../components/Info/Information"







const TreeMenuSelect = () => {
  const dispatch = useDispatch()
  
  const [value, setValue] = useState()
  const userDetails = JSON.parse(localStorage.getItem("authUser"))
  const [treeLine, setTreeLine] = useState(true)
  const [showLeafIcon, setShowLeafIcon] = useState(false)
  const [selectedNode, setSelectedNode] = useState({})
  const [commonPopUpOpen, setCommonPopUpOpen] = useState(false)
  //functions for model popup
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  const popUpHandler = popUpId => {
    setCommonPopUpOpen(!commonPopUpOpen)
    removeBodyCss()
  }

  const handlePopUpClose = (status) => {
    setCommonPopUpOpen(status)
  }

  const [treeData, setTreeData] = useState([
    {
      id: userDetails.memberId,
      pId: 0,
      value: userDetails.memberId,
      title: (
        <>
          <div>{userDetails.member.name}</div>
          <div className="ripos">
            <TreeRightMonitor userInfo={{}} popUpHandler={popUpHandler} />
          </div>
        </>
      ),
      icon: <img src={usicons1} />,
    },
  ])

  const onChange = (value, label, extra) => { }

  const onSelect = (value, node, extra) => {
    setSelectedNode(node);
    dispatch(getMonitorDetails(node.id))
  }



  const genTreeNode = async parentId => {
    const random = Math.random().toString(36).substring(2, 6)
    const axconfig = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/Member/MenuTree/SubUser/${parentId}`,
      headers: {
        accept: "/",
        Authorization: `Bearer ${userDetails.accessToken}`,
      },
    }

    const response = await axios(axconfig)

    response.data.rows.forEach(userDetails => {
      let leafstatus
      delete userDetails.key
      if (userDetails.childMemberCount === "0") {
        leafstatus = true
      } else {
        leafstatus = false
      }

      if (userDetails.role === "end_user") {
        userDetails.icon = <img src={usicons1} />
      } else if (userDetails.role === "distributor") {
        userDetails.icon = <img src={usicons1} />
      } else if (userDetails.role === "virtual") {
        userDetails.icon = <img src={usicons2} />
      }
      userDetails.id = userDetails.id
      userDetails.pId = parentId
      userDetails.value = `${userDetails.id}`
      userDetails.title = (
        <>
          <div>{userDetails.name}</div>
          <div className="ripos">
            <TreeRightMonitor userInfo={{}} popUpHandler={popUpHandler} />
          </div>
        </>
      )
      userDetails.isLeaf = leafstatus
    })

    return response.data.rows
  }

  const onLoadData = ({ id }) =>
    new Promise(resolve => {
      setTimeout(async () => {
        setTreeData(treeData.concat(await genTreeNode(id)))
        resolve(undefined)
      }, 300)
    })

  return (<>
    <TreeSelect
      style={{ width: "100%" }}
      treeDataSimpleMode
      placeholder="Search"
      dropdownStyle={{ maxHeight: 100, overflow: "auto", position: "relative" }}
      onSelect={onSelect}
      onChange={onChange}
      loadData={onLoadData}
      treeData={treeData}
      // status="warning"
      allowClear
      defaultOpen={true}
      bordered={false}
      treeIcon={true}
      defaultValue="Select"
      getPopupContainer={trigger => trigger.parentNode}
      open={true}
      virtual={false}
      showSearch={true}
      //showIcon={true}
      treeLine={
        treeLine && {
          showLeafIcon,
        }
      }
      treeNodeFilterProp="name"
      // showLeafIcon={false}
      treeDefaultExpandAll={true}
      filterTreeNode={(search, item) => {
        return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
      }}
    />

    <Modal
      size="lg"
      isOpen={commonPopUpOpen}
      toggle={() => {
        popUpHandler("none")
      }}
      centered
    >   <div className="modal-header">
        <h5 className="modal-title mt-0">Informations</h5>
        <button
          type="button"
          onClick={() => {
            setCommonPopUpOpen(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="mdi mdi-close"></i>
        </button>
      </div>
      <div className="modal-body">
        <Information
          userDetail={selectedNode}
          updatePopupClose={handlePopUpClose}
        />
      </div>
    </Modal>
  </>
  )
}


export default TreeMenuSelect
