import {
  GET_PLAYBACK_HISTORY_INITIAL,
  GET_PLAYBACK_HISTORY_DETAILS_FAIL,
  GET_PLAYBACK_HISTORY_DETAILS_SUCCESS,
} from "./actionTypes"

export const getPlayBackHistoryDetails = data => ({
  type: GET_PLAYBACK_HISTORY_INITIAL,
  data,
})

export const getPlayBackHistoryDetailsSuccess = PlaybackHistoryDetails => ({
  type: GET_PLAYBACK_HISTORY_DETAILS_SUCCESS,
  payload: PlaybackHistoryDetails,
})
export const getPlayBackHistoryDetailsFail = error => ({
  type: GET_PLAYBACK_HISTORY_DETAILS_FAIL,
  payload: error,
})
