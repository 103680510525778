/* eslint-disable */

import React, { Component, useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Spinner,
} from "reactstrap"
import Tooltip from "@mui/material/Tooltip"
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"
import PlayBackSpeedChart from "./PlayBackSpeedChart"
import PlayBackDetailsTab from "./PlayBackDetailsTab"
import PlayBackTripTab from "./PlayBackTripTab"

import icons1 from "../../../assets/images/icons/map-street.jpg"

const GroupAdd = () => {
  const [inputList, setInputList] = useState([{ groupName: "" }])

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { groupName: "" }])
  }
  return (
    <div>
      {inputList.map((x, i) => {
        return (
          <div className="box row mb-3">
            <div className="col-md-6">
              <input
                name="groupName"
                placeholder="Add Group Name"
                value={x.groupName}
                onChange={e => handleInputChange(e, i)}
                className="form-control w-100"
              />
            </div>
            <div className="col-md-6">
              <div className="btn-box">
                {inputList.length - 1 === i && (
                  <button
                    className="btn btn-primary me-2"
                    onClick={handleAddClick}
                  >
                    <i className="bx bx-plus-medical"></i>
                  </button>
                )}
                {inputList.length !== 1 && (
                  <button
                    className="btn btn-danger"
                    onClick={() => handleRemoveClick(i)}
                  >
                    <i className="bx bx-trash-alt"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        )
      })}
      <button type="button" class="btn btn-primary">
        Submit
      </button>
      {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
    </div>
  )
}

export default GroupAdd
