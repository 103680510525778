import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import {
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Tooltip,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Spinner,
  Badge,
  UncontrolledPopover,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const UiGeneral = () => {

  const [popoverright, setpopoverright] = useState(false);




  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>General | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="UI Elements" breadcrumbItem="General" />


          <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>Popovers</CardTitle>
                  <CardSubtitle className="mb-3">
                    Add small overlay content, like those found in iOS, to any
                    element for housing secondary information.
                  </CardSubtitle>

                  <div className="d-flex flex-wrap gap-2">
                   
                    <Button
                      id="Popoverright"
                      onClick={() => {
                        setpopoverright(!popoverright);
                      }}
                      color="secondary"
                    >
                      Popover on right
                    </Button>
                    <Popover
                      placement="right"
                      isOpen={popoverright}
                      target="Popoverright"
                      trigger="hover"
                      toggle={() => {
                        setpopoverright(!popoverright);
                      }}
                    >
                      <PopoverHeader>Popover Title right</PopoverHeader>
                      <PopoverBody>
                        Sed posuere consectetur est at lobortis. Aenean eu leo
                        quam. Pellentesque ornare sem lacinia quam venenatis
                        vestibulum.
                      </PopoverBody>
                    </Popover>
                    
                  </div>
                </CardBody>
              </Card>
            </Col>

        

        </Container>
      </div>
    </React.Fragment>
  );
};

export default UiGeneral;
