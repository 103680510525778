import React, { useState, useEffect, memo } from "react"
//Import Breadcrumb
import { Popup, Tooltip, useMap } from "react-leaflet"
import PropTypes from "prop-types"
import airplaneIcon from "./arrow_head.png"
import L from "leaflet"
//import 'leaflet-polylinedecorator';
const icon = L.icon({
    iconSize: [50, 50],
    popupAnchor: [2, -20],
    iconUrl: airplaneIcon,
})

const PolylineDecorator=({ polyline }) =>{
    const map = useMap()


    useEffect(() => {
        if (!map) return

        const polyline1 = L.polyline(polyline, {
            color: "#7fff00",
            weight: 8,
            lineCap: "round",
            bubblingMouseEvents: true,
        }).addTo(map)
        map.fitBounds(polyline1.getBounds())
        // L.polylineDecorator(polyline, {
        // patterns: [
        //     {
        //       repeat: 30,
        //       symbol: L.Symbol.arrowHead({
        //         pixelSize: 5,
        //         headAngle: 100,
        //         polygon: false,
        //         pathOptions: {
        //           stroke: true,
        //           weight: 1,
        //           color: "#ffffff"
        //         }
        //       })
        //     }
        //   ],
        // }).addTo(map)
    }, [map])

    return null
}

PolylineDecorator.propTypes = {
    polyline: PropTypes.array
}

export default memo(PolylineDecorator)