/* eslint-disable */
import { takeEvery, fork, put, all, call, delay } from "redux-saga/effects"

import {
  ADD_MESSAGE,
  GET_SENT_MESSAGE,
  GET_RECEIVED_MESSAGE,
  DELETE_MESSAGE,
  PUT_MESSAGE,
} from "./actionTypes"
import {
  addMessageSuccess,
  addMessageError,
  getSentMessagesSuccess,
  getSentMessagesFail,
  getReceivedMessagesSuccess,
  getReceivedMessagesFail,
  deleteMessageSuccess,
  deleteMessageFail,
  putMessageSuccess,
  putMessageFail,
} from "./actions"

import {
  addMessage,
  getSentMessage,
  getReceivedMessage,
  deleteMessage,
  putMessage,
} from "../../helpers/fakebackend_helper"

import { showToast } from "../../utils/Common"

function* addMessageSaga({ payload }) {
  try {
    const {
      payload: data,
      meta: { resolve, reject },
    } = payload

    const response = yield call(addMessage, {
      ...data,
    })
    if (response) {
      yield put(addMessageSuccess())
      yield call(resolve, response)
      showToast("Message Send Successfully!!", true)
    } else {
      yield put(addMessageError())
      yield call(reject, response)
      showToast("Something Want Wrong! Please Try Again.", false)
    }
  } catch (error) {
    yield put(addMessageError())
    showToast(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again.",
      false
    )
    yield call(reject, error?.response?.data?.message)
  }
}

function* fetchSentMessage({ payload }) {
  try {
    const response = yield call(getSentMessage, payload)
    yield put(getSentMessagesSuccess(response))
  } catch (error) {
    yield put(getSentMessagesFail(error))
  }
}

function* fetchReceivedMessage({ payload }) {
  try {
    const response = yield call(getReceivedMessage, payload)
    yield put(getReceivedMessagesSuccess(response))
  } catch (error) {
    yield put(getReceivedMessagesFail(error))
  }
}

function* deleteMessageSaga({ payload }) {
  const {
    payload: data,
    meta: { resolve, reject },
  } = payload

  try {
    const response = yield call(deleteMessage, data)
    yield put(deleteMessageSuccess(response))
    yield call(resolve, response)
    showToast("Message Deleted Successfully!!", true)
  } catch (error) {
    yield put(deleteMessageFail())
    yield call(reject, error)
    showToast(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again.",
      false
    )
  }
}

function* putMessageSaga({ payload }) {
  const {
    payload: data,
    meta: { resolve, reject },
  } = payload
  try {
    const response = yield call(putMessage, data)
    yield put(putMessageSuccess(response))
    yield call(resolve, response)
  } catch (error) {
    yield put(putMessageFail())
    yield call(reject, error)
    showToast(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again.",
      false
    )
  }
}

export function* watchMessage() {
  yield takeEvery(ADD_MESSAGE, addMessageSaga)
  yield takeEvery(GET_SENT_MESSAGE, fetchSentMessage)
  yield takeEvery(GET_RECEIVED_MESSAGE, fetchReceivedMessage)
  yield takeEvery(DELETE_MESSAGE, deleteMessageSaga)
  yield takeEvery(PUT_MESSAGE, putMessageSaga)
}

function* messageSaga() {
  yield all([fork(watchMessage)])
}

export default messageSaga
