/* eslint-disable */
import React from "react"
import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
import { CarryOutOutlined, FormOutlined } from "@ant-design/icons"
import usicons1 from "../assets/images/user.svg"
import usicons2 from "../assets/images/user2.svg"
import usicons3 from "../assets/images/user3.svg"
import usicons4 from "../assets/images/user4.svg"
import _ from "lodash"
import { renderSwitch } from "../utils/vehicleStatus"
//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL

export const apiConfig = (formdata = false) => {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  if (formdata && authUser.accessToken) {
    return {
      headers: {
        Authorization: authUser?.accessToken,
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,PATCH,DELETE",
      },
    }
  }
  if (authUser.accessToken) {
    return {
      headers: {
        Authorization: authUser?.accessToken,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,PATCH,DELETE",
      },
    }
  }

  return {}
}

const getParamsGenerator = apiVal => {
  let paramStr = ""
  if (apiVal) {
    paramStr = Object.keys(apiVal)
      .map(function (k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(apiVal[k])
      })
      .join("&")

    if (paramStr && paramStr !== "") paramStr = "?" + paramStr
  }
  return paramStr
}

// const axiosApi = axios.create({
//   baseURL: API_URL,
//   headers: {
//     Authorization: JSON.parse(localStorage.getItem("authUser")).accessToken,
//     "Content-Type": "application/json",
//   },
// })

// axiosApi.defaults.headers.common["Authorization"] = JSON.parse(
//   localStorage.getItem("authUser")
// ).accessToken

// axiosApi.interceptors.response.use(
//   response => response,
//   error => Promise.reject(error)
// )

export async function get(url, config = {}) {
  return await axios.get(`${API_URL}${url}`, apiConfig()).then(response => {
    return response.data
  })
}
export async function getPlayBackDetails(url, config = {}) {
  return await axios.get(`${API_URL}${url}`, apiConfig()).then(response => {
    return response.data
  })
}

//inba created

export async function getMonitorDetailsNew(url, selectedData,config = {}) {
  return await axios.get(`${API_URL}${url}`, apiConfig()).then(response => {
 let otherStatusDetails = []
    const device = response.data
   otherStatusDetails = device.rows.reduce((acc, obj,i) => {
    obj.indexValue = i
    const statusCarChange = renderSwitch(obj)
    obj.carIcon = `car${statusCarChange.icnStatus}top`
     const key = obj.isOnline
     if (!acc[key]) {
       acc[key] = []
     }
     acc[key].push(obj)
     return acc
   }, {})

  //  let finalDeviceData = _.map(device, (n,i)=> {
  //   n.index
  //    return n * n
  //  })
 
   device.statusList=otherStatusDetails;
   device.selectedData=selectedData;
  

    return device
  })
}

export async function getMonitor(urls, config = {}) {

  try {
    if (urls.length === 0) {
      return []
    }
    const promiseArrayUrl = urls.map(url =>
      axios.get(`${API_URL}${url}`, apiConfig())
    )
    const result = (await Promise.all(promiseArrayUrl)).map(
      response => response.data.rows
    )
    return result
  } catch (error) {
    throw new Error(error)
  }


}

export async function getTrack(urls, config = {}) {

  try {
    if (urls.length === 0) {
      return []
    }
    const promiseArrayUrl = urls.map(url =>
      axios.get(`${API_URL}${url}`, apiConfig())
    )
    const result = (await Promise.all(promiseArrayUrl)).map(
      response => response.data
    )
    return result
  } catch (error) {
    throw new Error(error)
  }

}
export async function getTreeMenuDetails(url, parentId, config = {}) {
  return await axios.get(`${API_URL}${url}`, apiConfig()).then(response => {
    response.data.rows.forEach(userDetails => {
      let leafstatus
      delete userDetails.key
      if (userDetails.childMemberCount === "0") {
        leafstatus = true
      } else {
        leafstatus = false
      }

      if (userDetails.role === "end_user") {
        userDetails.icon = <img src={usicons1} />
      } else if (userDetails.role === "distributor") {
        userDetails.icon = <img src={usicons2} />
      } else if (userDetails.role === "virtual") {
        userDetails.icon = <img src={usicons2} />
      }

      userDetails.pId = parentId
      userDetails.value = `${userDetails.id}`
      userDetails.title = `${userDetails.name}`
      userDetails.isLeaf = leafstatus
    })

    return response.data.rows
  })
}

export async function post(url, data, config = {}) {
  return await axios
    .post(`${API_URL}${url}`, { ...data }, apiConfig())
    .then(response => response.data)
}

export async function postLogin(url, data, config = {}) {
  return axios
    .post(`${API_URL}${url}`, { ...data })
    .then(response => response.data)
}

export async function postForgotPass(url, data, config = {}) {
  return axios
    .put(`${API_URL}${url}`, { ...data })
    .then(response => response.data)
}

export async function putChangePass(url, data, config = {}) {
  return axios
    .put(
      `${API_URL}${url}?memberId=${data.memberId}`,
      {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      },
      apiConfig()
    )
    .then(response => response.data)
}

export async function putChangePassOfMember(url, data, config = {}) {
  return axios
    .put(
      `${API_URL}${url}/${data.memberId}`,
      {
        password: data.password,
      },
      apiConfig()
    )
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axios
    .put(`${API_URL}${url}`, { ...data }, apiConfig())
    .then(response => response.data)
}

export async function putById(url, data, config = {}) {
  return axios
    .put(`${API_URL}${url}/${data.id}`, { ...data.userData }, apiConfig())
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axios
    .delete(`${API_URL}${url}`, apiConfig())
    .then(response => response.data)
}

export async function uploadIamages(url, data) {
  const formData = new FormData()
  for (let i = 0; i < data.images.length; i++) {
    formData.append("file", data.images[i].file)
  }
  return await axios
    .post(`${API_URL}${url}`, formData, apiConfig(true))
    .then(response => {
      return response.data
    })
}

export async function getWithPayload(url, { payload }) {
  return await axios
    .get(`${API_URL}${url}${getParamsGenerator({ ...payload })}`, apiConfig())
    .then(response => {
      return response.data
    })
}

export async function deleteWithPayload(url, payload) {
  return await axios
    .delete(`${API_URL}${url}`, { ...apiConfig(), data: { ...payload } })
    .then(response => response.data)
}
