import React, { useState } from "react"
import PropTypes from "prop-types"
/* eslint-disable */
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import classnames from "classnames"
import NotificationAlert from "./Notification/NotificationAlert"
import NotificationService from "./Notification/NotificationService"

const MonitorBottomNotification = props => {
  const [activeTab, setActiveTab] = useState("1")
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <div className="crypto-buy-sell-nav w-100">
        <Nav tabs className="nav-tabs-custom mb-3" role="tablist">
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggleTab("1")
              }}
            >
              Alert
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggleTab("2")
              }}
            >
              Services
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent
          activeTab={activeTab}
          className="crypto-buy-sell-nav-content border-0"
        >
          <TabPane tabId="1" id="buy">
            <div className="ps-3 pb-3 pe-3">
              {activeTab === "1" && <NotificationAlert {...props} />}
            </div>
          </TabPane>

          <TabPane tabId="2">
            <div className="ps-3 pb-3 pe-3">
              {activeTab === "2" && <NotificationService {...props} />}
            </div>
          </TabPane>
        </TabContent>
      </div>
    </React.Fragment>
  )
}

MonitorBottomNotification.propTypes = {
  //preferenceInput: PropTypes.obj,
  //notificationAlertInput: PropTypes.obj,
 // notificationServiceInput: PropTypes.obj,
  updateNotificationServiceField: PropTypes.func,
  updateNotificationAlertField: PropTypes.func,
  //selectedUser: PropTypes.obj,
  tog_preferences: PropTypes.func,
}

export default MonitorBottomNotification
