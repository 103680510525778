import { combineReducers } from "redux"
// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import ChangePassword from "./auth/changepwd/reducer"
import Profile from "./auth/profile/reducer"

import Dashboard from "./dashboard/reducer"
import AddUser from "./addUser/reducer"
import AddDevice from "./addDevice/reducer"
import Device from "./device/reducer"
import TreeMenu from "./treeMenu/reducer"
import Message from "./message/reducer"
import Preferences from "./preferences/reducer"
import DeviceReducer from "./addDevice/reducer"
import GetCardsDataReducer from "./getAllCards/reducer"

import MonitorDetails from "./monitor/reducer"

import DeviceGroup from "./deviceGroup/reducer"
import DeviceListSelectedPopup from "./monitor/deviceList/reducer"
import TrackPageDetails from "./monitor/track/reducer"
import Reports from "./reports/reducer"
import PlayBackDetails from "./monitor/playback/reducer"
import playBackHistoryDetails from "./monitor/playBackHistory/reducer"
import SharedDetails from "./sharedPopUp/reducer"
import GeoFenceDetails from "./geoFence/reducer"
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  ChangePassword,
  Profile,
  Dashboard,
  AddUser,
  AddDevice,
  Device,
  TreeMenu,
  GetCardsDataReducer,
  Message,
  MonitorDetails,
  Preferences,
  DeviceReducer,
  DeviceGroup,
  DeviceListSelectedPopup,
  TrackPageDetails,
  Reports,
  PlayBackDetails,
  playBackHistoryDetails,
  SharedDetails,
  GeoFenceDetails,
})

export default (state, action) => {
  return rootReducer(action.type === "LOGOUT_USER" ? undefined : state, action)
}
