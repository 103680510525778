export const GET_USER_DETAIL = "GET_USER_DETAIL"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const GET_USER_DETAIL_ERROR = "GET_USER_DETAIL_ERROR"

export const ADD_USERS = "ADD_USERS"
export const ADD_USERS_SUCCESS = "ADD_USERS_SUCCESS"
export const ADD_USERS_ERROR = "ADD_USERS_ERROR"

export const UPDATE_USERS = "UPDATE_USERS"
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS"
export const UPDATE_USERS_ERROR = "UPDATE_USERS_ERROR"

export const CHANGE_PASSWORD_MEMBER = "CHANGE_PASSWORD_MEMBER"
export const CHANGE_PASSWORD_MEMBER_SUCCESS = "CHANGE_PASSWORD_MEMBER_SUCCESS"
export const CHANGE_PASSWORD_MEMBER_ERROR = "CHANGE_PASSWORD_MEMBER_ERROR"

export const SEARCH_USER_DEFAULT = "SEARCH_USER_DEFAULT"
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS"
export const SEARCH_USER_ERROR = "SEARCH_USER_ERROR"