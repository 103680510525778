/* eslint-disable */

import React, { Component, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"
import SendMessagePopup from "./SendMessagePopup"
import MessageTabsInfo from "./MessageTabsInfo"
import CustomerService from "./CustomerService"
import SentMessageTab from "./SentMessageTab"

const TopMessgTab = props => {
  const { type = "1" } = props
  const [activeTab, setActiveTab] = useState(type)
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const [modal_center, setmodal_center] = useState(false)
  function tog_center() {
    setmodal_center(!modal_center)
  }
  return (
    <React.Fragment>
      <div className="crypto-buy-sell-nav w-100">
        <div className="position-relative pt-3">
          <Nav tabs className="nav-tabs-custom mb-3" role="tablist">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                Message(0)
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                Customer Service(0)
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  toggleTab("3")
                }}
              >
                Sent
              </NavLink>
            </NavItem>
          </Nav>
          <div className="position-absolute tab_right_btn">
            {/* <SendMessagePopup /> */}
            <button
              onClick={() => {
                tog_center()
              }}
              type="button"
              className="me-3 btn btn-outline-secondary"
            >
              <i className="bx bx-envelope"> </i> Send Message
            </button>
          </div>
        </div>

        <TabContent
          activeTab={activeTab}
          className="crypto-buy-sell-nav-content border-0"
        >
          <TabPane tabId="1" id="buy">
            {activeTab === "1" && <MessageTabsInfo />}
          </TabPane>

          <TabPane tabId="2">
            <CustomerService />
          </TabPane>
          <TabPane tabId="3">{activeTab === "3" && <SentMessageTab />}</TabPane>
        </TabContent>
      </div>
      <SendMessagePopup tog_center={tog_center} modal_center={modal_center} />
    </React.Fragment>
  )
}

TopMessgTab.propTypes = {
  type: PropTypes.string,
}

export default TopMessgTab
