/* eslint-disable */

import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import "./ReportStyle.css"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"

const GeoFenceAlertGrid = () => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: false,
      classes: 'columsno'
    },
    {
      dataField: "device",
      text: "Device",
      sort: false,
      classes: 'columsloca',
    },
    {
      dataField: "alerttype",
      text: "Alert type",
      sort: false,
    },
    

    {
      dataField: "alerttime",
      text: "Alert Time",
      sort: false,
    },

    {
      dataField: "gpstime",
      text: "GPS Time",
      sort: false,
    },

    {
      dataField: "speed",
      text: "Speed(kph)",
      sort: false,
    },

    {
      dataField: "address",
      text: "Address",
      sort: false,
      classes: 'columwidtsingle',
      formatter: (cellContent, address) => (
        <>
<Link className="txt_colr_1">  SMAART TOWERS, No.61,100 Feet Road,Jawahlal Nehru Salai ,Vadapalani Chennai, Tamil Nadu 600026, SH 2, Thiru Nagar Colony, NGO Colony, Vadapalani, Chennai, Tamil Nadu 600026, India</Link>
        </>
      ),
    },

  
   
  ]

  // Table Data
  const productData = [
    {
      id: "1",
    device: "Alfa",
    alerttype: "Geo-fence out(Auto20210921)",
    alerttime: "2022-05-27 19:01:37",
    gpstime: "2022-05-27 16:31:26",
    speed: "65",
    address: "",
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: productData.length },
  ]

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          

          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={columns}
            data={productData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={productData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle table-nowrap table-bordered"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>

                        <div className="text-md-right ms-auto endpush">
                        <PaginationListStandalone {...paginationProps} />
                        
                        </div>
                        
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Col>
      </Row>

      <div className="posexcelsheet">
<span className="me-2"><i className="bx  bx bxs-bulb orangecol"></i>Google Address :</span>
      <div
                          className="form-check form-switch mb-0 d-inline-block me-3"

                        >


                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitch1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customSwitch1"
                          >
                          unavailable
                          </label>
                        </div>

            <button className="btn btn-success btn-sm me-3 pt-1 pb-1 cursor-pointer">
              Excel
            </button>
           
            <button className="btn btn-info btn-sm pt-1 pb-1 cursor-pointer">
              Print
            </button>
          </div>


    </React.Fragment>
  )
}

export default GeoFenceAlertGrid
