/* eslint-disable */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import CarStatus from "../../assets/images/car-staus.svg"
import trace from "../../assets/images/trace.svg"
import list from "../../assets/images/list.svg"
import t from "../../assets/images/t.svg"
import target from "../../assets/images/target.svg"
import infoicons1 from "../../assets/images/icons/static.png"
import infoicons2 from "../../assets/images/icons/static-idle.png"
import infoicons3 from "../../assets/images/icons/moving.png"
import infoicons4 from "../../assets/images/icons/speeding.png"
import infoicons5 from "../../assets/images/icons/offline.png"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import IconButton from "@mui/material/IconButton"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { useSelector, useDispatch } from "react-redux"
import { getDeviceGroup } from "../../store/actions"
import "./Monitor.css"

import {
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import AllDeviceList from "./AllDeviceList"
import DeviceListOnline from "./DeviceListOnline"
import DeviceListOffline from "./DeviceListOffline"
import { Popover } from "antd"
import "antd/dist/antd.css"

function MyDeviceAll() {
  const [col6, setcol6] = useState(true)
  const [deviceGroupList, setDeviceGroupList] = useState([])
  const [isGroupSelected, setIsGroupSelected] = useState(true)
  const { deviceDetails, isLoading, error } = useSelector(state => {
    return {
      deviceDetails: state.MonitorDetails.deviceDetails,
      isLoading: state.MonitorDetails.isLoading,
      error: state.MonitorDetails.error
    }
  })

  // useEffect(() => {
  //   if (!isGroupSelected) {
  //     const deviceGroupsData = [...deviceGroups]
  //     let latestArrayDeviceList = []
  //     deviceGroupsData.forEach(deviceGroup => {
  //       latestArrayDeviceList = [
  //         ...latestArrayDeviceList,
  //         ...deviceGroup?.device,
  //       ]
  //     })

  //     setDeviceGroupList([...latestArrayDeviceList])
  //   } else {
  //     const deviceGroupsData = [...deviceGroups]
  //     const updateDeviceGroups = deviceGroupsData.map(deviceGroup => ({
  //       id: deviceGroup.id,
  //       key: deviceGroup.id,
  //       name: deviceGroup.name,
  //       memberId: deviceGroup.memberId,
  //       device: deviceGroup?.device?.map((device, index) => ({
  //         ...device,
  //         key: index,
  //       })),
  //     }))

  //     setDeviceGroupList([...updateDeviceGroups])
  //   }
  // }, [deviceGroups, isGroupSelected])

  // useEffect(() => {
  //   props?.getDeviceGroupAction({ memberId: selectedUser })
  // }, [selectedUser])

  const t_col6 = () => {
    setcol6(!col6)
  }
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      )
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows)
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows)
    },
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "model" || "name",
      key: "model" || "name",
      render: (value, row, index) => {
        console.log({ row })
        if (row?.children) {
          return (
            <div className="Accord_head d-flex flex-row ">
              <div className="flx_1" onClick={t_col6}>
                {row.name} {`(${row?.children?.length})`}
              </div>
              {index === 0 && (
                <div className="flx_0 d-flex align-items-center">
                  <img alt="CarStatus" width="24" src={CarStatus} />
                  <i className="bx bxs-pencil font-size-18 ms-1"></i>
                </div>
              )}
            </div>
          )
        } else {
          return <div style={{ marginTop: "2px" }}>{row.model}</div>
        }
      },
    },
  ]

  const handleChangeView = () => {
    setIsGroupSelected(!isGroupSelected)
  }

  const popcontent = (
    <div className="devicesta">
      <span className="staticdevice">
        <h4>Status:</h4>
        <ul>
          <li>
            <span className="colo1"></span>
            <img className="" src={infoicons1} width={18} />
            <b>Static</b>
          </li>
          <li>
            <span className="colo2"></span>
            <img className="" src={infoicons2} width={18} />
            <b>Static(Engine IDLE)</b>
          </li>
          <li>
            <span className="colo3"></span>
            <img className="" src={infoicons3} width={18} />
            <b>Moving</b>
          </li>
          <li>
            <span className="colo4"></span>
            <img className="" src={infoicons4} width={18} />
            <b>Speeding</b>
          </li>
          <li>
            <span className="colo5"></span>
            <img className="" src={infoicons5} width={18} />
            <b>Offline</b>
          </li>
        </ul>

        <h4>Not shown on map:</h4>
        <ul className="pb-2">
          <li>
            <span className="colo6">
              <i className="bx bxs-info-circle colored"></i>
            </span>
            <img className="" src={infoicons5} width={18} />
            <b>Expired</b>
          </li>
          <li>
            <span className="colo7">
              <i className="bx bxs-lock-alt"></i>
            </span>
            <img className="" src={infoicons5} width={18} />
            <b>Unactivated</b>
          </li>
        </ul>
      </span>
    </div>
  )

  const ExpandableTableRow = ({
    children,
    index,
    key,
    row,
    expand,
    expandComponent,
    ...otherProps
  }) => {
    const [isExpanded, setIsExpanded] = React.useState(false)

    if (!expand) {
      return (
        <TableRow style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
          <TableCell padding="checkbox"></TableCell>
          <TableCell>{row.model}</TableCell>
          <TableCell padding="checkbox"></TableCell>
        </TableRow>
      )
    }

    return (
      <>
      
        <TableRow
          {...otherProps}
          style={{
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            backgroundColor: "#f4f0f0",
          }}
        >
          <TableCell padding="checkbox">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            {row.name} {`(${row?.device?.length || 0})`}
          </TableCell>
          {index === 0 && (
            <TableCell
              className="flx_0 d-flex align-items-center cell-border-none"
              style={{ padding: "8px" }}
            >
              <img alt="CarStatus" width="24" src={CarStatus} />
              <i className="bx bxs-pencil font-size-18 ms-1"></i>
            </TableCell>
          )}
        </TableRow>
        {isExpanded &&
          row?.device?.map((device, idx) => (
            <TableRow
              key={idx}
              style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
            >
              <TableCell padding="checkbox" />
              <TableCell>{device.model}</TableCell>
            </TableRow>
          ))}
      </>
    )
  }

  return (
    <>
      <div className="flx_1 d-flex w-100 flex-column h-50 over_auto border-bottom">
        <div className="bg_cus_1 d-flex p-2 flx_0 justify-content-between">
          <div className="d-flex">
            <button className="cus_btn_icon me-1">
              <img className="" alt="" src={trace} width="16" />
            </button>
            <button
              className={
                !isGroupSelected
                  ? "list-view cus_btn_icon me-3"
                  : "cus_btn_icon me-3"
              }
              onClick={() => handleChangeView()}
            >
              <img alt="" src={list} width="16" />
            </button>
          </div>
          <div className="d-flex">
            <div className="pe-1">
              <select name="" id="" className="form-select selecsmall">
                <option>Select</option>
              </select>
            </div>
            <button className="cus_btn_icon me-1">
              <img className="" alt="" src={t} width="16" />
            </button>
            <button className="cus_btn_icon me-1">
              <img className="" alt="" src={target} width="16" />
            </button>
          </div>
        </div>
        {}
        <div className="tab_wrapper2 flx_1 d-flex flex-column over_auto">
          <Nav
            tabs
            className="nav-tabs-custom flx_0 d-flex align-items-center justify-content-between"
          >
            <div className="d-flex">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1")
                  }}
                >
                  <span className="d-none d-sm-block">
                    All
                    {customActiveTab === "1" &&
                      `(${
                        deviceDetails.statusList
                          ? _.size(deviceDetails.rows)
                          : 0
                      })`}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2")
                  }}
                >
                  <span className="d-none d-sm-block">
                    Online(
                    {deviceDetails.statusList
                      ? _.size(deviceDetails.statusList[1])
                      : 0}
                    )
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "3",
                  })}
                  onClick={() => {
                    toggleCustom("3")
                  }}
                >
                  <span className="d-none d-sm-block">
                    Offline(
                    {deviceDetails.statusList
                      ? _.size(deviceDetails.statusList[0])
                      : 0}
                    )
                  </span>
                </NavLink>
              </NavItem>
            </div>

            <Popover content={popcontent} placement="right" className="me-2">
              <span type="primary" className="">
                {" "}
                <i className="bx bxs-info-circle tooltip_i"></i>
              </span>
            </Popover>
          </Nav>
          <TabContent
            activeTab={customActiveTab}
            className="tab-content text-muted flx_1 over_auto"
          >
            <TabPane tabId="1" className="tab-pane h-100">
              <AllDeviceList deviceDetails={deviceDetails.rows} />
            </TabPane>
            <TabPane tabId="2" className="tab-pane">
              {deviceDetails.statusList && (
                <AllDeviceList deviceDetails={deviceDetails.statusList[1]} />
              )}
            </TabPane>
            <TabPane tabId="3" className="tab-pane">
              {deviceDetails.statusList && (
                <AllDeviceList deviceDetails={deviceDetails.statusList[0]} />
              )}
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  )
}


export default MyDeviceAll;
