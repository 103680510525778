/* eslint-disable */

import React, { useState } from "react"
import { Link } from "react-router-dom"
import _ from "lodash"
import { Popover } from "antd"
import { Modal, Collapse } from "reactstrap"
import classnames from "classnames"
import MyDeviceAll from "./MyDeviceAll"
import DeviceDetailsCom from "../../pages/Shared/DeviceDetails"
import listvechi from "../../assets/images/icons/group-vechicle.svg"
import listvechistatus from "../../assets/images/icons/group-vechicle-status.svg"
import GroupAdd from "./PlayBack/GroupAdd"
import { setSelectedDevicePopup } from "../../store/actions"
import "./Monitor.css"
import { useDispatch } from "react-redux"
import { renderSwitch } from "../../utils/vehicleStatus"
const MyDevice = React.memo(({ deviceDetails }) => {
  const dispatch = useDispatch()
  //popup operations

  const [commonData, setCommonData] = useState(false)
  const [commonPopUpOpen, setCommonPopUpOpen] = useState(false)
  const [selectedNode, setSelectedNode] = useState({})

  //functions for model popup
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const popUpHandler = (popUpId, item) => {
    setCommonPopUpOpen(!commonPopUpOpen)
    setCommonData(popUpId)
    setSelectedNode(item)
    removeBodyCss()
  }
  const handlePopUpClose = (status) => {
    setCommonPopUpOpen(status)
  }
  const popContentMenu = item => {
   
    return (
      <div className="popuserright hoverdiv">
        <ul>
          {item.lat && item.lng && (
            <>
              <li>
                <Link to={`/monitor/track/${item.id}`} target={"_blank"}>
                  <i className="iicons trackicons"></i>Track
                </Link>
              </li>
              <li>
                <Link
                  to={`/monitor/playback/${item.deviceMapping[0].memberId}/${item.id}`}
                  target={"_blank"}
                >
                  <i className="iicons playicons"></i>Playback
                </Link>
              </li>


            </>
          )}

          <li href="#">
            <i className="iicons commandicons"></i> Command
          </li>
          <li
            onClick={() => {
              popUpHandler("Device Details", item)
            }}
          >
            <i className="iicons detailsicons"></i> Details
          </li>
          <li href="#">
            <i className="iicons movegroicons"></i> Move Group
          </li>
          <li href="#">
            <i className="iicons reporticons"></i> Report
          </li>
          <li href="#">
            <i className="iicons suppicons"></i> Supplier
          </li>
          <li href="#">
            <i className="iicons helpicons"></i> Helps
          </li>
        </ul>
      </div>
    )
  }

  const [tagGroup, setTagGroup] = useState(true)
  const handleClick = () => {
    setTagGroup(!tagGroup)
  }

  const [tagGroupAnother, setTagGroupAnother] = useState(true)
  const handleClick2 = () => {
    setTagGroupAnother(!tagGroupAnother)
  }

  return (<>
    <div>
      <div
        onClick={handleClick}
        className={tagGroup ? "your-class" : null}
      //className="p-2 d-flex cursor-pointer position-relative bgtoggle"
      >
        <i className=" fas fa-caret-right fs-5"></i>{" "}
        <span className="ms-1">
          <b>Default Device Group</b>{" "}
        </span>
        <div className="absolilist">
          <span
            onClick={() => {
              popUpHandler("")
            }}
          >
            {" "}
            <img
              src={listvechistatus}
              width="18"
              className="me-1 opacity-75"
            />{" "}
            <img src={listvechi} width="18" className="me-1 opacity-75" />{" "}
          </span>
        </div>
      </div>
      <div
        className="groupvechicle"
        style={{ display: tagGroup ? "block" : "none" }}
      >
        <ul className="deviceshown">
          {deviceDetails &&
            deviceDetails.map((item, index) => {
              const deviceStatus = renderSwitch(item)

              return (
                <li
                  key={index + Math.random() * 10}
                  className={deviceStatus.classNameStatus}
                >
                  <div className="form-check" onClick={() =>
                    dispatch(setSelectedDevicePopup(item.indexValue))
                  }>
                    {/* <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    /> */}

                    <span className="devicenam">
                      {item.lat && item.lng ? (
                        <Link
                          to={"#"}
                          onClick={() => {
                            console.log("clicked the menu")
                            dispatch(setSelectedDevicePopup(item.indexValue))
                          }
                        
                          }
                        >
                          {item.name ? item.name : item.model}
                        </Link>
                      ) : (
                        <Link onClick={() => { }} to={"#"}>
                          {item.name ? item.name : item.model}  
                        </Link>
                      )}
                    </span>
                  </div>

                  <div className="timedurea d-flex align-items-center">
                    {deviceStatus.duration}
                    <span alt={deviceStatus.status}>
                      <b className="devicenoto" alt={deviceStatus.status}></b>
                    </span>
                    <div className="d-inline-flex devicpoo">
                      <Popover content={popContentMenu(item)} placement="right">
                        <span type="primary" className="dottedusersss">
                          <i className=" bx bx-dots-vertical-rounded"></i>
                        </span>
                      </Popover>
                    </div>
                  </div>
                </li>
              )
            })}
        </ul>
      </div>



    </div>
    <Modal
      size="lg"
      isOpen={commonPopUpOpen}
      toggle={() => {
        popUpHandler("none")
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">{commonData}</h5>
        <button
          type="button"
          onClick={() => {
            setCommonPopUpOpen(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="mdi mdi-close"></i>
        </button>
      </div>
      {commonData === "Device Details" && (
        <>
          <div className="modal-body">
            <DeviceDetailsCom selectedDevice={selectedNode} selectedUser={{}} popupHandler={handlePopUpClose} />
          </div>
        </>
      )}
      {commonData === "Add Group" && (
        <>
          <div className="modal-body">
            <GroupAdd selectedDevice={selectedNode} selectedUser={{}} popupHandler={handlePopUpClose} />
          </div>
        </>
      )}


    </Modal></>
  )
})

export default MyDevice
