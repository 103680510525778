/* eslint-disable */

import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
} from "reactstrap"
import CheckPopup from "./CheckPopup"

function CreateAccount() {
  const [popoverright, setpopoverright] = useState(false);


  return (
    <div>
     <div className="bg_gray p-2 mb-3 d-flex align-items-center flex-row">
            <i className="bx bxs-info-circle txt_link flx_0 fs-5 me-2"></i>
            <p className="mb-0 mt-0 flx_1">
            Create an account and transfer the device to this account.
            </p>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <label>Account <small className="text-danger">*</small></label>
                <div>
                <input
                        className="form-control"
                        type="text"
                        value={892951027457085}
                      />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="pb-3">
                <label>User name <small className="text-danger">*</small></label>
                <div>
                <input
                        className="form-control"
                        type="text"
                        value="GT06N-57085"
                      />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <label>Password <small className="text-danger">*</small></label>
                <div>
                <input
                        className="form-control"
                        type="password"
                        value="GT06N-57085"
                      />
                      <span className="form-text d-flex align-items-center"><i className="bx bxs-info-circle txt_link me-1"></i> Last 6 digits of the IMEI
</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <label>Contact</label>
                <div>
                <input
                        className="form-control"
                        type="text"
                      />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="pb-3">
                <label>Mobile</label>
                <div>
                <input
                        className="form-control"
                        type="text"
                      />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <label>Telephone</label>
                <div>
                <input
                        className="form-control"
                        type="text"
                      />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="pb-3">
                <label>Email</label>
                <div>
                <input
                        className="form-control"
                        type="text"
                      />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <label>Address</label>
                <div>
                <input
                        className="form-control"
                        type="text"
                      />
                </div>
              </div>
            </div>
          </div>
          <div className="text-end">
            <button className="btn btn-primary">Submit</button>
          </div>

    </div>
  )
}

export default CreateAccount
