import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import "bootstrap/dist/css/bootstrap.css";
import store from "./store"

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </Provider>
)

// Get the container to mount the app
const container = document.getElementById("root")

// Create a root
const root = createRoot(container)

// Render the app using the root
root.render(app)

// Service Worker
serviceWorker.unregister()
