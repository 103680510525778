import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  UPDATE_LOGIN_USER,
  UPDATE_LOGIN_USER_SUCCESS,
  UPDATE_LOGIN_USER_ERROR,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  LOGIN_USER_DETAIL,
  LOGIN_USER_DETAIL_SUCCESS,
  LOGIN_USER_DETAIL_ERROR,
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

export const updateLoginUser = (userData, id, history, isFileUpload, files) => {
  return {
    type: UPDATE_LOGIN_USER,
    payload: { userData, id: id, history, isFileUpload, files },
  }
}

export const updateLoginUserSuccess = message => {
  return {
    type: UPDATE_LOGIN_USER_SUCCESS,
    payload: message,
  }
}

export const updateLoginUserError = message => {
  return {
    type: UPDATE_LOGIN_USER_ERROR,
    payload: message,
  }
}

export const uploadImage = images => {
  return {
    type: UPLOAD_IMAGE,
    payload: { images },
  }
}

export const uploadImageSuccess = message => {
  return {
    type: UPLOAD_IMAGE_SUCCESS,
    payload: message,
  }
}

export const uploadImageError = message => {
  return {
    type: UPLOAD_IMAGE_ERROR,
    payload: message,
  }
}

export const loginUserDetail = () => {
  return {
    type: LOGIN_USER_DETAIL,
  }
}

export const loginUserDetailSuccess = detail => {
  return {
    type: LOGIN_USER_DETAIL_SUCCESS,
    payload: detail,
  }
}

export const loginUserDetailError = detail => {
  return {
    type: LOGIN_USER_DETAIL_ERROR,
  }
}
