/* eslint-disable */
import { call, put, takeLatest } from "redux-saga/effects"
import { CarryOutOutlined, FormOutlined } from "@ant-design/icons"

// Crypto Redux States
import { GET_MONITOR_INITIAL } from "./actionTypes"

import {
  getMonitorDetailsSuccess,
  getMonitorDetailsFail
} from "./actions"

//Include Both Helper File with needed methods
import { getMonitorDataNew } from "../../helpers/fakebackend_helper"

function* fetchMonitorDetails({ selectedData }) {
  try {
    const response = yield call(getMonitorDataNew, selectedData)
    yield put(getMonitorDetailsSuccess(response))
  } catch (error) {
    yield put(getMonitorDetailsFail(error))
  }
}


function* monitorSaga() {
  yield takeLatest(GET_MONITOR_INITIAL, fetchMonitorDetails)
}

export default monitorSaga
