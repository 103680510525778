/* eslint-disable */

import React, { Component, useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Spinner,
} from "reactstrap"
import Tooltip from "@mui/material/Tooltip"
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"
import PlayBackSpeedChart from "./PlayBackSpeedChart"
import PlayBackDetailsTab from "./PlayBackDetailsTab"
import PlayBackTripTab from "./PlayBackTripTab"

import icons1 from "../../../assets/images/icons/location-alert.svg"
import icons2 from "../../../assets/images/icons/location-loca.svg"
import icons3 from "../../../assets/images/icons/parking.svg"
import icons4 from "../../../assets/images/icons/information.svg"
import icons5 from "../../../assets/images/icons/points.svg"

const PlayBackTabs = ({ handleRightOption }) => {
  const [PlayBackTabsShow, setPlayBackTabsShow] = useState(false)
  const [activeTab, setActiveTab] = useState("1")

  const [alertClass, setAlertClass] = useState(false)
  const [startEndMarkerClass, setStartEndMarkerClass] = useState(false)
  const [parkingMarkerClass, setParkingMarkerClass] = useState(false)
  const [infoWindowClass, setInfoWindowClass] = useState(false)
  const [showPointsClass, setShowPointsClass] = useState(false)

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const PlayBackTabsOpenBtn = id => {
    setPlayBackTabsShow(true)
    toggleTab(id)
  }

  const PlayBackTabsClose = () => {
    setPlayBackTabsShow(false)
  }
  return (
    <div>
      <div className="play_back_tab_right">
        <div className="d-flex flex-column">
          <span className={alertClass ? "tabs_pb activemaps" : "tabs_pb "}>
          <Tooltip title="Alert" placement="right" onClick={() => { handleRightOption("alert"); setAlertClass(!alertClass)}} arrow>
              <img src={icons1} width="30" />
            </Tooltip>
          </span>
          <span className={startEndMarkerClass ? "tabs_pb activemaps" : "tabs_pb "} >
          <Tooltip title="Start End Marker" onClick={() => { handleRightOption("startEndMarker"); setStartEndMarkerClass(!startEndMarkerClass) }} placement="right" arrow>
            <img src={icons2} width="30" />
            </Tooltip>
            
          </span>
          <span className={parkingMarkerClass ? "tabs_pb activemaps" : "tabs_pb "}>
          <Tooltip title="Parking Marker" placement="right" onClick={() => { handleRightOption("parkingMarker"); setParkingMarkerClass(!parkingMarkerClass) }} arrow>
            <img src={icons3} width="30" />
            </Tooltip>
          </span>

          <span className={infoWindowClass ? "tabs_pb activemaps" : "tabs_pb "}>
          <Tooltip title="Info Window" placement="right" onClick={() => { handleRightOption("infoWindow"); setInfoWindowClass(!infoWindowClass) }} arrow>
            <img src={icons4} width="30" />
            </Tooltip>
          </span>

          <span className={showPointsClass ? "tabs_pb activemaps" : "tabs_pb "} >
          <Tooltip title="Show Points" placement="right" onClick={() => { handleRightOption("showPoints"); setShowPointsClass(!showPointsClass) }}  arrow>
            <img src={icons5} width="30" />
            </Tooltip>
          </span>
        </div>
      </div>
      
    </div>
  )
}

export default PlayBackTabs
