/* eslint-disable */

import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import "./ReportStyle.css"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

const ReportParkGrid = () => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      classes: 'columsno'
    },
    {
      dataField: "DeviceName",
      text: "Device Name",
      sort: false,
    },
    {
      dataField: "engineon",
      text: "Engine ON",
      sort: true,
    },
    {
      dataField: "engineoff",
      text: "Engine OFF",
      sort: true,
    },
   
  ]

  // Table Data
  const productData = [
    {
      id: 1,
      DeviceName: "ALFA",
      engineon: "5hr24min13s",
      engineoff: "17hr20min39s",
    },
    {
      id: 2,
      DeviceName: "MARUTHI VAN",
      engineon: "4hr42min53s",
      engineoff: "18hr1min59s",
    },
    
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: productData.length },
  ]

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          

          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={columns}
            data={productData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={productData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle table-nowrap table-bordered"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                         

                          <div className="grid_down_item text-end pb-3">
            <button className="btn btn-success btn-sm me-3 pt-1 pb-1 cursor-pointer">
              Excel
            </button>
            <button className="btn btn-danger-2 text-white btn-sm me-3 pt-1 pb-1 cursor-pointer">
              PDF
            </button>
            <button className="btn btn-info btn-sm pt-1 pb-1 cursor-pointer">
              Print
            </button>
          </div>

                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ReportParkGrid
