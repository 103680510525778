/* eslint-disable */
import React, { useEffect, useState, useRef } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Tooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Label,
  Button,
  Form,
  Input,
  NavLink,
  Container,
  InputGroup,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { Link } from "react-router-dom"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import SelectSearch from "react-select-search"
import TreeSelect from "../../components/user/treeMenuSelectBox"
import { getCardBalanceAction } from "../../store/actions"
import "./Card.css"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { getCardCountDefault } from "../../store/actions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { useDispatch, useSelector } from "react-redux"

const ExchangeLifeCardHistory = () => {
  const dispatch = useDispatch()
  // const [parentID, setParentID] = useState(selectedUser?.id || "")

  const { getCardsCountDataOBject, selectedValue } = useSelector(state => {
    return {
      getCardsCountDataOBject: state.GetCardsDataReducer.cardsCount,
      selectedValue: state.TreeMenu.treeData,
    }
  })

  const [parentID, setParentID] = useState(selectedValue[0]?.id || "")
  const handleSetData = childData => {
    setParentID(childData)
  }

  useEffect(() => {
    dispatch(getCardCountDefault())
  }, [])

  useEffect(() => {
    if (parentID) {

      dispatch(getCardBalanceAction(parentID))
    }
  }, [parentID])

  const { getCardBalanceDataOBject } = useSelector(state => {
    return {
      getCardBalanceDataOBject: state.GetCardsDataReducer.cardBalanceData,
    }
  })


  // data
  const columns = [
    {
      dataField: "sno",
      text: "#",
      sort: false,
    },
    {
      dataField: "name",
      text: "User name",
      sort: false,
    },
    {
      dataField: "username",
      text: "Account",
      sort: false,
    },
    {
      dataField: "newCard",
      text: "New card",
      sort: false,
    },
    {
      dataField: "newcardlifelong",
      text: "New card to lifelong",
      sort: false,
    },
    {
      dataField: "annualCard",
      text: "Annual card",
      sort: false,
    },
    {
      dataField: "lifeLongCard",
      text: "Lifelong card",
      sort: false,
    },
  ]

  // Table Data
  const productData = getCardBalanceDataOBject

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: productData.length },
  ]

  // Select All Button operation
  //const selectRow = {
  // mode: 'checkbox'
  //}

  const { SearchBar } = Search

  const handleChange = (...args) => {
    searchInput.current.querySelector("input").value = args
  }

  const handleFilter = items => {
    return searchValue => {
      if (searchValue.length === 0) {
        return options
      }
      const updatedItems = items.map(list => {
        const newItems = list.items.filter(item => {
          return item.name.toLowerCase().includes(searchValue.toLowerCase())
        })
        return { ...list, items: newItems }
      })
      return updatedItems
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Card Balance</title>
        </MetaTags>
        <div>
          <Container fluid>
            <Row className="mt-3">
              <Col md={12}>
                <Card className="mb-3">
                  <CardBody className="d-flex align-items-center justify-content-between p-2">
                    <div className="page-title-box">
                      <h3 className="mb-0 font-size-16 d-flex align-items-center">
                        Transfer card
                      </h3>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody className="d-flex align-items-center justify-content-between px-3 py-4">
                    <Col className="col-12">
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={columns}
                        data={productData || "No Data Found"}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={productData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-4 d-flex">
                                  <div className="col-md-4 pt-2 d-flex">
                                    <span className="pt-2 pe-2">Account</span>
                                    <TreeSelect
                                      parentCallback={handleSetData}
                                      selectedValue={parentID}
                                    />
                                  </div>
                                  <div className="col-md-8 pt-2 d-flex justify-content-end">
                                    {getCardsCountDataOBject &&
                                      getCardsCountDataOBject.map(data => {
                                        return (
                                          <>
                                            <span className="countcss">
                                              <b>{data?.newCardCount || 0}</b>
                                              New card
                                            </span>
                                            <span className="countcss">
                                              <b>0</b>New card to lifelong
                                            </span>
                                            <span className="countcss">
                                              <b>
                                                {data?.annualCardsCount || 0}
                                              </b>
                                              Annual card
                                            </span>
                                            <span className="countcss">
                                              <b>
                                                {data?.lifeLongCardsCount || 0}
                                              </b>
                                              Lifelong card
                                            </span>
                                          </>
                                        )
                                      })}
                                  </div>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        //selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ExchangeLifeCardHistory
