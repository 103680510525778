import moment from "moment"
export const dateDifferent = (date1, date2) => {
  let output = ""
  if (date1) {
    const diffInMilliseconds = date2.diff(date1)
    const duration = moment.duration(diffInMilliseconds)
    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()
  
    if (days) {
      output += `${days}d+`
    }
    if (hours && days===0) {
      output += `${hours}h+`
    }
    if (minutes && days === 0 && hours === 0 ) {
      output += `${minutes}m`
    }
  }
  return output
}

 