/* eslint-disable */

import React from "react"
import { Link } from "react-router-dom"

const PlayLocalData = () => {
  return (
    <div>
      <div className="p-3">
        <div className="bg_cus_2 p-3 d-flex align-items-center mb-3">
          <span className="pe-2 flx_0">
            <i className="helpIcon  bx bxs-info-circle font-size-16"></i>
          </span>
          <p className="flx_1 mb-0">
          Please choose your local trace data file,which you downloaded from Trace download before.
            
          </p>
        </div>

        <div>
          <button className="file_upload_btn btn btn-primary btn-sm me-2">
            <input type="file" />
            Upload
          </button>
          <span  className="link_txt ps-2">
          (.txt, maximum size 2 Mb)
          </span>
        </div>

        <div className="pt-3 text-end">
          <button className="btn btn-outline-primary btn-sm me-2">
            Cancel
          </button>
          <button className="btn btn-primary btn-sm ">Play</button>
        </div>
      </div>
    </div>
  )
}

export default PlayLocalData
