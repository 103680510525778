/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_CARDS_DATA,
  GET_CARDS_DATA_FAIL,
  GET_CARDS_DATA_SUCCESS,
  ADD_TRANSFER_CARD_DEFAULT,
  ADD_CARD_DEFAULT,
  GET_DISTRIBUTOR_DATA_DEFAULT,
  GET_CARD_COUNT_DATA_DEFAULT,
  GET_CARD_BALANCE_DATA_DEFAULT,
} from "./actionTypes"

import { showToast } from "../../utils/Common"

import {
  getCardMenuSuccess,
  getCardMenuFail,
  addCardDefault,
  addCardSuccess,
  addCardFail,
  getAllDistribitutorSuccess,
  getAllDistribitutorFail,
  addTransferCardSuccess,
  addTransferCardFail,
  addTransferCardDefault,
  getCardCountASuccess,
  getCardCountFail,
  getCardBalanceActionSuccess,
  getCardBalanceActionFail,
} from "./action"

import {
  getAllCardsSaga,
  addPostCard,
  getAllDistribitutorSaga,
  postTransferCard,
  getAllCardCount,
  getCardBalanceData,
} from "../../helpers/fakebackend_helper"

function* fetchGetAllCardsData() {
  try {
    const response = yield call(getAllCardsSaga)
    yield put(getCardMenuSuccess(response))
  } catch (error) {
    yield put(getCardMenuFail(error))
  }
}

function* fetchGetAllCardsCount() {
  try {
    const response = yield call(getAllCardCount)
    yield put(getCardCountASuccess(response))
  } catch (error) {
    yield put(getCardCountFail(error))
  }
}

function* fetchCardBalanceDataById({ id }) {
  try {
    const response = yield call(getCardBalanceData, id)
    yield put(getCardBalanceActionSuccess(response))
  } catch (error) {
    yield put(getCardBalanceActionFail(error))
  }
}

function* addCardDataAction({ payload }) {
  const {
    payload: data,
    meta: { resolve, reject },
  } = payload
  
  try {
    const response = yield call(addPostCard, data)
    yield put(addCardSuccess(response))
    yield call(resolve, response)
    showToast("Card added succesfully", true)
  } catch (error) {
    yield put(addCardFail())
    yield call(reject, error)
    showToast(`${error?.response?.data.message}`, false)
  }
}

function* addTransferCardDataAction({ payload }) {
  const {
    payload: data,
    meta: { resolve, reject },
  } = payload
  try {
    const response = yield call(postTransferCard, data.payload)
    yield put(addTransferCardSuccess(response))
    yield call(resolve, response)
    showToast("Transfer Card added succesfully", true)
  } catch (error) {
    yield put(addTransferCardFail())
    yield call(reject, error)
    showToast(`${error?.response?.data.message}`, false)
  }
}

function* getallDistributorAction() {
  try {
    const response = yield call(getAllDistribitutorSaga)
    yield put(getAllDistribitutorSuccess(response))
  } catch (error) {
    yield put(getAllDistribitutorFail(error))
  }
}

function* contactsSaga() {
  yield takeEvery(GET_CARDS_DATA, fetchGetAllCardsData)
  yield takeEvery(ADD_CARD_DEFAULT, addCardDataAction)
  yield takeEvery(GET_DISTRIBUTOR_DATA_DEFAULT, getallDistributorAction)
  yield takeEvery(ADD_TRANSFER_CARD_DEFAULT, addTransferCardDataAction)
  yield takeEvery(GET_CARD_COUNT_DATA_DEFAULT, fetchGetAllCardsCount)
  yield takeEvery(GET_CARD_BALANCE_DATA_DEFAULT, fetchCardBalanceDataById)
}

export default contactsSaga
