/* eslint-disable */

import React, { Component, useState } from "react"
// import React, { Component }  from 'react';
import MetaTags from "react-meta-tags"
import { Container, Row, Card,Label,
    Button,Nav,
    Table,
    NavItem,
    NavLink, TabContent,
    TabPane,
    CardBody,
    CardText,
    Modal,
    CardTitle,
    Col } from "reactstrap"
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"

import Profile from "../../components/Profile/Profile"

const Membership = props => {
  const [activeTab, setactiveTab] = useState("1");
  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };


  const [modal_center2, setmodal_center2] = useState(false)
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }

  

  return (
    <React.Fragment>
    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>Account</th>
                          <th>Operation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><span className="text-info">Account </span></td>
                          <td>admindemo</td>
                          <td><span className="text-info">
                            
                          
                            <span className="txt_colr_1"  onClick={() => {
                              tog_center2()
                            }}>Details</span> | <Link
                to="/monitor" target={"_blank"}
                className="txt_colr_1"
              >
                <span>Monitor</span>
              </Link>
              </span></td>
                        </tr>
                       
                      </tbody>
                    </Table>
                   

                   



      <Modal
        size="lg"
        isOpen={modal_center2}
        toggle={() => {
          tog_center2()
        }}
        
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Details</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center2(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <Profile />
        </div>
      </Modal>

  
    </React.Fragment>
  )
}

export default Membership
