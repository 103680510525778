import React, { useState } from "react"
import Tooltip from "@mui/material/Tooltip"

const AddGroupGeoFence = () => {
  const [AddGroup2, setAddGroup] = useState(false)
  const AddGroup = () => {
    setAddGroup(true)
  }
  return (
    <div>
      <div className="p-3">
        <header className="border-bottom pb-2 mb-2">Default Group</header>

        <div className="append_grop">
          {AddGroup2 ? (
            <div className="d-flex align-items-center border-bottom pb-3 mb-3">
              <div className="flx_1 pe-2">
                <input type="text" className="form-control w-100" />
              </div>
              <div className="flx_0 pe-2">
                <Tooltip title="Save" placement="top" arrow>
                  <i className="bx bxs-save fs-5"></i>
                </Tooltip>
              </div>
              <div className="flx_0 pe-2">
                <Tooltip title="Delete" placement="top" arrow>
                  <i className=" bx bx-trash-alt fs-5"></i>
                </Tooltip>
              </div>
              <div className="flx_0">
                <Tooltip title="Cancel" placement="top" arrow>
                  <i className="bx bx-block fs-5"></i>
                </Tooltip>
              </div>
            </div>
          ) : null}
        </div>
        <div className="pt-3">
          <button onClick={AddGroup} className="btn btn-primary btn-sm">
            Add Group
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddGroupGeoFence
