/* eslint-disable */

import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
} from "reactstrap"
import CheckPopup from "./CheckPopup"

function Recharge() {
  const [popoverright, setpopoverright] = useState(false);


  return (
    <div>
     <div className="bg_gray p-2 mb-3 d-flex align-items-center flex-row">
            <i className="bx bxs-info-circle txt_link flx_0 fs-5 me-2"></i>
            <p className="mb-0 mt-0 flx_1">
              Please confirm whether your device support these functions.
            </p>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <label>Input 1 :</label>
                <div>
                <select className="form-control">
              <option>Select</option>
              <option>Air conditioner</option>
              <option>Door status</option>
              <option>SOS</option>
              <option>Engine status</option>
            </select>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="pb-3">
                <label>Input 2 :</label>
                <div>
                <select className="form-control">
              <option>Select</option>
              <option>Air conditioner</option>
              <option>Door status</option>
              <option>SOS</option>
              <option>Engine status</option>
            </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <label>Input 3 :</label>
                <div>
                <select className="form-control">
              <option>Select</option>
              <option>Air conditioner</option>
              <option>Door status</option>
              <option>SOS</option>
              <option>Engine status</option>
            </select>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="pb-3">
                <label>Input 4 :</label>
                <div>
                <select className="form-control">
              <option>Select</option>
              <option>Air conditioner</option>
              <option>Door status</option>
              <option>SOS</option>
              <option>Engine status</option>
            </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <label>Input 5 :</label>
                <div>
                <select className="form-control">
              <option>Select</option>
              <option>Air conditioner</option>
              <option>Door status</option>
              <option>SOS</option>
              <option>Engine status</option>
            </select>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="pb-3">
                <label>Input 6 :</label>
                <div>
                <select className="form-control">
              <option>Select</option>
              <option>Air conditioner</option>
              <option>Door status</option>
              <option>SOS</option>
              <option>Engine status</option>
            </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="pb-3">
                <label>Input 7 :</label>
                <div>
                <select className="form-control">
              <option>Select</option>
              <option>Air conditioner</option>
              <option>Door status</option>
              <option>SOS</option>
              <option>Engine status</option>
            </select>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="pb-3">
                <label>Input 8 :</label>
                <div>
                <select className="form-control">
              <option>Select</option>
              <option>Air conditioner</option>
              <option>Door status</option>
              <option>SOS</option>
              <option>Engine status</option>
            </select>
                </div>
              </div>
            </div>


          </div>

          <div className="text-end">
            <button className="btn btn-primary">Submit</button>
          </div>

    </div>
  )
}

export default Recharge
