/* eslint-disable */

import React, { useEffect, useState, memo } from "react"
import { Modal, InputGroup, Spinner } from "reactstrap"
import "../Monitor.css"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Tooltip from "@mui/material/Tooltip"
import DeviceDetails from "../../../components/Business/DeviceDetails"
import PlayLocalData from "./PlayLocalData"
import moment from "moment"
import { getDeviceByDeviceId } from "../../../store/actions"
import LoadingButton from "../../Common/Button"
import classnames from "classnames"
import { showToast } from "../../../utils/Common"
//i18n
import { useSelector, dispatch, useDispatch } from "react-redux"
import { withTranslation } from "react-i18next"
import { getPlayBackHistoryDetails } from "../../../store/actions"

const PlayBackTop = ({ playTopData }) => {
  const {
    deviceDetails,
    getAddData,
    PreviousData,
    updateFilterValue,
    playButtonStatus,
    defaultDevice,
    changeButtonHandler,
    stopAndPlay,
    stopAndPlayHandler,
    playPauseStop,
    playBackHistory,
    resetCurser,
  } = playTopData
  const { fromDateParent, toDateParent, deviceIdParent } = PreviousData

  const [menu, setMenu] = useState(false)
  const [colEnable, setColEnable] = useState(true)
  const [AlertVolume, setAlertVolumeBtn] = useState(false)
  const [NotificationPop, setNotificationPop] = useState(false)
  const [ReceiveVoicePop, setReceiveVoicePop] = useState(false)
  const [PlayLocal, setPlayLocal] = useState(false)
  const [modelStandard2, setModelStandard2] = useState(false)
  const [buttonAction, setButtonAction] = useState(playButtonStatus)
  const [fromDate, setFromDate] = useState(
    `${moment().format("Y-MM-DD")} 00:01`
  )
  const dispatch = useDispatch()
  const [toDate, setToDate] = useState(moment().format("Y-MM-DD HH:mm"))
  const [deviceId, setDeviceId] = useState(deviceDetails?.rows[0])
  const [speedVal, setSpeedVal] = useState(2000)
  const [parkingMarker, setParkingMarker] = useState(2)
  const [markTrip, setMarkTrip] = useState(false)
  const [removeDrift, setRemoveDrift] = useState(false)
  const [deviceList, setDeviceList] = useState(
    '<option value="">Select</option>'
  )
  const [selectedDevice, setSelectedDevice] = useState({})

  //Fetch Playback history data

  const {
    deviceDetailById,
    isLoadingDetailById,
    isGettingData,
    isGettingDataError,
    playBackHistoryData,
  } = useSelector(state => {
    return {
      deviceDetailById: state.Device.deviceDetail,
      isLoadingDetailById: state.Device.isLoadingDetail,
      isGettingData: state.playBackHistoryDetails.isLoading,
      isGettingDataError: state.playBackHistoryDetails.error,
      playBackHistoryData: state.playBackHistoryDetails.playBackHistoryData,
    }
  })

  // Setup the select box logic if device information not there fetch device details and set default selected
  useEffect(() => {
    if (!deviceDetails) return
    dispatch(getDeviceByDeviceId(deviceDetails?.rows[0].id))
    const deviceListDE = deviceDetails?.rows?.map(device => {
      let classSelected = ""
      if (defaultDevice === device.id) {
        classSelected = "selected"
      }
      return (
        <option value={device.id} selected={classSelected}>
          {device.name ? device.name : device.model}
        </option>
      )
    })
    setDeviceList(deviceListDE)
    setDeviceId(deviceDetails?.rows[0]?.id || 0)
  }, [deviceDetails])

  //Playback button logic but need to verify

  //    useEffect(() => {
  //      if (playBackHistoryData && playBackHistoryData.length > 0 && playButtonStatus) return
  //      if (playBackHistoryData.length===0 && playButtonStatus) {
  //        changeAccessFetchingHandler(false)
  //        changeButtonStatus(false)
  // }
  //    }, [playBackHistoryData])

  //Need to check this

  const tableCol = () => {
    setColEnable(!colEnable)
  }

  const AlertVolumeBtn = () => {
    setAlertVolumeBtn(!AlertVolume)
  }

  function NotificationPopBtn() {
    setMenu(!menu)
    setNotificationPop(!NotificationPop)
    removeBodyCss()
  }

  function ReceiveVoicePopBtn() {
    setMenu(!menu)
    setReceiveVoicePop(!ReceiveVoicePop)
    removeBodyCss()
  }

  //Play local data popup
  function PlayLocalBtn() {
    setPlayLocal(!PlayLocal)
    removeBodyCss()
  }

  //Open the Device details popup when click on edit button
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function togStandard(device = {}) {
    setSpeedVal(device?.alert?.speedingAlert)
    setSelectedDevice(device)
    setModelStandard2(!modelStandard2)
    removeBodyCss()
  }

  //Date Change in form input box based as default
  const dateChange = change => {
    switch (change) {
      case "today":
        setFromDate(`${moment().format("Y-MM-DD")} 00:01`)
        setToDate(moment().format("Y-MM-DD HH:mm"))
        break
      case "yesterday":
        setFromDate(`${moment().subtract(1, "days").format("Y-MM-DD")} 00:01`)
        setToDate(`${moment().subtract(1, "days").format("Y-MM-DD")} 23:59`)
        break
      case "thisWeek":
        setFromDate(moment().subtract(7, "days").format("Y-MM-DD HH:mm"))
        setToDate(moment().format("Y-MM-DD HH:mm"))
        break
      case "lastWeek":
        setFromDate(moment().subtract(14, "days").format("Y-MM-DD HH:mm"))
        setToDate(moment().subtract(7, "days").format("Y-MM-DD HH:mm"))
        break
      case "thisMonth":
        setFromDate(`${moment().format("Y-MM")}-01 00:01`)
        setToDate(moment().format("Y-MM-DD HH:mm"))
        break
      case "lastMonth":
        setFromDate(`${moment().subtract(1, "months").format("Y-MM")}-01 00:01`)
        setToDate(
          `${moment(`${moment().subtract(1, "months").format("Y-MM")}-01 00:01`)
            .add(30, "days")
            .format("Y-MM")}-01 23:59`
        )
        break
    }
  }

  //On Submit Data Fetching
  const onSubmit = async () => {
    const data = {}
    data.play = !playButtonStatus
    data.speedVal = speedVal
    data.parkingMarker = parkingMarker
    data.markTrip = markTrip
    data.removeDrift = removeDrift
    if (
      deviceIdParent === deviceId &&
      fromDateParent === fromDate &&
      toDateParent === toDate
    ) {
      if (playBackHistory.length != 0) {
        console.log("invoked the dispatch")
        dispatch(
          getPlayBackHistoryDetails({
            playBackHistory,
          })
        )
        resetCurser(0)
      } else {
        showToast("Data Not available", false)
      }

      data.isValue = false
    } else {
      data.isValue = true
      data.fromDate = fromDate
      data.toDate = toDate
      data.deviceId = deviceId
    }
    await getAddData(data)

    // changeButtonStatus(true)
    //changeAccessFetchingHandler(true)
  }

  //TODO :remove existing Change button status and change  few correction in filter
  //TODO : Check function which is related to left menu

  return (
    <React.Fragment>
      <div className="topbar">
        <div className="d-flex w-100">
          <div className="flx_1 w-100 text-center">
            <div className="d-inline-flex">
              <div className="colc pt-1 mt-0  me-2">Device</div>
              <div className="colc me-2">
                <select
                  className="form-select"
                  onChange={e => {
                    dispatch(getDeviceByDeviceId(e.target.value))
                    setDeviceId(e.target.value)
                  }}
                >
                  {deviceList}
                </select>
              </div>

              <div className="colc me-2">
                <InputGroup>
                  <Flatpickr
                    className="form-control d-block"
                    placeholder=""
                    value={fromDate}
                    options={{
                      altInput: false,
                      enableTime: true,
                      dateFormat: "Y-m-d H:i",
                      time_24hr: true,
                      defaultDate: [fromDate],
                      maxDate: new Date(),
                      //maxTime: moment().format("H:i"),
                      onChange: (selectedDates, dateStr, instance) =>
                        setFromDate(dateStr),
                    }}
                  />
                </InputGroup>

                <span className="form-text d-flex align-items-center">
                  <i className="bx bxs-info-circle me-1"></i> Maximum time range
                  of last 3 days.
                </span>
              </div>
              <div className="col pt-1  me-2">To</div>
              <div className="colc me-3">
                <InputGroup>
                  <Flatpickr
                    className="form-control d-block"
                    placeholder=""
                    value={toDate}
                    options={{
                      altInput: false,
                      enableTime: true,
                      dateFormat: "Y-m-d H:i",
                      time_24hr: true,
                      defaultDate: [toDate],
                      maxDate: new Date(),
                     // maxTime: moment().format("H:i"),
                      onChange: (selectedDates, dateStr, instance) => {
                        setToDate(dateStr)
                      },
                    }}
                  />
                </InputGroup>
              </div>
              <div className="col pt-1 me-3 option_main position-relative">
                <span className="bluecol optionTxt d-inline-flex align-items-center">
                  <i className=" fas fa-sliders-h pe-2"></i> Option
                </span>
                <div className="play_option p-2 text-start">
                  <div className="link_txt_option">
                    <span
                      onClick={() => {
                        dateChange("today")
                      }}
                    >
                      Today
                    </span>
                    <span
                      onClick={() => {
                        dateChange("yesterday")
                      }}
                    >
                      Yesterday
                    </span>
                    <span
                      onClick={() => {
                        dateChange("thisWeek")
                      }}
                    >
                      This Week
                    </span>
                    <span
                      onClick={() => {
                        dateChange("lastWeek")
                      }}
                    >
                      Last Week
                    </span>
                    <span
                      onClick={() => {
                        dateChange("thisMonth")
                      }}
                    >
                      This Month
                    </span>
                    <span
                      className="border-end-0 pe-0"
                      onClick={() => {
                        dateChange("lastMonth")
                      }}
                    >
                      Last Month
                    </span>
                  </div>
                  <div className="form_option pt-3">
                    <div className="row pb-3">
                      <div className="col-4 text-end">Speed</div>
                      <div className="col-8">
                        <select
                          className="form-control w-auto"
                          onChange={e => {
                            updateFilterValue("speedVal", e.target.value)
                            setSpeedVal(e.target.value)
                          }}
                        >
                          <option value="2000">x1</option>
                          <option value="1500">x2</option>
                          <option value="1000">x3</option>
                          <option value="600">x4</option>
                          <option value="300">x5</option>
                          <option value="300">x6</option>
                        </select>
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-4 text-end">Parking marker</div>
                      <div className="col-8">
                        <select
                          className="form-control w-auto"
                          onChange={e => {
                            updateFilterValue("parkingMarker", e.target.value)
                            setParkingMarker(e.target.value)
                          }}
                        >
                          <option value="2"> 2min</option>
                          <option value="5"> 5min</option>
                          <option value="10"> 10min</option>
                          <option value="15"> 15min</option>
                          <option value="20"> 20min</option>
                        </select>
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-8 offset-md-4">
                        <div className="pb-3">
                          <input
                            type="checkbox"
                            className="me-1"
                            onClick={e => {
                              updateFilterValue("makeTrip", e.target.value)
                              setMarkTrip(e.target.checked)
                            }}
                          />
                          Mark trip route with color
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            className="me-1"
                            onClick={e => {
                              updateFilterValue("removeDrift", e.target.value)
                              setRemoveDrift(e.target.checked)
                            }}
                          />
                          Remove triff location
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 text-end">Speeding value</div>
                      {!isLoadingDetailById && (
                        <div className="col-8">
                          {deviceDetailById?.alert?.speedingAlert}
                          <span
                            className="txt_colr_1 ms-2"
                            onClick={() => {
                              togStandard(deviceDetailById)
                            }}
                          >
                            <i className=" bx bx-pencil"></i>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col me-3 position-relative">
                <div className="d-inline-flex align-items-center">
                  {
                    //stopAndPlay - only update the button status true or false , when fetching is happened change the status equal to true and if there is data change status is true
                    // changing area if data available =true
                    //playPauseStop - this is when user wontedly updating the play stop action in map and initial value is true
                    //Initial button status stopAndPlay =false ,playPauseStop=true so there no action
                  }
                  {!stopAndPlay && playPauseStop && !isGettingData && (
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary me-2 d-inline-flex align-items-center"
                      onClick={() => {
                        onSubmit()

                        console.log("i am 1 clicked")
                      }}
                    >
                      <i className="bx bx-play-circle"></i> Play
                    </button>
                  )}

                  {!stopAndPlay && !playPauseStop && !isGettingData && (
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary me-2 d-inline-flex align-items-center"
                      onClick={() => {
                        onSubmit()
                        console.log("i am 2 clicked")
                      }}
                    >
                      <i className="bx bx-play-circle"></i> Play
                    </button>
                  )}

                  {stopAndPlay && !playPauseStop && !isGettingData && (
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary me-2 d-inline-flex align-items-center"
                      onClick={() => {
                        stopAndPlayHandler(true)
                        console.log("i am 3 clicked")
                      }}
                    >
                      <i className="bx bx-play-circle"></i> Play
                    </button>
                  )}

                  {stopAndPlay && playPauseStop && !isGettingData && (
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary me-2 d-inline-flex align-items-center"
                      onClick={() => {
                        //changeButtonHandler(false)
                        stopAndPlayHandler(false)
                      }}
                    >
                      <i className="bx bx-play-circle"></i> Pause
                    </button>
                  )}

                  {/* {playButtonStatus && !isGettingData && (
                    <button
                      type="submit"
                      className="btn btn-sm btn-secondary me-2 d-inline-flex align-items-center"
                      onClick={() => {
                        if (playBackHistoryData.length > 0) {
                          changeButtonHandler(false)
                        } else {
                          onSubmit()
                        }
                      }}
                    >
                      <i className="bx bx-pause-circle"></i> Pause
                    </button>
                  )} */}

                  {isGettingData && (
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary me-2 d-inline-flex align-items-center"
                      onClick={() => {
                        alert("Wait for data to fetch from server ")
                      }}
                    >
                      <Spinner
                        className={classnames({
                          "position-relative": true,
                          "button-style": !isGettingData,
                          visible: isGettingData,
                          invisible: !isGettingData,
                        })}
                        size="sm"
                        // type="grow"
                      />
                    </button>
                  )}

                  <Tooltip
                    title="Q: Why the history of playback is not as same as actual one?  A: Please set up device timezone into GMT+8 "
                    placement="bottom"
                    arrow
                  >
                    <i className="bx bxs-info-circle fs-5 txt_link"></i>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          <div className="flx_0 pt-2">
            <div>
              <span
                onClick={() => {
                  PlayLocalBtn()
                }}
                className="cursor-poiter"
              >
                <i className="bx bx-play-circle"></i> Play local data
              </span>
              <Modal
                isOpen={PlayLocal}
                size="md"
                toggle={() => {
                  PlayLocalBtn()
                }}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Play local data</h5>
                  <button
                    type="button"
                    onClick={() => {
                      setPlayLocal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                </div>
                <PlayLocalData />
              </Modal>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        isOpen={modelStandard2}
        toggle={() => {
          togStandard()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Device Setting</h5>
          <button
            type="button"
            onClick={() => {
              setModelStandard2(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <DeviceDetails
            selectedDevice={selectedDevice}
            tog_model={togStandard}
          />
        </div>
      </Modal>
      {/*bottom play Button */}
    </React.Fragment>
  )
}
export default memo(withTranslation()(PlayBackTop))
