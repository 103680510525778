/* eslint-disable */

import React from "react"
// import React, { Component }  from 'react';
import MetaTags from "react-meta-tags"
import { Container, Row, Card, CardBody } from "reactstrap"

import AddUersMain from "../Shared/AddUsersMain"

const Addusers = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Users</title>
        </MetaTags>
        <div>
          <Container fluid>
            <Row className="mt-3">
              <div className="col-md-12">
                <Card className="mb-3">
                  <CardBody className="d-flex align-items-center justify-content-between p-2">
                    <div className="page-title-box ps-3">
                      <h3 className="mb-0 font-size-16 d-flex align-items-center">
                        <i className="bx bx-user-plus me-2 font-size-24"></i>
                        Add user
                      </h3>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Container>

          <Container fluid>
            <Row>
              <div className="col-md-12">
                <Card>
                  <CardBody className="d-flex align-items-center justify-content-between px-4 py-4">
                    <div className="crypto-buy-sell-nav w-100">
                      <div className="row">
                        <div className="col-md-8">
                          <AddUersMain />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Addusers
