/* eslint-disable */

// import React, { Component, useState } from "react"

import React, { Component, useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Spinner,
} from "reactstrap"
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"
import Profile from "../../components/Profile/Profile"
import usicons1 from "../../assets/images/user.svg"
import usicons2 from "../../assets/images/user2.svg"
import usicons3 from "../../assets/images/user3.svg"
import usicons4 from "../../assets/images/user4.svg"

import BusinessGrid2 from "./BusinessGrid2"
import Information from "../../components/Info/Information"
import SubUser from "./SubUser"

import {
  getDevicesByMemberId,
  getUserDetail,
  getSubUsers,
} from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"

const BusinessGrid = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { selectedUser, profileData, businessGridTab, setBusinessGridTab } =
    props
  const { member } = profileData

  const {
    isLoading,
    userDetail,
    isLoadingUserDetail,
  } = useSelector(state => {
    return {
      userDetail: state.AddUser.userDetail,
      isLoadingUserDetail: state.AddUser.lodaingForDetail,
    }
  })

  // const [activeTab, setActiveTab] = useState("1")
  const toggleTab = tab => {
    if (businessGridTab !== tab) {
      setBusinessGridTab(tab)
    }
  }

  const makeAPICall = tab => {
    if (tab === "3") {
      dispatch(getUserDetail(selectedUser?.id))
    }
  }

  useEffect(() => {
    if (selectedUser?.id && businessGridTab) {
      makeAPICall(businessGridTab)
    }
  }, [selectedUser, businessGridTab])

  // if (isLoading) {
  //   return (
  //     <Spinner
  //       className={classnames({
  //         "position-relative": true,
  //         "button-style": false,
  //         visible: true,
  //         "left-50": true,
  //       })}
  //       size="lg"
  //       // type="grow"
  //     />
  //   )
  // }

  return (
    <React.Fragment>
      <div className="crypto-buy-sell-nav w-100">
        <Nav tabs className="nav-tabs-custom mb-3" role="tablist">
          <NavItem>
            <NavLink
              className={classnames({
                active: businessGridTab === "1",
              })}
              onClick={() => {
                toggleTab("1")
              }}
            >
              Device
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: businessGridTab === "2",
              })}
              onClick={() => {
                toggleTab("2")
              }}
            >
              Sub-user
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: businessGridTab === "3",
              })}
              onClick={() => {
                toggleTab("3")
              }}
            >
              Information
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent
          activeTab={businessGridTab}
          className="crypto-buy-sell-nav-content border-0"
        >
          <TabPane tabId="1" id="buy">
            {businessGridTab === "1" && (
              <BusinessGrid2 selectedUser={selectedUser ? selectedUser : {}} />
            )}
          </TabPane>

          <TabPane tabId="2">
            {businessGridTab === "2" && (
              <SubUser selectedUser={selectedUser ? selectedUser : {}} />
            )}
          </TabPane>
          <TabPane tabId="3">
            {userDetail?.id === member?.id ? (
                <Profile />
            ) : (
               <Information
                userDetail={userDetail ? userDetail : {}}
                isLoading={isLoadingUserDetail}
              />
            )}
          </TabPane>
        </TabContent>
      </div>
    </React.Fragment>
  )
}

BusinessGrid.propTypes = {
  selectedUser: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    profileData: state.Login.profileData,
  }
}

export default connect(mapStateToProps, null)(BusinessGrid)
