/* eslint-disable */
import React, {
  Component,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react"
import MetaTags from "react-meta-tags"
import "./BusinessStyle.css"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import axios from "axios"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import BusinessGrid from "./BusinessGrid"
import UserLists from "../../components/user/treeMenu"
import UserListsExpired from "../../components/user/treeMenuExpired"
import UserListsOffline from "../../components/user/treeMenuOffline"
import { useSelector, useDispatch } from "react-redux"
import classnames from "classnames"
import AddUsersMain from "../Shared/AddUsersMain"
import Profile from "../../components/Profile/Profile"
import Preference from "../../components/Business/Preference"
import TemplateSettings from "../../components/Business/TemplateSettings"
import DeviceChart from "../../components/Business/DeviceChart"
import ModelChart from "../../components/Business/ModelChart"
import DeviceQuantityChart from "../../components/Business/DeviceQuantityChart"
import TreeRight from "../../components/user/TreeRight"
import monitoricons from "../../assets/images/icons/monitor.svg"
import sidebaricons from "../../assets/images/icons/sidebar.svg"

import { getDevicesInfoByMemberId } from "../../store/actions"
import { getCardBalanceAction } from "../../store/actions"
import SendMessagePopup from "../../components/Message/SendMessagePopup"

const Business = props => {
  const dispatch = useDispatch()
  const { profileData } = props
  const { member } = profileData
  const [customActiveTab, setCustomerActiveTab] = useState("1")
  const [singlebtn, setSinglebtn] = useState(false)
 
  const [selectedUser, setSelectedUser] = useState(member)

  const [businessGridTab, setBusinessGridTab] = useState("1")

  const { deviceInfoByMember } = useSelector(state => {
    return {
      deviceInfoByMember: state.Device.deviceInfoByMember,
    }
  })

    const { deviceCardInfoByMember } = useSelector(state => {
      return {
        deviceCardInfoByMember: state.GetCardsDataReducer.cardBalanceData,
      }
    })

  useEffect(() => {
    if (selectedUser?.id) {
      dispatch(getDevicesInfoByMemberId(selectedUser?.id))
      dispatch(getCardBalanceAction(selectedUser?.id))
    }
  }, [selectedUser])

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomerActiveTab(tab)
    }
  }

  const [modal_center, setmodal_center] = useState(false)
  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  const [modal_center2, setmodal_center2] = useState(false)
  function tog_center2() {
    setmodal_center2(!modal_center2)
    removeBodyCss()
  }
  const [modal_center3, setmodal_center3] = useState(false)
  function tog_center3() {
    setmodal_center3(!modal_center3)
    removeBodyCss()
  }
  const [modal_center4, setmodal_center4] = useState(false)
  function tog_center4() {
    setmodal_center4(!modal_center4)
    removeBodyCss()
  }

  const [modal_center5, setmodal_center5] = useState(false)
  function tog_center5() {
    setmodal_center5(!modal_center5)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function handleCallBack(value, node) {
       if (selectedUser.id != node.id || value === "refresh")
         setSelectedUser({ ...node })
  }
  const [height, setHeight] = useState()
  useEffect(() => {
    var divElement = document.getElementById("LeftColumn")
    var elemHeight = divElement.offsetHeight
    setHeight(elemHeight)
  }, [])

  const [showdiv, setShowdiv] = useState(false)

  return (
    <React.Fragment>
      <div className="page-content h-100">
        <MetaTags>
          <title>Business</title>
        </MetaTags>
        <div className="h-100 d-flex flex-column">
          <Container fluid>
            <Row className="mt-3">
              <div className="col-md-12">
                <Card className="mb-3">
                  <CardBody className="d-flex align-items-center justify-content-between p-2">
                    <div className="page-title-box ps-3">
                      <h3 className="mb-0 font-size-16 d-flex align-items-center">
                        <i className="bx bx-user-plus me-2 font-size-24"></i>{" "}
                        Business
                      </h3>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Container>

          <div className="container-fluid main_scroll_dash flex1 d-flex over_x_auto">
            <div className="col-auto widthside col" id="LeftColumn">
              <Card className="h-100  bg-transparent mb-0">
                <CardHeader className="h5 bg-white border-bottom">
                  <header className="d-flex align-items-center">
                    <div className=" flx_1">
                      <span>My Users</span>
                      <button
                        className="cus_btn_1"
                        onClick={() => {
                          tog_center()
                        }}
                      >
                        <i className="bx bxs-plus-circle plus_icon_1"></i> New
                      </button>
                    </div>

                    <div className="flx_0 ps-2">
                      <img src={sidebaricons} width="20" />
                    </div>
                  </header>
                </CardHeader>
                <CardBody className="d-flex justify-content-between p-0">
                  <div className="crypto-buy-sell-nav w-100">
                    <div className="bus_info busin_tab_1">
                      <div>
                        <div className="d-flex align-items-center bg-white">
                          <div className="flx_1">
                            <Nav tabs className="nav-tabs-custom nav-justified">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: customActiveTab === "1",
                                  })}
                                  onClick={() => {
                                    toggleCustom("1")
                                  }}
                                >
                                  <span className="d-none d-sm-block">
                                    Users
                                  </span>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: customActiveTab === "2",
                                  })}
                                  onClick={() => {
                                    toggleCustom("2")
                                  }}
                                >
                                  <span className="d-none d-sm-block">
                                    Expired
                                    <i className="bx bxs-info-circle info_icon opacity-50 ms-1"></i>
                                  </span>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: customActiveTab === "3",
                                  })}
                                  onClick={() => {
                                    toggleCustom("3")
                                  }}
                                >
                                  <span className="d-none d-sm-block">
                                    Offline{" "}
                                    <i className="bx bxs-info-circle info_icon opacity-50 ms-1"></i>
                                  </span>
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                          <div className="flx_0 positon-relative h38">
                            <span className="tab_with_icon cursor-pointer"></span>
                            <Dropdown
                              isOpen={singlebtn}
                              toggle={() => setSinglebtn(!singlebtn)}
                            >
                              <DropdownToggle className="drop_cus_1" caret>
                                <i className="bx bx-transfer-alt rotate_90 font-size-20 color_1 opacity-50"></i>
                              </DropdownToggle>
                              <DropdownMenu className="posup">
                                <DropdownItem>
                                  <i className="bx bxs-user"></i> Username
                                </DropdownItem>
                                <DropdownItem>
                                  <i className="bx bxs-user-detail"></i>
                                  Account
                                </DropdownItem>
                                <DropdownItem>
                                  <i className="bx bxs-user-detail"></i>
                                  Total Device
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </div>

                        <TabContent
                          activeTab={customActiveTab}
                          className="p-3 text-muted bg-white"
                        >
                          <TabPane tabId="1" id="test">
                            <div className="selectisopen">
                              {customActiveTab === "1" && (
                                <UserLists
                                  handleCallBack={handleCallBack}
                                  selectedValue={selectedUser?.id}
                                  TreeHeight={Math.round(height)}
                                />
                              )}
                            </div>
                          </TabPane>
                          <TabPane tabId="2" id="test1">
                            <div className="selectisopen">
                              {customActiveTab === "2" && (
                                <UserLists
                                  handleCallBack={handleCallBack}
                                  selectedValue={selectedUser?.id}
                                  TreeHeight={Math.round(height)}
                                />
                              )}
                            </div>
                          </TabPane>
                          <TabPane tabId="3" id="test2">
                            <div className="selectisopen">
                              {customActiveTab === "3" && (
                                <UserLists
                                  handleCallBack={handleCallBack}
                                  selectedValue={selectedUser?.id}
                                  TreeHeight={Math.round(height)}
                                />
                              )}
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col col ms-3 panel_x_scroll">
              <div className="wh_np_row">
                <Card>
                  <CardHeader className="h5 bg-transparent border-bottom ">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center flx_0 pe-4 text-uppercase">
                        <i className="bx bx-user font-size-20 color_1 pe-2"></i>
                        Admin Account
                      </div>
                      <div className="d-flex align-items-center flx_0 orangecol fontnormal">
                        <Link
                          to="/monitor"
                          target={"_blank"}
                          className="orangecol"
                        >
                          <img src={monitoricons} width="20" className="me-1" />
                          Monitor
                        </Link>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-0 pb-0 bus_col_2 position-relative">
                    <div className="row fw_ini">
                      <div className="col-4 dash_col pt-3 pb-3 br_1">
                        <div className="d-flex pb-3">
                          <div className="flx_0 pe-2">
                            <figure className="prof_img">
                              <img
                                src={
                                  selectedUser?.profileImage ||
                                  "https://images.protrack365.com/portrait/default_s.jpg"
                                }
                                alt=""
                              />
                            </figure>
                          </div>
                          <div className="flx_1">
                            <div className="pb-2 d-flex">
                              <span className="flx_0 pe-2 opacity-50">
                                <i className="bx bx-user font-size-18 color_1 "></i>
                              </span>
                              <span className="flx_1">
                                <span className="txt_colr_1">
                                  {" "}
                                  {selectedUser?.name}{" "}
                                </span>{" "}
                                {selectedUser?.role && `(${selectedUser.role})`}
                              </span>
                            </div>
                            <div className="pb-2 d-flex">
                              <span className="flx_0 pe-2 opacity-50">
                                <i className="bx bx-phone font-size-18 color_1 "></i>
                              </span>
                              <span className="flx_1">
                                {selectedUser?.contactNumber}
                              </span>
                            </div>
                            <div className="pb-2 d-flex">
                              <span className="flx_0 pe-2">
                                <i className="bx bx-envelope font-size-18 color_1 opacity-50"></i>
                              </span>
                              <span className="flx_1">
                                {selectedUser?.email}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row bs_r1 gx-0">
                          <div className="col-lg-8 d-flex">
                            <div
                              className="text-center flx_0 cursorp opacity-75"
                              onClick={() => {
                                setBusinessGridTab("3")
                              }}
                            >
                              <i className="bx bx-edit-alt font-size-18 opacity-50"></i>
                              <span className="d-block color_1 font-size-13 opacity-80">
                                Edit
                              </span>
                            </div>

                            <div
                              className="text-center ms-4 cursorp opacity-75"
                              onClick={() => {
                                tog_center3()
                              }}
                            >
                              <i className="bx bx-cog font-size-18 opacity-50"></i>
                              <span className="d-block color_1 font-size-13 opacity-80">
                                Setting
                              </span>
                            </div>
                            <div
                              className="text-center ms-4 cursorp opacity-75"
                              onClick={() => {
                                tog_center5()
                              }}
                            >
                              <i className="bx bx-envelope font-size-18 opacity-50"></i>
                              <span className="d-block color_1 font-size-13 opacity-80">
                                Send Message
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-3  offset-lg-1 text-end">
                            <span className="cir_user d-inline-flex align-items-center justify-content-center">
                              <i className="bx bxs-user color_1 font-size-18"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 dash_col pt-3 pb-3 br_1">
                        <div className="pb-4 opacity-75">Device Quality</div>
                        <div className="d-flex align-items-end pb-4">
                          <span className="large_txt flx_0 line-25 pe-2">
                            {deviceInfoByMember?.totalCount}{" "}
                          </span>
                          {/* <span className="flx_0 line-1">
                            <i className="bx bx-up-arrow-alt color_1 font-size-17"></i>
                          </span> */}
                          {/* <span className="flx_0 pe-2">1</span> */}
                          <span className="flx_0 pe-2 opacity-75">Total</span>
                        </div>
                        <div className="row bs_r1 gx-0 pt-2">
                          <div className="col-lg-12 d-flex">
                            {/* <div className="text-center  flx_0 pe-2">
                              <span className="color_1">0</span>
                              <span className="d-block color_1 font-size-13">
                                Imported
                              </span>
                            </div> */}
                            <div className="text-center col pe-2 opacity-75">
                              <span className="txt-black">
                                {deviceInfoByMember?.enabledCount} /{" "}
                                {deviceInfoByMember?.disabledCount}
                              </span>
                              <span className="d-block color_1 font-size-13 opacity-80">
                                Activated / Unactivated
                                
                              </span>
                            </div>
                            <div className="text-center col pe-2 opacity-75">
                              <span className="color_1">0</span>
                              <span className="d-block color_1 font-size-13 opacity-80">
                                Recharged
                              </span>
                            </div>
                            <div className="text-center col pe-2 opacity-75">
                              <span className="txt-black">0</span>
                              <span className="d-block color_1 font-size-13 opacity-80">
                                Expired
                              </span>
                            </div>
                            <div className="text-end col pe-1 opacity-75">
                              <span className="cir_user d-inline-flex align-items-center justify-content-center">
                                <i className="bx bxs-user font-size-18"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 dash_col pt-3 pb-3 position-relative">
                        <div className="pb-3 opacity-75">Card balance</div>

                        <div className="row gx-0">
                          <div className="col-md-6 pb-1">
                            <span className="large_txt flx_0 line-25">
                              {deviceCardInfoByMember &&
                                deviceCardInfoByMember[0]?.newCard}
                            </span>
                            <div className="pt-1 opacity-50">
                              <i
                                className="bx bxs-info-circle info_icon"
                                id="NewCard"
                              ></i>
                              <span className="ps-1">New card </span>
                            </div>
                          </div>
                          <div className="col-md-6 pb-1">
                            <span className="large_txt flx_0 line-25">
                              {" "}
                              {deviceCardInfoByMember &&
                                deviceCardInfoByMember[0]?.lifeLongCard}
                            </span>
                            <div className="pt-1 opacity-50">
                              <i
                                className="bx bxs-info-circle info_icon"
                                id="NewCard"
                              ></i>
                              <span className="ps-1">
                                {" "}
                                New card to lifelong{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 pb-1">
                            <span className="large_txt flx_0 line-25">
                              {" "}
                              {deviceCardInfoByMember &&
                                deviceCardInfoByMember[0]?.annualCard}
                            </span>
                            <div className="pt-1 opacity-50">
                              <i
                                className="bx bxs-info-circle info_icon"
                                id="NewCard"
                              ></i>
                              <span className="ps-1"> Annual card</span>
                            </div>
                          </div>
                          <div className="col-md-6 pb-1">
                            <span className="large_txt flx_0 line-25">
                              {deviceCardInfoByMember &&
                                deviceCardInfoByMember[0]?.lifeLongCard}
                            </span>
                            <div className="pt-1 opacity-50">
                              <i
                                className="bx bxs-info-circle info_icon"
                                id="NewCard"
                              ></i>
                              <span className="ps-1"> Lifelong card</span>
                            </div>
                          </div>
                        </div>

                        <div className="car_bal_cir">
                          <span className="cir_user d-inline-flex align-items-center justify-content-center">
                            <i className="bx bxs-user font-size-18"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <span
                      className="showdetails"
                      // onClick={() => setShowdiv(!showdiv)}
                    >
                      <i class="bx bx-menu-alt-right"></i> Detail{" "}
                      <i class="bx bx-menu-alt-left"></i>
                    </span>
                  </CardBody>
                </Card>

                {showdiv ? (
                  <Card className="mb-3">
                    <div className="p-3">
                      <div className="row">
                        <div className="col-md-4 pb-1">
                          <h3 className="mb-4"> Device analysis </h3>
                          <DeviceChart />
                        </div>
                        <div className="col-md-4 pb-1">
                          <h3 className="mb-4">Model</h3>
                          <ModelChart />
                        </div>

                        <div className="col-md-4 pb-1">
                          <h3 className="mb-4">Device quantity</h3>
                          <DeviceQuantityChart />
                        </div>
                      </div>
                    </div>
                  </Card>
                ) : null}

                <Card>
                  <div className="p-3">
                    {selectedUser && (
                      <BusinessGrid
                        selectedUser={selectedUser}
                        businessGridTab={businessGridTab}
                        setBusinessGridTab={setBusinessGridTab}
                      />
                    )}
                  </div>
                </Card>

                {/*<Card>
                <div className="p-3">
                  {selectedUser && <BusinessGrid selectedUser={selectedUser} />}
                </div>
              </Card> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add user</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <AddUsersMain />
        </div>
      </Modal>

      <Modal
        size="lg"
        isOpen={modal_center2}
        toggle={() => {
          tog_center2()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">My Profile</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center2(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <Profile />
        </div>
      </Modal>

      <Modal
        size="lg"
        isOpen={modal_center3}
        toggle={() => {
          tog_center3()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Preferences</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center3(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          {modal_center3 && (
            <Preference
              selectedUser={selectedUser}
              tog_preferences={tog_center3}
              tog_center4={tog_center4}
            />
          )}
        </div>
      </Modal>

      <Modal
        size="lg"
        isOpen={modal_center4}
        toggle={() => {
          tog_center4()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Template settings</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center4(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <TemplateSettings
            selectedUser={selectedUser}
            tog_preferences={tog_center4}
          />
        </div>
      </Modal>

      <Modal
        size="lg"
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add user</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div className="modal-body">
          <AddUsersMain />
        </div>
      </Modal>
      {modal_center5 && (
        <SendMessagePopup
          modal_center={modal_center5}
          tog_center={tog_center5}
          selectedUserData={selectedUser}
        />
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    profileData: state.Login.profileData,
  }
}

export default connect(mapStateToProps, null)(Business)
