import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

const Supplier = () => {
  return (
    <React.Fragment>
      <Card className="mb-0">
        <CardBody className="p-0">
         
         <div className="bgsuplli text-left">
          <h3 className="text-white p-3 text-start fs-5">Supplier</h3>

         <img src="https://3.imimg.com/data3/YK/QQ/MY-6041134/al-hadeed-manufacturing-trading-pvt-ltd-logo-90x90.png" />

         </div>
          

<div className="supplierinfo p-4 pt-0">
          <h3 className="fs-5 mb-2">AL HADEED MANUFACTURING</h3>
          <span>7358703261,7358703263 (Mr.Rafath)</span>
          <span>support@alhadeed.in</span>
          <span>www.alhadeedtechnologies.com</span>
          <span> CHENNAI</span>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Supplier;