/* eslint-disable */
import React, { memo, useState, useEffect, useCallback, useRef } from "react"
import Geocode from "react-geocode"
import _ from "lodash"
import moment from "moment"
//Import Breadcrumb
import "./Monitor.css"
Geocode.setApiKey("AIzaSyCVPM_AKcsmM6GvZmx5xpizXasMuho4c24")
Geocode.enableDebug()
import LeafMap from "./component/LeafMap"
import {
  Marker,
  Popup,
  Tooltip,
  FeatureGroup,
  Polygon,
  useMap,
} from "react-leaflet"


import MarkerClusterGroup from "react-leaflet-cluster"
import L, { MarkerCluster } from "leaflet"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import lefticon14 from "../../assets/images/icons/track.svg"
//Common component
import MonitorMapComman from "./MapPopupInfo/MonitorMapCommand"
import ShareLocation from "./MapPopupInfo/ShareLocation"
import Iosetting from "./MapPopupInfo/Iosetting"
import Counter from "./counter"
import { renderSwitch } from "../../utils/vehicleStatus"
import { dateDifferent } from "../../utils/dates"
import iconComponents from "../../utils/Icons"
import center from "@turf/center"
import { points } from "@turf/helpers"

// InfoWindow component

const infoWindowStyle = {
  position: "relative",
  bottom: "0",
  left: "-150px",
  width: 350,
  backgroundColor: "white",
  boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
  fontSize: 14,
  zIndex: 100,
  padding: 15,
  color: "#333",
}
// eslint-disable-next-line

const InfoWindow = ({ deviceInfo }) => {
  const { device, deviceStatusDetails } = deviceInfo
  const id = device.show ? "simple-popover" : undefined

  let engine = "OFF"
  if (device.acc) {
    engine = "ON"
  }

  let engineDuration = dateDifferent(device.accLastStatusAt, moment.utc())
  return (
    <>
      <div className="row d-flex">
        <div className="leftpop col-md-8">
          <span>
            <h5 className="text-info">
              {device.name ? `${device.name}(${device.model})` : device.model}
            </h5>{" "}
          </span>
          <span>
            <b>Status:</b> {deviceStatusDetails.status}
            {deviceStatusDetails.duration
              ? `(${deviceStatusDetails.duration})`
              : ""}
          </span>
          <span>
            <b>Engine:</b> {engine}{" "}
            {engineDuration ? `(${engineDuration})` : ""}
          </span>
          {device?.info?.voltage && engine != "OFF" ? (
            <span>
              <b>External Power:</b>{" "}
              {device.info.voltage == "No power(shutdown)" ? "OFF" : "ON"}
            </span>
          ) : null}

          <span>
            <b>IMEI:</b> {device.imei}
          </span>
          <span>
            <b>Coordinate:</b>
            <b className="text-info">
              {" "}
              {device.lat.length > 10
                ? device.lat.substring(0, 10)
                : device.lat}
              ,
              {device.lng.length > 10
                ? device.lng.substring(0, 10)
                : device.lng}
            </b>
          </span>
        </div>
      </div>

      <div className="row d-flex">
        <div className="deviceinpop">
          <div className="popmapicons">
            <span>
              <NavLink
                to={`/monitor/track/${device.id}`}
                target={"_blank"}
                //onClick={() => openInNewWindow(device.id, device.imie)}
              >
                <img alt="" src={lefticon14} width={21} />
              </NavLink>
            </span>
            <span>
              <NavLink
                to={`/playback/${device.id}`}
                target={"_blank"}
                onClick={() => openInNewWindow(device.id, device.imie)}
              >
                <img alt="" src={iconComponents.playback} width={21} />
              </NavLink>
            </span>
            <span>
              <MonitorMapComman />
            </span>

            <div className="position-relative mm_parent">
              <NavLink to="/apps/monitor/report/EngineOverview">
                <img alt="" src={iconComponents.geoFence} width={21} />
              </NavLink>
              <div className="mm_list">
                <span className="d-block">300m</span>
                <span className="d-block">500m</span>
                <span className="d-block">300m</span>
              </div>
            </div>
            <span>
              <ShareLocation />
            </span>
            <span>
              <NavLink to="/apps/monitor/report/EngineOverview">
                <img alt="" src={iconComponents.street} width={21} />
              </NavLink>
            </span>
            <span>
              <NavLink to="/apps/monitor/report/EngineOverview">
                <img alt="" src={iconComponents.details} width={21} />
              </NavLink>
            </span>

            <span>
              <Iosetting />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
const Monitor = ({ data }) => {
  const { deviceDetails, centerData, isLoading, error } = data
  const [showLocation, setShowLocation] = useState(false)
  const [location, setLocation] = useState()
  const [centerDetails, setCenterDetails] = useState([])
  const mapRef = useRef(null)
  const markerRef = useRef([])
  const { selectedDevice } = useSelector(state => {
    return {
      selectedDevice: state.DeviceListSelectedPopup.selectedDevicePopupDetails,
    }
  })

  useEffect(() => {
    if (!deviceDetails) return
    const deviceAllPoints = deviceDetails
      .filter(device => {
        if (device.lat && device.lng) return true // skip
        return false
      })
      .map(deviceLoc => {
        return [parseFloat(deviceLoc.lat), parseFloat(deviceLoc.lng)]
      })
    if (deviceAllPoints.length === 0) return
    const pointData = points(deviceAllPoints)
    setCenterDetails(center(pointData)?.geometry.coordinates)
  }, [deviceDetails])

  useEffect(() => {
    console.log(selectedDevice, typeof selectedDevice, "selectedDevice=====")
    if (typeof selectedDevice != "number") return
    const map = mapRef.current
    if (!map) return
    const marker = markerRef.current

    if (marker.length > 0) {
      marker[selectedDevice].openPopup()

      map.target.setView(
        [
          marker[selectedDevice]._latlng.lat,
          marker[selectedDevice]._latlng.lng,
        ],
        18
      )
    }
  }, [selectedDevice])

  //Geofence code comes below


      const {
        geoFenceDetails,
        geoFenceFetchLoading,
        GeoFenceFetchFailed,
        previousMemberId,
      } = useSelector(state => {
        return {
          geoFenceDetails: state.GeoFenceDetails.geoFenceData,
          geoFenceFetchLoading: state.GeoFenceDetails.isLoading,
          GeoFenceFetchFailed: state.GeoFenceDetails.isFailed,
          previousMemberId: state.GeoFenceDetails.GeoFenceCurrentMemberId,
        }
      })

  const generateFixedPolygons = (center, size, count) => {
    const polygons = []

    for (let i = 0; i < count; i++) {
      const latLngs = []
      const sides = 6 // Number of sides for the polygon
      const radius = size / 2

      for (let j = 0; j < sides; j++) {
        const angle = (j * 2 * Math.PI) / sides
        const x = center[0] + radius * Math.cos(angle)
        const y = center[1] + radius * Math.sin(angle)
        latLngs.push([x, y])
      }

      polygons.push(latLngs)
    }

    setFixedPolygons(polygons)
  }

  const onFeatureGroupReady = reactFGref => {
    setEditableFG(reactFGref)
  }

  return (
    // Important! Always set the container height explicitly
    <>
      {showLocation && (
        <div className="curmaplocation">
          {location && <span>{location}</span>}
        </div>
      )}
      <Counter />

      <div style={{ height: "100vh", width: "100%" }}>
        <LeafMap
          whenReady={map => {
            mapRef.current = map
          }}
          zoom={5}
          center={
            centerDetails.length > 0
              ? centerDetails
              : [14.270834555555556, 86.7688728888889]
          }
        >
         

          {/* <MarkerClusterGroup
            onClick={e => console.log("onClick", e)}
            iconCreateFunction={iconComponents.createClusterCustomIcon}
            maxClusterRadius={150}
            spiderfyOnMaxZoom={true}
            polygonOptions={{
              fillColor: "#ffffff",
              color: "#f00800",
              weight: 5,
              opacity: 1,
              fillOpacity: 0.8,
            }}
            showCoverageOnHover={false}
          > */}
          {deviceDetails &&
            deviceDetails.map(
              (device, i) =>
                device.lat &&
                device.lng && (
                  <Marker
                    ref={el => (markerRef.current[device.indexValue] = el)}
                    position={[device.lat, device.lng]}
                    icon={iconComponents[device.carIcon]}
                    eventHandlers={{
                      dblclick: () => {
                        Geocode.fromLatLng(device.lat, device.lng)
                          .then(response => {
                            const address =
                              response.results[0].formatted_address
                            setShowLocation(true)
                            setLocation(address)
                          })
                          .catch(error => {
                            console.error(error)
                          })
                      },
                    }}
                  >
                    <Popup style={infoWindowStyle}>
                      <InfoWindow
                        deviceInfo={{
                          device,
                          deviceStatusDetails: renderSwitch(device),
                        }}
                      />
                    </Popup>
                    <Tooltip>{device.model}</Tooltip>
                  </Marker>
                )
            )}
          {/* </MarkerClusterGroup> */}
        </LeafMap>
      </div>
    </>
  )
}

export default Monitor
