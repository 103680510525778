/* eslint-disable */

import React, { useState, useRef, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import CheckPopup from "./CheckPopup"

import { checkImeiDefault } from "../../store/actions"
import { connect, useDispatch, useSelector } from "react-redux"
import LoadingButton from "../../components/Common/Button"
import { bindActionToPromise } from "utils/Common"
import { rechargeDefault } from "../../store/actions"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

function Recharge(props) {
  const dispatch = useDispatch()
  const { selectedUser = {}, selectedRows } = props
  const today = new Date()
  const minDateDisable = new Date(today)
  minDateDisable.setDate(minDateDisable.getDate() + 1)
  const [popoverright, setpopoverright] = useState(false)

  const [userDue, setUserDue] = useState(minDateDisable)
  const [imeiInput, setImeiInput] = useState([])
  const [cardType, setCardType] = useState("Annual Card")
  const [remarkInput, setRemarkInput] = useState("")
  const [errorUserDue, setErrorUserDue] = useState("")
  const [errorImei, setErrorImei] = useState("")

  const { checkImeiData, isLoadingCheckImei, isLoadingRecharge } = useSelector(
    state => {
      return {
        checkImeiData: state.AddDevice.imeiResData,
        isLoadingCheckImei: state.AddDevice.isLoadingCheckImei,
        isLoadingRecharge: state.AddDevice.isLoadingRecharge,
      }
    }
  )

  useEffect(() => {
    if (selectedRows && selectedRows.length > 0) {
      setImeiInput(selectedRows)
    }
  }, [])

  const updateImei = value => {
    setErrorImei("")
    const updateArray = value ? value.split("\n") : []
    setImeiInput(updateArray)
  }

  const updateUserDue = value => {
    setErrorUserDue("")
    setUserDue(value)
  }

  const handleCheck = () => {
    const imeiData = {
      imei: imeiInput,
    }
    props.checkImeiDefaultAction(imeiData).then(() => {
      setpopoverright(true)
    })
  }

  const handleSubmit = () => {
    if (userDue === "" || userDue === null) {
      setErrorUserDue("Please Select User due")
    }

    if (imeiInput.length <= 0) setErrorImei("Please Enter IMEI")

    if (userDue !== "" && userDue !== null && imeiInput.length > 0) {
      const rechargeData = {
        targetMemberId: selectedUser?.id,
        imei: imeiInput,
        userDue: userDue,
        isAnnual: cardType === "Annual Card" ? true : false,
        remark: remarkInput,
      }

      if (rechargeData) {
        props.rechargeDefaultAction(rechargeData).then(() => {
          props.setmodal_center2(false)
        })
      }
    }
  }
  return (
    <div>
      <form>
        <div className="modal-body">
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-4 col-form-label text-start"
            >
              IMEI <small className="text-danger">*</small>
            </label>
            <div className="col-md-8">
              <div className="row gx-2">
                <div className="col">
                  <Input
                    type="textarea"
                    placeholder="Enter Imei"
                    value={imeiInput ? imeiInput.join("\n") : ""}
                    onChange={e => updateImei(e.target.value)}
                    rows={5}
                  />
                  <span className="font-size-12">
                    One batch for 200 IMEI at most.
                  </span>
                </div>
                {errorImei ? (
                  <FormFeedback type="invalid" style={{ display: "block" }}>
                    {errorImei}
                  </FormFeedback>
                ) : null}
                <div className="col-auto">
                  <LoadingButton
                    id="Popoverright"
                    color="primary"
                    className="btn btn-primary btn-sm"
                    loading={isLoadingCheckImei}
                    disabled={isLoadingCheckImei || imeiInput.length <= 0}
                    block={false}
                    onClick={() => {
                      handleCheck()
                    }}
                  >
                    Check
                  </LoadingButton>
                  {checkImeiData && !isLoadingCheckImei && (
                    <Popover
                      placement="right"
                      isOpen={popoverright}
                      target="Popoverright"
                      toggle={() => {
                        setpopoverright(!popoverright)
                      }}
                      className="width350"
                    >
                      <PopoverBody>
                        <span
                          id="Popoverright"
                          onClick={() => {
                            setpopoverright(!popoverright)
                          }}
                          className="popoverclose"
                        >
                          <i className="mdi mdi-close"></i>
                        </span>
                        <CheckPopup checkImeiData={checkImeiData} />
                      </PopoverBody>
                    </Popover>
                  )}
                </div>
              </div>
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-4 col-form-label text-start"
            >
              Type <small className="text-danger">*</small>
            </label>
            <div className="col-md-8">
              <div className="row gx-2">
                <div className="col d-flex">
                  <div className="mt-2 d-flex">
                    <div className="form-check mb-3 me-3">
                      <Input
                        type="radio"
                        value="Annual Card"
                        id="fuelUnit1"
                        name="type-card"
                        className="form-check-input radio-inline"
                        onClick={e => setCardType("Annual Card")}
                        checked={cardType === "Annual Card"}
                      />
                      <Label
                        className="form-check-label font-size-13"
                        htmlFor="fuelUnit1"
                        style={{ paddingLeft: "10px" }}
                      >
                        <div className="d-flex justify-content-between">
                          Annual Card
                        </div>
                      </Label>
                    </div>
                    <div className="form-check">
                      <Input
                        type="radio"
                        value="Lifelong Card"
                        id="fuelUnit2"
                        name="type-card"
                        className="form-check-input radio-inline"
                        onClick={e => setCardType("Lifelong Card")}
                        checked={cardType === "Lifelong Card"}
                      />
                      <Label
                        className="form-check-label font-size-13"
                        htmlFor="fuelUnit2"
                        style={{ paddingLeft: "10px" }}
                      >
                        <div className="d-flex justify-content-between">
                          Lifelong Card
                        </div>
                      </Label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-4 col-form-label text-start"
            >
              User due <small className="text-danger">*</small>
            </label>
            <div className="col-md-8">
              <DatePicker
                name="platformExpirationDate"
                className="form-control"
                selected={userDue || null}
                onChange={date => updateUserDue(date)}
                placeholderText=""
                minDate={minDateDisable}
              />
              {errorUserDue ? (
                <FormFeedback type="invalid" style={{ display: "block" }}>
                  {errorUserDue}
                </FormFeedback>
              ) : null}
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-4 col-form-label text-start"
            >
              Remark
            </label>
            <div className="col-md-8">
              <div className="row gx-2">
                <div className="col">
                  <Input
                    type="textarea"
                    placeholder="Enter Remark"
                    value={remarkInput}
                    onChange={e => setRemarkInput(e.target.value)}
                    rows={5}
                  />
                </div>
              </div>
            </div>
          </Row>
        </div>
        <div className="modal-footer">
          <LoadingButton
            color="primary"
            className="btn btn-primary"
            loading={isLoadingRecharge}
            disabled={isLoadingRecharge}
            block={false}
            onClick={() => handleSubmit()}
          >
            Save changes
          </LoadingButton>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  checkImeiDefaultAction: bindActionToPromise(dispatch, checkImeiDefault),
  rechargeDefaultAction: bindActionToPromise(dispatch, rechargeDefault),
})

export default connect(mapStateToProps, mapDispatchToProps)(Recharge)
