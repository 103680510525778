/* USERS */
export const GET_DEVICES_BY_MEMBERID = "GET_DEVICES_BY_MEMBERID"
export const GET_DEVICES_BY_MEMBERID_SUCCESS = "GET_DEVICES_BY_MEMBERID_SUCCESS"
export const GET_DEVICES_BY_MEMBERID_FAIL = "GET_DEVICES_BY_MEMBERID_FAIL"

export const GET_DEVICES_INFO_BY_MEMBERID = "GET_DEVICES_INFO_BY_MEMBERID"
export const GET_DEVICES_INFO_BY_MEMBERID_SUCCESS =
  "GET_DEVICES_INFO_BY_MEMBERID_SUCCESS"
export const GET_DEVICES_INFO_BY_MEMBERID_FAIL =
  "GET_DEVICES_INFO_BY_MEMBERID_FAIL"

export const GET_DEVICE_BY_DEVICEID = "GET_DEVICE_BY_DEVICEID"
export const GET_DEVICE_BY_DEVICEID_SUCCESS = "GET_DEVICE_BY_DEVICEID_SUCCESS"
export const GET_DEVICE_BY_DEVICEID_FAIL = "GET_DEVICE_BY_DEVICEID_FAIL"

export const PUT_DEVICE_BY_DEVICEID = "PUT_DEVICE_BY_DEVICEID"
export const PUT_DEVICE_BY_DEVICEID_SUCCESS = "PUT_DEVICE_BY_DEVICEID_SUCCESS"
export const PUT_DEVICE_BY_DEVICEID_FAIL = "PUT_DEVICE_BY_DEVICEID_FAIL"

export const PUT_DEVICE_INSSTALLATION_INFO = "PUT_DEVICE_INSSTALLATION_INFO"
export const PUT_DEVICE_INSSTALLATION_INFO_SUCCESS =
  "PUT_DEVICE_INSSTALLATION_INFO_SUCCESS"
export const PUT_DEVICE_INSSTALLATION_INFO_FAIL =
  "PUT_DEVICE_INSSTALLATION_INFO_FAIL"

export const PUT_DEVICE_FUEL = "PUT_DEVICE_FUEL"
export const PUT_DEVICE_FUEL_SUCCESS = "PUT_DEVICE_FUEL_SUCCESS"
export const PUT_DEVICE_FUEL_FAIL = "PUT_DEVICE_FUEL_FAIL"


export const PUT_DEVICE_ALARM = "PUT_DEVICE_ALARM"
export const PUT_DEVICE_ALARM_SUCCESS = "PUT_DEVICE_ALARM_SUCCESS"
export const PUT_DEVICE_ALARM_FAIL = "PUT_DEVICE_ALARM_FAIL"
