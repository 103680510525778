/* eslint-disable */

import React, { memo, useState, useEffect, useCallback, useRef } from "react"
import MetaTags from "react-meta-tags"
import Navigation from "../../components/Monitor/NavigationMenu"
import Maps from "../../components/Monitor/Maps"
import "./MonitorStyle.css"
import MonitorPage from "../../components/Monitor/Index"
import MonitorBottomBar from "../../components/Monitor/MonitorBottom"
import { useSelector, useDispatch } from "react-redux"
import { getMonitorDetails } from "../../store/actions"
import _ from "lodash"
import socket from "../../utils/socket"

//Alert socket import
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const CalculateCenter = locations => {
  var latitude = 0,
    longitude = 0
  for (var location of locations) {
    longitude += parseFloat(location.lng)
    latitude += parseFloat(location.lat)
  }

  latitude = latitude / locations.length
  longitude = longitude / locations.length

  return {
    lat: latitude,
    lng: longitude,
  }
}
const PagesStarter = () => {
  const [center, setCenter] = useState({})
  const dispatch = useDispatch()

   const userDetails = JSON.parse(localStorage.getItem("authUser"));
  //Device information
  const { deviceDetails, isLoading, error, selectedUser, deviceGroups } =
    useSelector(state => {
      return {
        deviceGroups: state.DeviceGroup.deviceGroups,
        deviceDetails: state.MonitorDetails.deviceDetails,
        selectedUser: state.MonitorDetails.deviceDetails.selectedData,
        isLoading: state.MonitorDetails.isLoading,
        error: state.MonitorDetails.error
      }
    })

  useEffect(() => {

    dispatch(
      getMonitorDetails(selectedUser ? selectedUser : userDetails.memberId)
    )
  }, [])

  useEffect(() => {
    if (deviceDetails) {
      const filtered_Device = _.filter(deviceDetails.rows, function (o) {
        return (o.lat != null) & (o.lng != null)
      })
      setCenter(CalculateCenter(filtered_Device))
    }
  }, [])


//Code For alarm in monitor page 
  const Msg = ({ messageData }) => (
    <div className="toastalert">
      <h3>Lorem ipsum dolor</h3>
      <p>
        This function is a valid React component because it accepts a single{" "}
        {messageData}
      </p>
    </div>
  )

  const notify = (data) =>
    toast(<Msg messageData={data}/>, {
      className: "trackalert trac-warning",
      position: "top-right",
      autoClose: false,
      // hideProgressBar: false,
      closeOnClick: true,
      // newestOnTop,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })

  useEffect(() => {
    // Listen for a 'notification' event from the server
    socket.on("ALARM", data => {
      // Handle the received notification data
      console.log("Received Notification:", data)
      notify(data)
      // You can use this data to update state or show a notification
      // Example: setNotification(data);
    })

    // Clean up the socket listener when the component unmounts
    return () => {
      socket.off("ALARM")
    }
  }, [])

 


  return (
    <React.Fragment>
      <Navigation />
      <Maps
        data={{
          deviceDetails: deviceDetails.rows,
          centerData: center,
          isLoading,
          error,
        }}
      />
      <MonitorPage />
      <div className="mapbottombar monitormaps">
        <MonitorBottomBar bottomBarData={{ selectedUser }} />
      </div>{" "}
    </React.Fragment>
  )
}

export default PagesStarter
