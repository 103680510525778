/* eslint-disable */

import React, { useState, useCallback, useEffect } from "react"
import "antd/dist/antd.css"
import { TreeSelect } from "antd"
import { useDispatch, useSelector } from "react-redux"

import PropTypes from "prop-types"

import { getTreeMunu } from "../../store/actions"

import "./antSelect.css"
import "./antSelectDrop.css"

const TreeMenuSelect = props => {
  const { handleCallBack, selectedValue } = props
  const [treeLine, setTreeLine] = React.useState(true)
  const [showLeafIcon, setShowLeafIcon] = React.useState(false)

  const dispatch = useDispatch()
  const { treeData, isLoading } = useSelector(state => {
    return {
      treeData: state.TreeMenu.treeData,
      // selectedValue: state.TreeMenu.selectedValue,
      isLoading: state.TreeMenu.isLoading,
    }
  })

  const onLoadData = ({ id }) =>
    new Promise(async resolve => {
      dispatch(getTreeMunu(id))
      setTimeout(() => {
        resolve()
      }, 2000)
    })

  const onChange = (value, label, extra) => {
    handleCallBack(value)
  }

  const onSelect = (value, node, extra) => {}
  const AppendData = () => {
    docuemnt
  }
  return (
    <div className="">
      <TreeSelect
        treeDataSimpleMode
        style={{ width: "100%" }}
        value={selectedValue}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder="Please select"
        // treeCheckable={true}
        onChange={onChange}
        loadData={onLoadData}
        treeData={treeData}
        status="warning"
        allowClear
        multiple
        treeIcon={true}
        treeLine={
          treeLine && {
            showLeafIcon,
          }
        }
        // showLeafIcon={false}
        treeDefaultExpandAll={true}
      />
    </div>
  )
}

TreeMenuSelect.propTypes = {
  handleCallBack: PropTypes.func,
  selectedValue: PropTypes.string,
}

export default TreeMenuSelect
