/* eslint-disable */

import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
// Login Redux States
import {
  ADD_USERS,
  GET_USER_DETAIL,
  UPDATE_USERS,
  CHANGE_PASSWORD_MEMBER,
  SEARCH_USER_DEFAULT,
} from "./actionTypes"
import {
  addUserSuccess,
  addUserError,
  getUserDetailSucces,
  getUserDetailError,
  updateUserSuccess,
  updateUserError,
  memberUserChangePasswordSuccess,
  memberUserChangePasswordError,
  searchUserFail,
  searchUserSuccess,
} from "./actions"

import {
  postAddUser,
  getUserDetail,
  updateUser,
  imageUpload,
  putChangePwdOfMember,
  getSearchUserDataByQuery,
} from "../../helpers/fakebackend_helper"
import { showToast } from "../../utils/Common"

function* onAddUser({ payload }) {
  const {
    payload: data,
    meta: { resolve, reject },
  } = payload
  try {
    const response = yield call(postAddUser, data.payload)
    yield put(addUserSuccess(response))
    yield call(resolve, response)
    showToast("User added succesfully", true)
  } catch (error) {
    yield put(addUserError())
    yield call(reject, error)
    showToast(`${error?.response?.data.message}`, false)
  }
}

function* fetchUserDetail({ memberId }) {
  try {
    const response = yield call(getUserDetail, memberId)
    yield put(getUserDetailSucces(response))
  } catch (error) {
    yield put(getUserDetailError(error))
  }
}

function* onUpdateUser({
  payload: { userData, id, history, isFileUpload, files },
}) {
  try {
    if (isFileUpload) {
      const responseFiles = yield call(imageUpload, { images: files })
      const response = yield call(updateUser, {
        userData: { ...userData, profileImage: responseFiles[0].key },
        id,
      })

      yield put(updateUserSuccess(response))
      showToast("User updated succesfully", true)
    } else {
      const response = yield call(updateUser, {
        userData: { ...userData },
        id,
      })

      yield put(updateUserSuccess(response))
      showToast("User updated succesfully", true)
    }
  } catch (error) {
    yield put(updateUserError(error?.response?.data.message))
    showToast(`${error?.response?.data.message}`, false)
  }
}

function* changePassMember({ payload: { user, history } }) {
  try {
    const response = yield call(putChangePwdOfMember, {
      ...user,
    })
    if (response) {
      yield put(memberUserChangePasswordSuccess())
      showToast("Password Updated Successfully!!", true)
    } else {
      yield put(memberUserChangePasswordError())
      showToast("Something Want Wrong! Please Try Again.", false)
    }
  } catch (error) {
    yield put(memberUserChangePasswordError())
    showToast(
      error?.response?.data?.message ||
        "Something Want Wrong! Please Try Again.",
      false
    )
  }
}

function* getDataByUserSerach({ searchQuery }) {
  try {
    const response = yield call(getSearchUserDataByQuery, searchQuery)

    yield put(searchUserSuccess(response))
  } catch (error) {
    yield put(searchUserFail(error))
  }
}

function* addUser() {
  yield takeEvery(ADD_USERS, onAddUser)
  yield takeLatest(GET_USER_DETAIL, fetchUserDetail)
  yield takeEvery(UPDATE_USERS, onUpdateUser)
  yield takeEvery(CHANGE_PASSWORD_MEMBER, changePassMember)
  yield takeEvery(SEARCH_USER_DEFAULT, getDataByUserSerach)
}

export default addUser
