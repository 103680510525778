/* eslint-disable */

import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import * as yup from "yup"
import { Formik, Field, Form, ErrorMessage } from "formik"
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CardTitle,
  Container,
  Label,
  FormFeedback,
  Input,
} from "reactstrap"

import { useDispatch, useSelector } from "react-redux"

import { connect } from "react-redux"

import { bindActionToPromise } from "../../utils/Common"
import Select from "react-select"

import LoadingButton from "components/Common/Button"

import {
  getAllDistribitutor,
  addTransferCardDefault,
  getCardCountDefault,
} from "../../store/actions"

const INITIAL_VALUES = {
  distributorId: "",
  lifelongCarCount: 1,
  annualCardCount: 1,
  remark: "",
}

function TransferCard(props) {
  const [cardTransferData, setCardTransferData] = useState(INITIAL_VALUES)
  const [distributorDataList, setDistributorDataList] = useState([])
  const [errObj, setErrObj] = useState({})

  const { getCardsCountDataOBject, getDistributorsDataOBject, isLoading } =
    useSelector(state => {
      return {
        getCardsCountDataOBject: state.GetCardsDataReducer.cardsCount,
        getDistributorsDataOBject: state.GetCardsDataReducer.distributorData,
        isLoading: state.GetCardsDataReducer.isCardTransferLoading,
      }
    })

  const onChangeInput = (key, value) => {
    if (key === "distributorId" && value !== "") {
      setErrObj({ distributorId: undefined })
    }
    setCardTransferData(previousCard => ({ ...previousCard, [key]: value }))
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllDistribitutor())
    dispatch(getCardCountDefault())
  }, [])

  useEffect(() => {
    if (getDistributorsDataOBject) {
      setDistributorDataList(getDistributorsDataOBject)
    }
  }, [getDistributorsDataOBject])

  const distributorOptions = distributorDataList.map(distributorItem => ({
    value: distributorItem.id,
    label: distributorItem.name,
  }))

  let incNum = () => {
    if (cardTransferData.annualCardCount < 1000) {
      onChangeInput(
        "annualCardCount",
        Number(cardTransferData.annualCardCount) + 1
      )
    }
  }
  let decNum = () => {
    if (cardTransferData.annualCardCount > 1) {
      onChangeInput("annualCardCount", cardTransferData.annualCardCount - 1)
    }
  }

  let incNum2 = () => {
    if (cardTransferData.lifelongCarCount < 1000) {
      onChangeInput(
        "lifelongCarCount",
        Number(cardTransferData.lifelongCarCount) + 1
      )
    }
  }
  let decNum2 = () => {
    if (cardTransferData.lifelongCarCount > 1) {
      onChangeInput("lifelongCarCount", cardTransferData.lifelongCarCount - 1)
    }
  }

  const submitForm = () => {
    if (cardTransferData?.distributorId === "") {
      setErrObj({ distributorId: "Target user is required" })
    } else {
      const payload = {
        distributorId: cardTransferData.distributorId,
        lifelongCarCount: cardTransferData.lifelongCarCount,
        annualCardCount: cardTransferData.annualCardCount,
        remark: cardTransferData.remark,
      }
      props.addTransferCardDefaultAction({ payload }).then(() => {
        setCardTransferData(INITIAL_VALUES)
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Business</title>
        </MetaTags>
        <div>
          <Container fluid>
            <Row className="mt-3">
              <Col md={12}>
                <Card className="mb-3">
                  <CardBody className="d-flex align-items-center justify-content-between p-2">
                    <div className="page-title-box">
                      <h3 className="mb-0 font-size-16 d-flex align-items-center">
                        Transfer card
                      </h3>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody className="d-flex align-items-center justify-content-between px-3 py-4">
                    <div className="crypto-buy-sell-nav w-100">
                      <div className="row">
                        <div className="col-xl-6 col-lg-12 col-md-12">
                          <div className="bg-card p-3 mb-4">
                            <div className="mb-4">
                              <h3 className="fs-5 mb-3">Card balance</h3>
                            </div>
                            <div className="carddetails d-flex align-items-center justify-content-center">
                              {getCardsCountDataOBject.length >= 0 &&
                                getCardsCountDataOBject.map(data => {
                                  return (
                                    <>
                                      <h3 className="me-5 ">
                                        <b className="text-danger fs-5">
                                          {data?.annualCardsCount === null
                                            ? 0
                                            : data?.annualCardsCount}
                                        </b>
                                        <br /> Annual card
                                      </h3>
                                      <h3>
                                        <b className="text-danger fs-5">
                                          {data?.lifeLongCardsCount === null
                                            ? 0
                                            : data?.lifeLongCardsCount}
                                        </b>
                                        <br /> Lifelong card
                                      </h3>
                                    </>
                                  )
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                        >
                          Target user<b>*</b>
                        </Label>
                        <Col className="col-xl-4 col-lg-6 col-sm-6">
                          <Select
                            classNamePrefix="select2-selection"
                            placeholder="Please Select"
                            title="distributorId"
                            name="distributorId"
                            isClearable={false}
                            options={distributorOptions}
                            isSearchable={false}
                            onChange={selectedOption =>
                              onChangeInput(
                                "distributorId",
                                selectedOption?.value
                              )
                            }
                            value={distributorOptions.filter(option => {
                              if (
                                option.value == cardTransferData.distributorId
                              )
                                return option
                            })}
                          />
                          {errObj?.distributorId ? (
                            <FormFeedback
                              type="invalid"
                              style={{ display: "block" }}
                            >
                              {errObj?.distributorId}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                      <div className="row mb-3">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                        >
                          Annual card
                        </Label>
                        <Col className="col-xl-4 col-lg-6 col-sm-6">
                          <div className="col-xl-12">
                            <div className="input-group card-inc">
                              <div className="input-group-prepend">
                                <button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={() => decNum()}
                                >
                                  -
                                </button>
                              </div>
                              <Input
                                type="text"
                                className="form-control text-center"
                                value={cardTransferData.annualCardCount}
                                name="annualCarCount"
                                onChange={e => {
                                  e.target.value > 0 &&
                                    e.target.value < 1000 &&
                                    onChangeInput(
                                      "annualCarCount",
                                      e.target.value
                                    )
                                }}
                              />
                              <div className="input-group-prepend">
                                <button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={() => incNum()}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                      <div className="row mb-3">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                        >
                          Lifelong card
                        </Label>
                        <Col className="col-xl-4 col-lg-6 col-sm-6">
                          <div className="col-xl-12">
                            <div className="input-group card-inc">
                              <div className="input-group-prepend">
                                <button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={() => decNum2()}
                                >
                                  -
                                </button>
                              </div>
                              <Input
                                type="text"
                                className="form-control text-center"
                                value={cardTransferData.lifelongCarCount}
                                name="lifelongCarCount"
                                onChange={e => {
                                  e.target.value > 0 &&
                                    e.target.value < 1000 &&
                                    onChangeInput(
                                      "lifelongCarCount",
                                      e.target.value
                                    )
                                }}
                              />
                              <div className="input-group-prepend">
                                <button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={() => incNum2()}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>

                      <div className="row mb-3">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                        >
                          Remark
                        </Label>
                        <Col className="col-xl-4 col-lg-6 col-sm-6">
                          <Input
                            type="textarea"
                            placeholder="Enter Remark"
                            value={cardTransferData.remark}
                            onChange={e =>
                              onChangeInput("remark", e.target.value)
                            }
                            rows={5}
                          />
                        </Col>
                      </div>

                      <div className="row mb-3">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-xl-2 col-lg-6 col-sm-6 col-form-label"
                        ></Label>
                        <Col className="col-xl-4 col-lg-6 col-sm-6">
                          <LoadingButton
                            color="primary"
                            className="btn btn-primary btn-right"
                            loading={isLoading}
                            disabled={isLoading}
                            block={false}
                            onClick={() => submitForm()}
                          >
                            {" "}
                            Submit
                          </LoadingButton>
                          <Button
                            type="reset"
                            color="secondary"
                            className="btnwid"
                            outline
                            onClick={() => setCardTransferData(INITIAL_VALUES)}
                          >
                            Clear
                          </Button>
                        </Col>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => ({
  addTransferCardDefaultAction: bindActionToPromise(
    dispatch,
    addTransferCardDefault
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(TransferCard)
