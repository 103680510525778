/* eslint-disable */
import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"


const CommandStatus = () => {
    return(
        <div>
            <i className="bx bx-check fs-3 text-success"></i>
            <i className="bx bx-x fs-3 text-danger"></i>
        </div>
    )
}

const CommandDescription = () => {
    return(
        <div className="com_des">
            <span className="d-block com_des_1">Firmware Version [<span className="txt_link">Retry</span>]</span>
            <span className="d-block com_des_2">[2022-03-16 11:42:28] Battery:4.13V,NORMAL; GPRS:Link Up GSM Signal Level:Strong; GPS:OFF; ACC:OFF; Defense:OFF; </span>
        </div>
    )
}



const CommandHistory = () => {
  const columns = [
    {
      dataField: "id",
      text: "",
      sort: false,
      headerStyle: () => 
      {
          return { width: '50px', textAlign: 'center'};
      },
    },
    {
      dataField: "status",
      text: "",
      sort: false,
      headerStyle: () => 
      {
          return { width: '60px', textAlign: 'center'};
      },
    formatter: () => {
        return(
            <CommandStatus />
          )
      },
     
    },
    {
      dataField: "description",
      text: "",
      sort: false,
      formatter: () => {
          return(
            <CommandDescription />
          )
        
      },
    }
  ]

  // Table Data
  const productData = [
    {
      id: 1
    },
    {
        id: 2
    }
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: productData.length },
  ]


  return (
    <div>
      <div className="p-2 border-bottom">
        <div className="row">
          <div className="col-5">
            <select name="" id="" className="form-control">
              <option value="">Select</option>
            </select>
          </div>
          <div className="col-auto">
            <button className="btn btn-sm btn-primary">Search</button>
          </div>
        </div>
      </div>
      <div className="p-2">
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="id"
          columns={columns}
          data={productData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={productData}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField={"id"}
                      responsive
                      bordered={false}
                      striped={false}
                      classes={"table align-middle table-nowrap command_table"}
                      headerWrapperClasses={"thead-light"}
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                    />
                  </div>

                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </div>
    </div>
  )
}

export default CommandHistory
